import { Component, inject, OnInit, signal } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

import { ITag } from 'src/app/core/models';
import { TagsService } from 'src/app/core/services';
import { UsersStore } from 'src/app/core/stores';
import { Timestamp } from 'src/app/firebase';
import { SharedModule } from 'src/app/shared';
import { ButtonComponent, ButtonSize, ButtonStyle } from 'src/app/standalone/shared';
import { CrossIconComponent } from 'src/app/standalone/shared/components/cross-icon/cross-icon.component';

@Component({
  selector: 'app-tag',
  standalone: true,
  imports: [ReactiveFormsModule, SharedModule, CrossIconComponent, ButtonComponent],
  templateUrl: './tag.component.html',
  styleUrl: './tag.component.scss',
})
export class TagComponent implements OnInit {
  isUpdating = signal(false);
  tag = signal<ITag>(null);
  pageTitle = signal<string>(null);
  form: FormGroup;
  buttonSize = signal<typeof ButtonSize>(ButtonSize);
  buttonStyle = signal<typeof ButtonStyle>(ButtonStyle);
  isSaving = signal(false);
  createAnotherControl = new FormControl(null);

  #ref = inject(DynamicDialogRef);
  #fb = inject(FormBuilder);
  #config = inject(DynamicDialogConfig);
  #usersStore = inject(UsersStore);
  #tagsService = inject(TagsService);

  get canUpdate(): boolean {
    return !this.isUpdating() && this.form.valid && this.form.dirty;
  }

  ngOnInit(): void {
    this.tag.set(this.#config.data?.tag ?? null);
    this.pageTitle.set(this.tag() ? 'tagComponent.editTitle' : 'tagComponent.addTitle');
    this.form = this.#fb.group({
      title: [null, Validators.required],
    });

    if (this.tag()) {
      this.form.patchValue({
        title: this.tag().title,
      });
    }
  }

  onCloseDialog(tag: ITag = null): void {
    this.#ref.close(tag);
  }

  async create(): Promise<void> {
    let tag: ITag;
    try {
      this.isUpdating.set(true);

      if (this.tag()) {
        const tagForUpdated: ITag = {
          ...this.tag(),
          title: this.form.value.title,
          _title_: this.form.value.title.toLowerCase(),
          updatedAt: Timestamp.now(),
          updatedBy: this.#usersStore.userId,
        };

        tag = await this.#tagsService.update(this.tag().id, tagForUpdated);
      } else {
        tag = await this.#tagsService.create(this.form.value.title);
      }
    } catch (error) {
      console.log(error);
    } finally {
      if (this.createAnotherControl.value) {
        this.form.reset({ createAnotherControl: true });
      } else {
        this.onCloseDialog(tag);
      }
      this.isUpdating.set(false);
    }
  }
}
