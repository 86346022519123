import { Component, OnDestroy, OnInit, Signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavigationEnd, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { filter, startWith, takeUntil, tap } from 'rxjs/operators';
import { MenuItem } from 'primeng/api';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

import { FeatureHeaderComponent, FeatureMainContentComponent } from 'src/app/standalone/admin';
import { ICourse, ICustomSidebarMenuItem, IHub, SidebarItemsLayout } from 'src/app/core/models';
import { CoursesStore, HubsStore } from 'src/app/core/stores';
import { goToLink } from 'src/app/core/utils';
import { CoursesService } from 'src/app/core/services';
import { ButtonComponent } from 'src/app/standalone/shared';
import { ButtonSize, ButtonStyle } from 'src/app/standalone/shared/enums';

@Component({
  selector: 'app-course-container',
  standalone: true,
  imports: [
    CommonModule,
    FeatureHeaderComponent,
    FeatureMainContentComponent,
    TranslateModule,
    ButtonComponent,
  ],
  templateUrl: './course-container.component.html',
  styleUrls: ['./course-container.component.scss'],
})
export class CourseContainerComponent implements OnInit, OnDestroy {
  items: ICustomSidebarMenuItem[];
  activeMenuItem: MenuItem;
  icon: string;
  btnLabel: string;
  statusChangeInProgress: boolean;
  buttonStyle = ButtonStyle;
  buttonSize = ButtonSize;
  isHideSidebarInAdminCourse: Signal<boolean> = this.coursesStore.isHideSidebarInAdminCourse;

  private unsubscribe$ = new Subject<void>();

  constructor(
    private translateService: TranslateService,
    public hubsStore: HubsStore,
    private router: Router,
    private coursesStore: CoursesStore,
    private coursesService: CoursesService,
  ) {}

  get hub(): IHub {
    return this.hubsStore.hub;
  }

  get course(): ICourse {
    return this.coursesStore.adminCourse;
  }

  ngOnInit(): void {
    this.updateBtnProperties();
    this.items = [
      {
        label: this.translateService.instant('adminCourse.menuItemSetup'),
        icon: 'far fa-gear',
        routerLink: `/${this.hubsStore.useHubUrl}/admin/courses/${this.course?.link}/setup`,
        sidebarItems: {
          layout: SidebarItemsLayout.DEFAULT,
          menuItems: [
            {
              items: [
                {
                  label: this.translateService.instant('adminCourse.menuItemDashboard'),
                  routerLink: `/${this.hubsStore.useHubUrl}/admin/courses/${this.course?.link}/setup/dashboard`,
                },
              ],
            },
            {
              groupTitle: this.translateService.instant('adminCourse.groupTitlePlanCourse'),
              items: [
                {
                  label: this.translateService.instant('adminCourse.menuItemBasicInfo'),
                  routerLink: `/${this.hubsStore.useHubUrl}/admin/courses/${this.course?.link}/setup/basic-info`,
                },
                {
                  label: this.translateService.instant('adminCourse.menuItemAudience'),
                  routerLink: `/${this.hubsStore.useHubUrl}/admin/courses/${this.course?.link}/setup/audience`,
                },
                {
                  label: this.translateService.instant('adminCourse.menuItemInstructors'),
                  routerLink: `/${this.hubsStore.useHubUrl}/admin/courses/${this.course?.link}/setup/instructors`,
                },
                // TODO: uncomment for future implementation or delete (before deleting please clarify with Sebastian)
                // {
                //   label: this.translateService.instant('adminCourse.menuItemBrands'),
                //   routerLink: `/${this.hubsStore.useHubUrl}/admin/courses/${this.course?.link}/setup/brands`
                // }
              ],
            },
            {
              groupTitle: this.translateService.instant('adminCourse.groupTitleCreateCourse'),
              items: [
                {
                  label: this.translateService.instant('adminCourse.menuItemMedia'),
                  routerLink: `/${this.hubsStore.useHubUrl}/admin/courses/${this.course?.link}/setup/media`,
                },
                {
                  label: this.translateService.instant('adminCourse.menuItemChapters'),
                  routerLink: `/${this.hubsStore.useHubUrl}/admin/courses/${this.course?.link}/setup/chapters`,
                },
              ],
            },
            {
              groupTitle: this.translateService.instant('adminCourse.groupTitlePublishCourse'),
              items: [
                {
                  label: this.translateService.instant('adminCourse.menuItemLandingPage'),
                  routerLink: `/${this.hubsStore.useHubUrl}/admin/courses/${this.course?.link}/setup/landing-page`,
                },
                {
                  label: this.translateService.instant('adminCourse.menuItemTickets'),
                  routerLink: `/${this.hubsStore.useHubUrl}/admin/courses/${this.course?.link}/setup/tickets`,
                },
                {
                  label: this.translateService.instant('adminCourse.menuItemPromotions'),
                  routerLink: `/${this.hubsStore.useHubUrl}/admin/courses/${this.course?.link}/setup/promotions`,
                },
                {
                  label: this.translateService.instant('adminCourse.menuItemMessages'),
                  routerLink: `/${this.hubsStore.useHubUrl}/admin/courses/${this.course?.link}/setup/messages`,
                },
              ],
            },
          ],
        },
      },
      {
        label: this.translateService.instant('adminCourse.menuItemSubscribers'),
        icon: 'fak fa-lox-team',
        routerLink: `/${this.hubsStore.useHubUrl}/admin/courses/${this.course.link}/subscribers`,
      },
      // {
      //   label: this.translateService.instant('adminCourse.menuItemReviews'),
      //   icon: 'fa-regular fa-comment-pen',
      //   routerLink: `/${this.hubsStore.useHubUrl}/admin/courses/${this.course.link}/reviews`
      // },
      {
        label: this.translateService.instant('adminCourse.menuItemReports'),
        icon: 'fa-regular fa-chart-simple',
        routerLink: `/${this.hubsStore.useHubUrl}/admin/courses/${this.course.link}/reports`,
        items: [
          {
            label: this.translateService.instant('adminCourse.menuItemsAssets'),
            icon: 'fal fa-photo-video',
            routerLink: `/${this.hub ? this.hubsStore.useHubUrl : ''}/admin/courses/${this.course.link}/reports`,
          },
          {
            label: this.translateService.instant('adminCourse.menuItemSubscribers'),
            icon: 'fak fa-lox-team',
            routerLink: `/${this.hub ? this.hubsStore.useHubUrl : ''}/admin/courses/${this.course.link}/subscriber-reports`,
          },
        ],
      },
    ];

    if (!this.hubsStore.hub.brands) {
      this.items.forEach((item) => {
        if (item.sidebarItems && item.sidebarItems.menuItems) {
          item.sidebarItems.menuItems.forEach((menuItem) => {
            if (menuItem.items) {
              menuItem.items = menuItem.items.filter((subItem) => {
                return (
                  subItem.label !== this.translateService.instant('adminCourse.menuItemBrands')
                );
              });
            }
          });
        }
      });
    }

    this.router.events
      .pipe(
        filter((event: NavigationEnd) => event instanceof NavigationEnd),
        startWith(this.router),
        tap(() => {
          this.activeMenuItem = this.items.find((item: MenuItem) =>
            this.router.url.includes(item.routerLink),
          );
        }),
        takeUntil(this.unsubscribe$),
      )
      .subscribe();
  }

  navigateToCourse(): void {
    const newRelativeUrl = this.router.createUrlTree([
      `${this.hubsStore.useHubUrl}`,
      'courses',
      this.course.link,
    ]);
    const baseUrl = window.location.href.replace(this.router.url, '');
    goToLink(baseUrl + newRelativeUrl);
  }

  async toggleCourseStatus(): Promise<void> {
    this.statusChangeInProgress = true;
    this.icon = 'pi pi-spinner pi-spin';

    if (this.course.publicationDate) {
      await this.coursesService.unpublishCourse(this.course.id);
    } else {
      await this.coursesService.publishCourse(this.course.id);
    }

    this.coursesStore.adminCourse = await this.coursesService.getOne(this.course.id, true, false);
    this.statusChangeInProgress = false;
    this.updateBtnProperties();
  }

  updateBtnProperties(): void {
    this.icon = this.course.publicationDate ? 'far fa-circle-check' : 'far fa-paper-plane';
    this.btnLabel = this.course.publicationDate
      ? 'adminCourse.publishLoading'
      : 'adminCourse.publishLabel';
  }

  toggleActive(item: any) {
    if (!this.course.checkedNavigationItems) this.course.checkedNavigationItems = [];
    if (this.course.checkedNavigationItems?.includes(item.label)) {
      this.course.checkedNavigationItems = this.course.checkedNavigationItems.filter(
        (i) => i !== item.label,
      );
    } else {
      this.course.checkedNavigationItems.push(item.label);
    }
    this.coursesService.updateCourseCheckedNavigationItems(
      this.course.id,
      this.course.checkedNavigationItems,
    );
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
