import { CanDeactivateFn } from '@angular/router';

import { CourseSubscriberInstructorComponent } from '../components';

export const courseInstructorExitGuard: CanDeactivateFn<CourseSubscriberInstructorComponent> = (
  component: CourseSubscriberInstructorComponent,
): boolean => {
  component.updateStateForHidingSidebarInAdminCourse();

  return true;
};
