<div class="dashboard-container">
  <h1 class="title">{{ 'adminDashboardAnalytics.title' | translate }}</h1>
  <div class="stat-cards-container">
    @for (stat of stats(); track $index; let last = $last) {
      <div class="card-container">
        <app-stat-card
          [iconClass]="stat.iconClass"
          [text]="stat.text"
          [value]="stat.value"
          [isShowTooltip]="stat.isShowTooltip"
          [tooltipText]="stat.tooltipText" />
      </div>
      @if (!last) {
        <div class="vertical-divider">
          <p-divider
            styleClass="custom-divider-vertical-2"
            layout="vertical">
          </p-divider>
        </div>
      }
    }
  </div>
  <div class="charts-container">
    @for (chart of charts(); track $index) {
      <app-stat-chart
        [statName]="chart.statName"
        [chartType]="chart.type"
        [chartData]="chart.data"
        [chartOptions]="chart.options"
        [chartStyles]="chart.chartStyles"
        [chartLegend]="chart.chartLegend"
        [isShowTooltip]="chart.isShowTooltip"
        [tooltipText]="chart.tooltipText">
      </app-stat-chart>
    }
  </div>
</div>
