import { Component, OnInit, inject, signal } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { FilterMatchMode, MessageService } from 'primeng/api';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { TranslateService } from '@ngx-translate/core';

import { ConfirmDialogComponent, IAdminTableColumn, SharedModule } from 'src/app/shared';
import { EventsStore } from 'src/app/core/stores';
import { EmailsService } from 'src/app/core/services';
import { eventAudience } from 'src/app/core/utils';
import { IAudience, IEmail } from 'src/app/core/models';
import { UnlayerEmailEditorComponent } from 'src/app/admin/components';
import { ButtonSize, ButtonStyle, ButtonType } from 'src/app/standalone';
import { AttachEmailsComponent } from 'src/app/admin/dialogs/attach-emails/attach-emails.component';
import { ButtonComponent, StatusTagComponent } from 'src/app/standalone/shared/components';

@Component({
  selector: 'app-event-emails',
  standalone: true,
  imports: [SharedModule, ButtonComponent, StatusTagComponent],
  templateUrl: './event-emails.component.html',
  styleUrl: './event-emails.component.scss',
})
export class EventEmailsComponent implements OnInit {
  loading = signal(true);
  loadingEntries = signal(true);
  searchValue = signal('');
  searchFilterFields = signal<string[]>(['title', 'language']);
  columns = signal<IAdminTableColumn[]>(null);
  entries = signal<IEmail[]>([]);
  buttonType = signal<typeof ButtonType>(ButtonType);
  buttonSize = signal<typeof ButtonSize>(ButtonSize);
  buttonStyle = signal<typeof ButtonStyle>(ButtonStyle);

  private emailsService = inject(EmailsService);
  private translateService = inject(TranslateService);
  private route = inject(ActivatedRoute);
  private router = inject(Router);
  private dialogService = inject(DialogService);
  private messageService = inject(MessageService);
  private eventsStore = inject(EventsStore);

  async ngOnInit(): Promise<void> {
    this.columns.set([
      {
        field: 'title',
        titleTranslationKey: 'adminEmailTemplates.tableColumnTitle',
        sortable: true,
      },
      {
        field: 'language',
        titleTranslationKey: 'adminEmailTemplates.tableColumnLanguage',
        template: 'langCell',
        filterable: true,
        filterMatchMode: FilterMatchMode.IN,
        filterOptions: this.translateService.getLangs().map((lang: string) => ({
          name: this.translateService.instant('languages.' + lang),
          value: lang,
        })),
        filterType: 'text',
      },
      {
        field: 'audience',
        titleTranslationKey: 'adminEmailTemplates.tableColumnAudience',
        filterable: true,
        filterMatchMode: FilterMatchMode.IN,
        filterOptions: eventAudience.map((item: IAudience) => ({
          name: item.label,
          value: item.value,
        })),
        filterType: 'text',
      },
      { field: 'actions', template: 'actionsCell' },
    ]);
    await this.fetchEmails();
    this.loadingEntries.set(false);
    this.loading.set(false);
  }

  async attach(): Promise<void> {
    const attachDialogRef: DynamicDialogRef = this.dialogService.open(AttachEmailsComponent, {
      width: '90%',
      height: '80%',
      dismissableMask: true,
      closable: false,
      contentStyle: {
        padding: '0',
        'border-radius': '20px',
      },
      data: { attachTo: 'events' },
    });

    await firstValueFrom(attachDialogRef.onClose);
    await this.fetchEmails();
  }

  onRowClicked(id: string): void {
    const email: IEmail = this.entries().find((entry: IEmail) => entry.id === id);
    this.navigateToEmail(email);
  }

  async edit(event: Event, email: IEmail): Promise<void> {
    event.stopPropagation();
    this.navigateToEmail(email);
  }

  async remove(event: Event, entry: IEmail): Promise<void> {
    event.stopPropagation();
    const deleteDialogRef: DynamicDialogRef = this.dialogService.open(ConfirmDialogComponent, {
      closable: false,
      styleClass: 'confirm-dialog',
      data: {
        titleKey: 'adminEmailTemplates.deattachTitle',
        descriptionKey: 'adminEmailTemplates.deattachText',
        confirmBtnKey: 'adminEmailTemplates.confirmBtn',
        cancelBtnKey: 'adminEmailTemplates.cancelBtn',
        className: 'fa-solid fa-trash-xmark',
        entry,
      },
    });

    const result: 'cancel' | 'confirm' = await firstValueFrom(deleteDialogRef.onClose);

    if (result === 'confirm') {
      try {
        this.loadingEntries.set(true);
        const deleteResult: boolean = await this.emailsService.unAttach('events', entry.id);
        if (deleteResult) {
          this.showToastMessage('success', 'adminEmailTemplates.succesDeattach');
        }
        await this.fetchEmails();
        this.loadingEntries.set(false);
      } catch (error) {
        this.showToastMessage('error', 'adminEmailTemplates.errorDeattach');
      }
    }
  }

  private async fetchEmails(): Promise<void> {
    this.loadingEntries.set(true);
    this.loading.set(true);

    const eventEmails: IEmail[] = await this.emailsService.getEventEmails(
      this.eventsStore.adminEvent.id,
    );
    this.entries.set(eventEmails);

    this.loadingEntries.set(false);
    this.loading.set(false);
  }

  private async navigateToEmail(email: IEmail): Promise<void> {
    if (email.isUnlayer) {
      const unlayerDialogRef: DynamicDialogRef = this.dialogService.open(
        UnlayerEmailEditorComponent,
        {
          closable: false,
          styleClass: 'unlayer-email-editor-dialog',
          data: { email },
        },
      );

      const response: boolean = await firstValueFrom(unlayerDialogRef.onClose);
      if (response) {
        this.fetchEmails();
      }
    } else {
      this.router.navigate([email.id], {
        relativeTo: this.route,
        state: { email },
      });
    }
  }

  private showToastMessage(severity: 'success' | 'error', detail: string): void {
    this.messageService.add({
      severity,
      summary: this.translateService.instant(severity),
      detail: this.translateService.instant(detail),
      styleClass: 'custom-toast',
    });
  }
}
