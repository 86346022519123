import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';

import { ICourse, ITag } from 'src/app/core/models';
import { TagsService } from 'src/app/core/services';
import { CoursesStore } from 'src/app/core/stores';

export const tagsByIdsResolver: ResolveFn<ITag[]> = (): Promise<ITag[]> => {
  const coursesStore = inject(CoursesStore);
  const tagsService = inject(TagsService);

  const course: ICourse = coursesStore.adminCourse;

  return tagsService.getByIdsOrderedByTitle(course.tags);
};
