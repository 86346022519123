export const stripeLocaleConfig = (currentLang: string) => {
  switch (currentLang) {
    case 'en':
      return 'en-US';
    case 'de':
      return 'de';
    default:
      return 'en-US';
  }
};
