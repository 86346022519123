import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';

import { ICourseTicket } from 'src/app/core/models';
import { TicketsService } from 'src/app/core/services';
import { CoursesStore } from 'src/app/core/stores';

export const courseTicketsResolver: ResolveFn<ICourseTicket[]> = (): Promise<ICourseTicket[]> => {
  const coursesStore = inject(CoursesStore);
  const ticketsService = inject(TicketsService);

  const courseId: string = coursesStore.adminCourse.id;

  return ticketsService.getCourseTickets(courseId);
};
