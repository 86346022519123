import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';

import { ICourse, IEvent } from 'src/app/core/models';
import { CoursesService, EventsService } from 'src/app/core/services';
import { HubsStore } from 'src/app/core/stores';

export const isExistEventOrCourseInHubResolver: ResolveFn<boolean> = async (): Promise<boolean> => {
  const hubsStore = inject(HubsStore);
  const eventsService = inject(EventsService);
  const coursesService = inject(CoursesService);

  const hubId: string = hubsStore.hubId;
  const events: IEvent[] = await eventsService.getAllByHubId(hubId);
  const isEventExists: boolean = !!events.length;

  if (isEventExists) {
    return true;
  }

  const courses: ICourse[] = await coursesService.getAllByHubId(hubId);
  const isCourseExists: boolean = !!courses.length;

  if (isCourseExists) {
    return true;
  }

  return false;
};
