import CryptoJS from 'crypto-js';
import { inject, Injectable } from '@angular/core';
import { Intercom } from '@supy-io/ngx-intercom';
import { TranslateService } from '@ngx-translate/core';

import { AppStore } from 'src/app/app.store';
import { IUser } from '../../models';

@Injectable({
  providedIn: 'root',
})
export class IntercomService {
  #intercom = inject(Intercom);
  #appStore = inject(AppStore);
  #translateService = inject(TranslateService);

  boot(appId: string, user?: IUser): void {
    if (this.#appStore.environment.firebase.projectId.includes('pmg-hub')) {
      return;
    }

    let userData = {};

    if (user) {
      const { firstName, lastName, _firstName_, _lastName_, profileImage, ...otherData } = user;
      userData = {
        ...otherData,
        user_hash: CryptoJS.HmacSHA256(
          user.id,
          this.#appStore.environment.intercom.identityVerificationSecret,
        ).toString(CryptoJS.enc.Hex),
        name: `${user.firstName} ${user.lastName}`,
        user_id: user.id,
        displayEmail: user.email,
        email: user.email.toLowerCase(),
        phone: user.phone,
        role: user.role,
        avatar: { avatar: 'avatar', image_url: user.profileImage },
        language: user.systemLanguage,
        company: user.company,
        position: user.position,
        department: user.department,
        website: user.website,
        city: user.city,
        country: user.country,
        created_at: user.createdAt.seconds,
        language_override: this.#translateService.currentLang,
      };
    }

    this.#intercom.boot({ app_id: appId, ...userData });
  }

  hide(): void {
    this.#intercom.hide();
  }

  update(): void {
    this.#intercom.update();
  }

  shutdown(): void {
    this.#intercom.shutdown();
  }
}
