export interface IStatCard {
  iconClass: string;
  text: string;
  value: string;
  isShowTooltip?: boolean;
  tooltipText?: string;
  tooltipPosition?: string;
  tooltipStyleClass?: string;
  tooltipEvent?: string;
  tabIndex?: number;
}
