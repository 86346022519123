import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';

import { StripeService } from 'src/app/core/services';
import { CoursesStore } from 'src/app/core/stores';
import { IStripeReport } from 'src/app/core/models';

export const courseStripeReportResolver: ResolveFn<IStripeReport> = (): Promise<IStripeReport> => {
  const coursesStore = inject(CoursesStore);
  const stripeService = inject(StripeService);

  const courseId: string = coursesStore.adminCourse.id;

  return stripeService.searchPaymentIntent(courseId);
};
