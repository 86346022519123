<ng-container *ngIf="loading">
  <app-loader></app-loader>
</ng-container>

<div
  *ngIf="!loading"
  class="table-container">
  <div class="c-mb-4">
    <admin-table-header-navbar [title]="'adminCourse.menuItemSubscribers' | translate">
      <div class="btn-actions-container">
        <app-button
          buttonText="{{ 'shared.sendEmails' | translate }}"
          buttonIconLeft="far fa-envelope"
          [buttonStyle]="buttonStyle.FILLED_SECONDARY"
          [buttonSize]="buttonSize.MEDIUM"
          [disabled]="!selectedEntries.length"
          (click)="sendInvites()" />
        <app-button
          buttonText="{{ 'exportFields.exportBtnLabel' | translate }}"
          buttonIconLeft="fa-solid fa-up-from-bracket"
          [buttonStyle]="buttonStyle.FILLED_SECONDARY"
          [buttonSize]="buttonSize.MEDIUM"
          (click)="toggleExportSidebar()" />
        <app-button-list
          [items]="btnListItems"
          btnLabel="shared.add">
        </app-button-list>
      </div>
    </admin-table-header-navbar>
  </div>

  <admin-table-filter-navbar
    [filters]="tableFilters"
    (onFiltersChange)="filtersChange($event)"
    (onSearchTermChange)="searchTerm = $event">
  </admin-table-filter-navbar>

  <admin-table
    [loading]="loadingEntries"
    [columns]="columns"
    [entries]="entries"
    [lazy]="false"
    [pageSize]="pageSize"
    [searchValue]="searchTerm"
    [searchFilterFields]="searchFilterFields"
    [checkBoxSelection]="true"
    [(selectedEntries)]="selectedEntries"
    emptyStateKey="adminSubscribers.emptyState"
    emptyStateIcon="fak fa-lox-team"
    (lazyLoad)="fetchEntries($event)"
    (rowClicked)="onRowClick($event)">
    <ng-template
      let-entry
      epTemplateKey="logoCell">
      <image
        [src]="entry?.profileImage"
        [text]="imageText(entry)"
        [size]="'extra-small'"></image>
    </ng-template>

    <ng-template
      let-entry
      epTemplateKey="statusCell">
      <app-status-tag
        [iconClass]="entry.status === 'active' ? 'fa-regular fa-check' : 'fa-regular fa-xmark'"
        tagText="{{
          (entry.status === 'active' ? 'adminTable.statusActive' : 'adminTable.statusInactive')
            | translate
        }}"
        [bgColor]="entry.status === 'active' ? 'var(--appPrimaryColor)' : '#ff244c'" />
    </ng-template>

    <ng-template
      let-entry
      epTemplateKey="actionsCell">
      <div class="table-btn-actions-container">
        <app-button
          [buttonType]="buttonType.ROUND"
          [buttonSize]="buttonSize.MEDIUM"
          [buttonStyle]="buttonStyle.FILLED_SECONDARY"
          buttonRoundIcon="far fa-pen-to-square"
          (click)="navigateToSubscriber($event, entry)" />
        <app-button
          [buttonType]="buttonType.ROUND"
          [buttonSize]="buttonSize.MEDIUM"
          [buttonStyle]="buttonStyle.FILLED_DANGER"
          buttonRoundIcon="far fa-trash-can"
          (click)="unAttachSubscriber($event, entry)" />
      </div>
    </ng-template>
  </admin-table>
</div>

<p-sidebar
  styleClass="custom-sidebar"
  [(visible)]="sidebarVisible"
  position="right"
  [showCloseIcon]="false">
  <app-sidebar-user-info-container
    [userId]="userIdForSidebar"
    (closeSidebar)="onCloseSidebar()">
  </app-sidebar-user-info-container>
</p-sidebar>

<p-sidebar
  styleClass="export-fields-sidebar"
  [(visible)]="showExportSidebar"
  position="right"
  baseZIndex="1">
  <app-export-fields-in-course userRole="subscriber"> </app-export-fields-in-course>
</p-sidebar>
