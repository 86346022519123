import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';

import { IUserCourse } from 'src/app/core/models';
import { CourseUserService } from 'src/app/core/services';
import { CoursesStore } from 'src/app/core/stores';

export const courseUserResolver: ResolveFn<IUserCourse> = (
  route: ActivatedRouteSnapshot,
): Promise<IUserCourse> => {
  const coursesStore = inject(CoursesStore);
  const courseUserService = inject(CourseUserService);

  const userId = route.parent.paramMap.get('userId');
  const courseId: string = coursesStore.adminCourse.id;

  return courseUserService.getUserCourse(courseId, userId);
};
