import { Component, inject, signal } from '@angular/core';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { TranslateModule } from '@ngx-translate/core';

import { ButtonComponent } from 'src/app/standalone/shared/components/button/button.component';
import { CrossIconComponent } from 'src/app/standalone/shared/components/cross-icon/cross-icon.component';
import { ButtonSize, ButtonStyle } from 'src/app/standalone/shared';

@Component({
  selector: 'app-manage-translations',
  standalone: true,
  imports: [TranslateModule, CrossIconComponent, ButtonComponent],
  templateUrl: './manage-translations.component.html',
  styleUrl: './manage-translations.component.scss',
})
export class ManageTranslationsComponent {
  isUpdating = signal(false);
  buttonStyle = signal<typeof ButtonStyle>(ButtonStyle);
  buttonSize = signal<typeof ButtonSize>(ButtonSize);

  #ref = inject(DynamicDialogRef);

  get canUpdate(): boolean {
    return true;
  }

  onCloseDialog(): void {
    this.#ref.close();
  }

  onSave(): void {}
}
