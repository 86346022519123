<app-feature-header
  [headerTitle]="course?.title"
  [menuItems]="items"
  backUrl="{{ hubsStore.useHubUrl }}/admin/courses">
  <app-button
    class="c-mr-2"
    buttonText="{{ 'adminCourse.viewCourse' | translate }}"
    buttonIconLeft="fa-regular fa-arrow-up-right-from-square"
    [buttonStyle]="buttonStyle.GHOST_PRIMARY"
    [buttonSize]="buttonSize.MEDIUM"
    (click)="navigateToCourse()" />
  <app-button
    [buttonText]="btnLabel | translate"
    [buttonIconLeft]="icon"
    [buttonStyle]="buttonStyle.TERTIARY_PRIMARY"
    [buttonSize]="buttonSize.MEDIUM"
    (click)="toggleCourseStatus()" />
</app-feature-header>
<app-feature-main-content
  [activeMenuItem]="activeMenuItem"
  [checkedMenuItems]="course.checkedNavigationItems"
  [isHideFeatureSidebar]="isHideSidebarInAdminCourse()"
  (checkClicked)="toggleActive($event)">
</app-feature-main-content>
