import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';

import { UserHubsService } from 'src/app/core/services';
import { HubsStore } from 'src/app/core/stores';

export const userRegistrationsByWeekResolver: ResolveFn<number[]> = (): Promise<number[]> => {
  const hubsStore = inject(HubsStore);
  const userHubsService = inject(UserHubsService);

  const hubId: string = hubsStore.hubId;

  return userHubsService.getUserRegistrationsOverPeriods(hubId, 'week', 6, new Date());
};
