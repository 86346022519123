import * as moment from 'moment';
import { Component, inject, OnDestroy, OnInit, Signal, signal } from '@angular/core';
import {
  AbstractControl,
  AsyncValidatorFn,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import {
  Observable,
  Subject,
  debounceTime,
  distinctUntilChanged,
  map,
  merge,
  takeUntil,
  tap,
} from 'rxjs';
import { MessageService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { ClipboardService } from 'ngx-clipboard';

import { IEvent, IHub, ITag, ITimeZone, IUser, IUserHub } from 'src/app/core/models';
import {
  EventsService,
  FormService,
  TagsService,
  UserHubsService,
  UsersService,
} from 'src/app/core/services';
import { EventsStore, HubsStore } from 'src/app/core/stores';
import { VIRTUAL_SCROLL_ITEM_SIZE, asyncDelay, sortItems, utcTimeZones } from 'src/app/core/utils';
import { EventsMaxLengthValidators, SharedModule, parseToMoment } from 'src/app/shared';
import { SaveDiscardActionsComponent, ToastComponent } from 'src/app/standalone/shared';
import { Timestamp } from 'src/app/firebase';

@Component({
  selector: 'app-event-details',
  standalone: true,
  imports: [SharedModule, SaveDiscardActionsComponent, ToastComponent],
  templateUrl: './event-details.component.html',
  styleUrl: './event-details.component.scss',
})
export class EventDetailsComponent implements OnInit, OnDestroy {
  form: FormGroup;
  copyIsClicked = signal(false);
  loading = signal(true);
  usersForShowing = signal<IUser[]>(null);
  tags = signal<ITag[]>(null);
  isUpdating = signal(false);
  endDateMin = signal<Date>(null);

  virtualScrollItemSize: Signal<number> = signal(VIRTUAL_SCROLL_ITEM_SIZE);
  timezoneOptionItems: Signal<ITimeZone[]> = signal(utcTimeZones);

  private users = signal<IUser[]>(null);
  private unsubscribe$ = new Subject<void>();
  private fb = inject(FormBuilder);
  private eventsService = inject(EventsService);
  private hubsStore = inject(HubsStore);
  private translateService = inject(TranslateService);
  private clipboardService = inject(ClipboardService);
  private usersService = inject(UsersService);
  private userHubsService = inject(UserHubsService);
  private tagsService = inject(TagsService);
  private eventsStore = inject(EventsStore);
  private formService = inject(FormService);
  private messageService = inject(MessageService);

  get event(): IEvent {
    return this.eventsStore.adminEvent;
  }

  get hub(): IHub {
    return this.hubsStore.hub;
  }

  get linkPrefix(): string {
    return this.hub
      ? `${this.hubsStore.environmentBaseEventsUrl}${this.hubsStore.useHubUrl}/`
      : this.hubsStore.environmentBaseEventsUrl;
  }

  get linkErrorText(): string {
    if (this.form?.controls?.link?.errors?.required) {
      return this.translateService.instant('adminNewEvent.eventLinkReq');
    }

    if (this.form?.controls?.link?.errors?.pattern) {
      return this.translateService.instant('adminNewEvent.eventLinkInvalidPattern');
    }

    if (this.form?.controls?.link?.errors?.exists) {
      return this.translateService.instant('adminEventSettings.linkExists');
    }

    return '';
  }

  get tooltipText(): string {
    return !this.copyIsClicked()
      ? this.translateService.instant('copyTooltip.hover')
      : this.translateService.instant('copyTooltip.action');
  }

  get canUpdate(): boolean {
    return (
      !this.isUpdating() && this.form.valid && this.form.dirty && this.formService.isValueChanged()
    );
  }

  async ngOnInit(): Promise<void> {
    this.createForm();
    this.updateForm();
    this.formService.setForm(this.form);
    this.endDateMin.set(parseToMoment(this.event.start).toDate());
    const ownersAndAdminsUserHubs: IUserHub[] =
      await this.userHubsService.getOwnersAndAdminsUserHubs(this.hub.id);
    const ownersAndAdminsUserHubIds: string[] = ownersAndAdminsUserHubs.map(
      (item: IUserHub) => item.userId,
    );
    const users: IUser[] = await this.usersService.getUserByIds(ownersAndAdminsUserHubIds);
    this.users.set(users);
    this.usersForShowing.set(this.searchableUsers(users));

    const tagIds: string[] = await this.tagsService.getHubTagsIdsArray(this.event.hubId);
    const tags: ITag[] = await this.tagsService.getByIdsOrderedByTitle(tagIds);
    this.tags.set(tags);

    if (this.form.controls.link.value.startsWith(this.linkPrefix)) {
      this.form.controls.link.setValue(this.form.controls.link.value.slice(this.linkPrefix.length));
    }
    this.loading.set(false);

    const eventStartDate$ = this.form.controls.start.valueChanges;
    const eventStartTime$ = this.form.controls.startTime.valueChanges;
    merge(eventStartDate$, eventStartTime$)
      .pipe(
        tap((value) => {
          this.endDateMin.set(value);
        }),
        takeUntil(this.unsubscribe$),
      )
      .subscribe();
  }

  createForm(): void {
    this.form = this.fb.group({
      title: [null, [Validators.required, Validators.maxLength(EventsMaxLengthValidators.title)]],
      accountingNumber: [null],
      tagline: [null, Validators.maxLength(EventsMaxLengthValidators.tagline)],
      link: [
        null,
        [
          Validators.required,
          Validators.pattern(/^\S+$/),
          Validators.pattern(/^((?!http:|www\.|https:|\/|\\).)*$/),
        ],
        this.linkExistsValidator(),
      ],
      user: [null, Validators.required],
      start: [null, Validators.required],
      startTime: [null, Validators.required],
      end: [null, Validators.required],
      endTime: [null, Validators.required],
      location: [null],
      timezone: [null, Validators.required],
      description: [null],
      shortDescription: [null],
      tags: [[]],
    });
  }

  updateForm(): void {
    const startDate =
      this.event.start instanceof Date
        ? this.event.start
        : parseToMoment(this.event.start).toDate();
    const endDate =
      this.event.end instanceof Date ? this.event.end : parseToMoment(this.event.end).toDate();

    this.form.patchValue({
      ...this.event,
      start: startDate,
      end: endDate,
      startTime: startDate,
      endTime: endDate,
    });
  }

  getMaxLengthValue(formControlName: string): number {
    return Number(EventsMaxLengthValidators[formControlName]);
  }

  copyToClipboard(): void {
    this.clipboardService.copy(`${this.linkPrefix}${this.form.controls.link.value}`);
    this.copyIsClicked.set(true);
  }

  selectTags(tagIds: string[]): void {
    this.form.patchValue({ tags: tagIds });
  }

  onDiscard(): void {
    this.updateForm();
  }

  async onConfirm(): Promise<void> {
    try {
      this.isUpdating.set(true);
      await asyncDelay(1);
      await this.updateEvent();
      this.formService.setForm(this.form);
    } catch (error) {
      console.error(error);
    }
  }

  async updateEvent(): Promise<void> {
    try {
      this.isUpdating.set(true);
      const { start, startTime, end, endTime, ...others } = this.form.getRawValue();

      const eventStart: Timestamp = Timestamp.fromMillis(
        moment(new Date(start))
          .set({ hour: moment(startTime).hour(), minute: moment(startTime).minute() })
          .toDate()
          .getTime(),
      );
      const eventEnd: Timestamp = Timestamp.fromMillis(
        moment(end)
          .set({ hours: moment(endTime).hour(), minutes: moment(endTime).minute() })
          .toDate()
          .getTime(),
      );
      const eventForUpdate: IEvent = {
        ...this.event,
        ...others,
        start: eventStart,
        end: eventEnd,
      };
      const updatedEvent: IEvent = await this.eventsService.update(this.event.id, eventForUpdate);
      if (!eventForUpdate.tags.length) {
        await this.eventsService.makeEventRegisterTagsNotMandatory(eventForUpdate.id);
      }
      const newEvent = { ...this.eventsStore.adminEvent, ...updatedEvent };
      this.eventsStore.setAdminEvent(newEvent);

      this.messageService.add({
        severity: 'success',
        summary: this.translateService.instant('success'),
        detail: this.translateService.instant('adminEventSettings.successUpdateEvent'),
        styleClass: 'custom-toast',
      });
    } catch (error) {
      console.error(error);
    } finally {
      this.isUpdating.set(false);
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private linkExistsValidator(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> =>
      this.eventsService.checkEventByLinkExists(control.value, this.event.id).pipe(
        debounceTime(500),
        distinctUntilChanged(),
        map((linkExists) => (linkExists ? { exists: linkExists } : null)),
      );
  }

  private searchableUsers(users: IUser[]): (IUser & { name: string })[] {
    return users
      ?.map((user: IUser) => ({ ...user, name: `${user.firstName} ${user.lastName}` }))
      .sort((firstUser: IUser & { name: string }, secondUser: IUser & { name: string }) =>
        sortItems(firstUser.name, secondUser.name, 'asc'),
      );
  }
}
