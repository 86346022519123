import { ChartConfiguration, ChartOptions, ChartType } from 'chart.js';

export interface IStatChart {
  statName: string;
  type: ChartType;
  data: ChartConfiguration<ChartType>['data'];
  options: ChartOptions<ChartType>;
  chartStyles: { [key: string]: string };
  chartLegend?: boolean;
  isShowTooltip?: boolean;
  tooltipText?: string;
}
