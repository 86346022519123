import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';

import { CustomProgressBarComponent, AssetEventBannerComponent } from 'src/app/standalone/shared';
import {
  IAdminTableColumn,
  SharedModule,
  imgPreloader,
  logoAvatar,
  videoImg,
  jpgImg,
  pdfImg,
  pngImg,
  zipImg,
} from 'src/app/shared';
import { CourseAssetsService, CourseTrackingService } from 'src/app/core/services';
import { CoursesStore, HubsStore } from 'src/app/core/stores';
import { AssetFileTypes, AssetType } from 'src/app/core/enums';
import { IAsset } from 'src/app/core/models';

@Component({
  selector: 'app-reports',
  standalone: true,
  imports: [CommonModule, SharedModule, CustomProgressBarComponent, AssetEventBannerComponent],
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss'],
})
export class ReportsComponent implements OnInit {
  loading = true;
  loadingEntries = true;
  columns: IAdminTableColumn[] = [
    {
      field: 'title',
      template: 'titleCell',
      titleTranslationKey: 'courseReports.assetTitle',
      sortable: true,
    },
    {
      field: 'viewersCount',
      template: 'viewersCountCell',
      titleTranslationKey: 'courseReports.assetViewers',
      sortable: true,
    },
    {
      field: 'averagePercentWatched',
      template: 'progressCell',
      titleTranslationKey: 'courseReports.assetAveragePercentWatched',
      sortable: true,
    },
  ];
  allEntries: any = [];
  entries: any = [];
  totalRecords = 0;
  pageSize = 10;
  imgPreloader = imgPreloader;
  logoAvatar = logoAvatar;
  zipImg = zipImg;
  assetType = AssetType;
  assets: IAsset[] = [];
  courseId: string;
  searchFilterFields: string[] = [];
  searchTerm = '';
  assetEventBannerContainerStyles = {
    background: '#323232',
    'border-radius': '0.25rem',
  };
  assetEventBannerMonthStyles = {
    'font-size': '0.625rem',
    'line-height': '0.625rem',
  };
  assetEventBannerDateStyle = {
    'font-size': '1.125rem',
    'line-height': '1.125rem',
  };

  constructor(
    private courseTrackingService: CourseTrackingService,
    private coursesStore: CoursesStore,
    private courseAssetsService: CourseAssetsService,
    private router: Router,
    private hubsStore: HubsStore,
  ) {}

  ngOnInit(): void {
    this.setSearchFilterFields();
    this.courseId = this.coursesStore.adminCourse.id;
    this.fetchAssetsData();
  }

  getPercentageString(percentage: any): string {
    if (percentage === 0) {
      return `0 %`;
    } else {
      return `${percentage.toFixed(1)} %`;
    }
  }

  getImagePlaceholder(asset: IAsset): string {
    if (asset.type === AssetType.VIDEO) {
      return videoImg;
    }

    switch (asset.fileType) {
      case AssetFileTypes.PNG:
        return pngImg;
      case AssetFileTypes.JPG:
        return jpgImg;
      case AssetFileTypes.PDF:
        return pdfImg;
      case AssetFileTypes.ZIP:
        return zipImg;
      default:
        return pngImg;
    }
  }

  async fetchAssetsData(): Promise<void> {
    const courseAssets = await this.courseAssetsService.getAllCourseAssetsByCourseId(this.courseId);
    const courseAssetsIds: string[] = courseAssets.map((ca) => ca.assetId);
    this.assets = await this.courseAssetsService.getAssetsByIds(courseAssetsIds);
    this.allEntries = await this.getInfoForAssets(this.assets);
    this.entries = this.allEntries;
    this.totalRecords = this.entries.length;
    this.loadingEntries = false;
    this.loading = false;
  }

  async getInfoForAssets(assets: IAsset[]): Promise<any[]> {
    const infoPromises = assets.map(async (asset) => {
      const info = await this.courseTrackingService.getAllByAssetId(asset.id, this.courseId);
      return { ...asset, ...info };
    });

    return Promise.all(infoPromises);
  }

  async onSearchTermChange(keyword: string): Promise<void> {
    this.searchTerm = keyword;
  }

  onRowClick(assetId: string) {
    this.router.navigate([
      `${this.hubsStore.useHubUrl}/admin/courses/${this.coursesStore.adminCourse.link}/asset-reports/${assetId}`,
    ]);
  }

  private setSearchFilterFields(): void {
    this.searchFilterFields = ['title', 'viewersCount', 'viewsCount', 'averagePercentWatched'];
  }
}
