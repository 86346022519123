import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';

import { IAdminTableColumn, SharedModule } from 'src/app/shared';
import { IHub, IHubPage } from 'src/app/core/models';
import { HubPagesService } from 'src/app/core/services';
import { HubsStore } from 'src/app/core/stores';
import { ButtonSize, ButtonType, ButtonStyle, StatusTagComponent } from 'src/app/standalone/shared';

@Component({
  selector: 'app-hub-pages',
  standalone: true,
  imports: [CommonModule, SharedModule, StatusTagComponent],
  templateUrl: './hub-pages.component.html',
  styleUrls: ['./hub-pages.component.scss'],
})
export class HubPagesComponent {
  loading = true;
  loadingEntries = true;
  columns: IAdminTableColumn[] = [
    { field: 'title', titleTranslationKey: 'adminEventPagesList.tableColumnTitle', sortable: true },
    {
      field: 'status',
      titleTranslationKey: 'adminEventPagesList.tableColumnStatus',
      template: 'statusCell',
      sortable: true,
    },
    { field: 'actions', template: 'actionsCell' },
  ];
  hubPages: IHubPage[] = [];
  scssClassForTable = 'table-border-top-radius';
  buttonSize = ButtonSize;
  buttonType = ButtonType;
  buttonStyle = ButtonStyle;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private hubPagesService: HubPagesService,
    private hubsStore: HubsStore,
  ) {}

  get hub(): IHub {
    return this.hubsStore.hub;
  }

  async ngOnInit(): Promise<void> {
    await this.fetchPages();
    this.loadingEntries = false;
    this.loading = false;
  }

  edit(event: Event, hubPage: IHubPage): void {
    event.stopPropagation();
    this.router.navigate([hubPage.id], { relativeTo: this.route });
  }

  private async fetchPages(): Promise<void> {
    this.hubPages = await this.hubPagesService.getHubPagesByHubId(this.hub.id);
  }
}
