import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';

import { CourseTrackingService } from 'src/app/core/services';
import { CoursesStore } from 'src/app/core/stores';

export const courseViewersResolver: ResolveFn<number> = (): Promise<number> => {
  const coursesStore = inject(CoursesStore);
  const courseTrackingService = inject(CourseTrackingService);

  const courseId: string = coursesStore.adminCourse.id;

  return courseTrackingService.getViewersCountByCourseId(courseId);
};
