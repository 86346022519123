<ng-container *ngIf="loading">
  <app-loader></app-loader>
</ng-container>

<div
  *ngIf="!loading"
  class="admin-attendees-container">
  <div class="admin-attendees-table-container">
    <div class="c-mb-4">
      <admin-table-header-navbar [title]="'adminEvent.menuItemAttendees' | translate">
        <div class="flex align-items-center gap-2">
          <app-button
            buttonText="{{ 'adminEventAttendees.sendEmails' | translate }}"
            buttonIconLeft="far fa-envelope"
            [buttonStyle]="buttonStyle.FILLED_SECONDARY"
            [buttonSize]="buttonSize.MEDIUM"
            [disabled]="!selectedEntries.length"
            (click)="sendInvites()" />
          <app-button
            buttonText="{{ 'exportFields.exportBtnLabel' | translate }}"
            buttonIconLeft="fa-solid fa-up-from-bracket"
            [buttonStyle]="buttonStyle.FILLED_SECONDARY"
            [buttonSize]="buttonSize.MEDIUM"
            (click)="toggleExportSidebar()" />
          <app-button-list
            [items]="btnListItems"
            btnLabel="shared.add">
          </app-button-list>
        </div>
      </admin-table-header-navbar>
    </div>

    <admin-table-filter-navbar
      [filters]="tableFilters"
      (onFiltersChange)="filtersChange($event)"
      (onSearchTermChange)="searchValue = $event">
    </admin-table-filter-navbar>

    <admin-table
      [loading]="loadingEntries"
      [columns]="columns"
      [entries]="entries"
      [lazy]="false"
      [searchValue]="searchValue"
      [searchFilterFields]="searchFilterFields"
      [checkBoxSelection]="true"
      [(selectedEntries)]="selectedEntries"
      emptyStateKey="adminEventAttendees.emptyState"
      emptyStateIcon="fak fa-lox-attendees"
      (rowClicked)="onRowClicked($event)">
      <ng-template
        let-entry
        epTemplateKey="logoCell">
        <image
          [src]="entry?.profileImage"
          [text]="imageText(entry)"
          [size]="'extra-small'"></image>
      </ng-template>

      <ng-template
        let-entry
        epTemplateKey="statusCell">
        <app-status-tag
          *ngIf="!entry.checkedIn"
          [iconClass]="
            entry.eventStatus === 'active'
              ? 'fa-regular fa-check'
              : entry.eventStatus === 'pending'
                ? 'fa-regular fa-clock'
                : 'fa-regular fa-xmark'
          "
          tagText="{{
            (entry.eventStatus === 'active'
              ? 'adminTable.statusActive'
              : entry.eventStatus === 'pending'
                ? 'adminTable.statusPending'
                : 'adminTable.statusInactive'
            ) | translate
          }}"
          [bgColor]="
            entry.eventStatus === 'active'
              ? 'var(--appPrimaryColor)'
              : entry.eventStatus === 'pending'
                ? 'var(--app-secondary)'
                : '#ff244c'
          ">
        </app-status-tag>
        <app-status-tag
          *ngIf="entry.checkedIn"
          iconClass="fa-regular fa-check"
          tagText="{{ 'adminTable.statusCheckedIn' | translate }}"
          bgColor="var(--appPrimaryColor)" />
      </ng-template>

      <ng-template
        let-entry
        epTemplateKey="actionsCell">
        <div class="table-btn-actions-container">
          <app-button
            [buttonType]="buttonType.ROUND"
            [buttonSize]="buttonSize.MEDIUM"
            [buttonStyle]="buttonStyle.FILLED_SECONDARY"
            [loading]="entry?.isActionBtnShowingSpinner"
            buttonRoundIcon="far fa-pen-to-square"
            (click)="navigateToAttendee($event, entry)" />
          <app-button
            [buttonType]="buttonType.ROUND"
            [buttonSize]="buttonSize.MEDIUM"
            [buttonStyle]="buttonStyle.FILLED_DANGER"
            [loading]="entry?.isActionBtnShowingSpinner"
            buttonRoundIcon="far fa-trash-can"
            (click)="removeAttendee($event, entry)" />
          <app-button
            [buttonType]="buttonType.ROUND"
            [buttonSize]="buttonSize.MEDIUM"
            [buttonStyle]="buttonStyle.FILLED_SUCCESS"
            [loading]="entry?.isActionBtnShowingSpinner"
            buttonRoundIcon="far fa-arrow-up-right-from-square"
            (click)="copyInvite($event, entry)" />
        </div>
      </ng-template>
    </admin-table>

    <p-sidebar
      [(visible)]="showExportSidebar"
      [styleClass]="'email-merge-tags-sidebar'"
      [style]="{
        width: '40%',
        background: 'var(--background-sidebar)',
        color: 'var(--text-primary)',
        border: '0',
      }"
      position="right"
      baseZIndex="1">
      <app-export-fields-selector></app-export-fields-selector>
    </p-sidebar>
  </div>
</div>

<p-sidebar
  styleClass="custom-sidebar"
  [(visible)]="sidebarVisible"
  position="right"
  [showCloseIcon]="false">
  <app-sidebar-user-info-container
    [userId]="userIdForSidebar"
    (closeSidebar)="onCloseSidebar()">
  </app-sidebar-user-info-container>
</p-sidebar>

<p-toast>
  <ng-template
    let-message
    pTemplate="message">
    <app-toast
      [severity]="message.severity"
      [summary]="message.summary"
      [detail]="message.detail">
    </app-toast>
  </ng-template>
</p-toast>
