import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { trackByFn } from 'src/app/core/utils';
import {
  IAdminTableColumn,
  SharedModule,
  imgPreloader,
  logoAvatar,
  videoImg,
  jpgImg,
  pdfImg,
  pngImg,
  zipImg,
} from 'src/app/shared';
import { AssetsService, CourseTrackingService, UsersService } from 'src/app/core/services';
import { IAsset, IUser } from 'src/app/core/models';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {
  ItemDetailHeaderComponent,
  StatCardComponent,
  StatusTagComponent,
} from 'src/app/standalone/shared';
import { CoursesStore, HubsStore } from 'src/app/core/stores';
import { userAvatar } from 'src/app/shared/utils';
import { AssetFileTypes, AssetType } from 'src/app/core/enums';

@Component({
  selector: 'app-subscriber-reports',
  standalone: true,
  imports: [
    CommonModule,
    SharedModule,
    StatCardComponent,
    ItemDetailHeaderComponent,
    StatusTagComponent,
  ],
  templateUrl: './subscriber-reports.component.html',
  styleUrls: ['./subscriber-reports.component.scss'],
})
export class SubscriberReportsComponent implements OnInit {
  loading = true;
  loadingEntries = true;

  trackByFn = trackByFn;
  statCards: { iconClass: string; text: string; value: string }[];
  columns: IAdminTableColumn[] = [];
  entries = [];
  totalRecords = 0;
  pageSize = 10;
  imgPreloader = imgPreloader;
  logoAvatar = logoAvatar;
  userAvatar = userAvatar;
  userId: string;
  user: IUser;
  fullName = '';
  assets: IAsset[] = [];
  courseId: string;
  searchTerm = '';
  searchFilterFields: string[] = [];

  constructor(
    private usersService: UsersService,
    private router: Router,
    private courseTrackingService: CourseTrackingService,
    private translateService: TranslateService,
    private assetsService: AssetsService,
    private coursesStore: CoursesStore,
    private hubsStore: HubsStore,
  ) {}

  async ngOnInit(): Promise<void> {
    this.courseId = this.coursesStore.adminCourse.id;
    this.initializeStatCards();
    this.setSearchFilterFields();
    this.userId = this.extractUserIdFromURL();
    await this.loadUserData();
    this.fetchStatisticsData();
    await this.fetchAssetsData();
  }

  getCardsContainerWidth(): string {
    const maxWidthPercentage = 100;
    const minItemWidthPercentage = 10;
    const items = this.statCards.length > 0 ? this.statCards.length : 1;
    const calculatedWidth = Math.min(maxWidthPercentage, minItemWidthPercentage * items);
    return `${calculatedWidth}%`;
  }

  getPercentageString(percentage: number): string {
    if (percentage === 0) {
      return `0 %`;
    } else {
      return `${percentage.toFixed(1)} %`;
    }
  }

  async onSearchTermChange(keyword: string): Promise<void> {
    this.searchTerm = keyword;
  }

  getImagePlaceholder(asset: IAsset): string {
    if (asset.type === AssetType.VIDEO) {
      return videoImg;
    }

    switch (asset.fileType) {
      case AssetFileTypes.PNG:
        return pngImg;
      case AssetFileTypes.JPG:
        return jpgImg;
      case AssetFileTypes.PDF:
        return pdfImg;
      case AssetFileTypes.ZIP:
        return zipImg;
      default:
        return pngImg;
    }
  }

  onBackClick(): void {
    this.router.navigate([
      `${this.hubsStore.useHubUrl}/admin/courses/${this.coursesStore.adminCourse.link}/subscriber-reports`,
    ]);
  }

  onRowClick(assetId: string) {
    this.router.navigate([
      `${this.hubsStore.useHubUrl}/admin/courses/${this.coursesStore.adminCourse.link}/asset-reports/${assetId}`,
    ]);
  }

  private initializeStatCards(): void {
    this.statCards = [
      { iconClass: 'fa-regular fa-eye', text: 'courseReports.totalViews', value: '0' },
      { iconClass: 'fa-regular fa-timer', text: 'courseReports.averageTimeSpent', value: '0' },
      { iconClass: 'fa-regular fa-percent', text: 'courseReports.averageWathed', value: '0' },
    ];

    this.columns = [
      {
        field: 'title',
        template: 'titleCell',
        titleTranslationKey: 'courseReports.assetTitle',
        sortable: true,
      },
      {
        field: 'viewed',
        template: 'statusCell',
        titleTranslationKey: 'courseReports.viewed',
        sortable: true,
      },
      // {
      //   field: 'views',
      //   template: 'countCell',
      //   titleTranslationKey: 'courseReports.assetViews',
      //   sortable: true
      // },
      {
        field: 'averagePercentWatched',
        template: 'progressCell',
        titleTranslationKey: 'courseReports.assetAveragePercentWatched',
        sortable: true,
      },
    ];
  }

  private extractUserIdFromURL(): string {
    return this.router.url.substring(this.router.url.lastIndexOf('/') + 1);
  }

  private async loadUserData(): Promise<void> {
    this.user = await this.usersService.getOne(this.userId);
    this.fullName = `${this.user.firstName} ${this.user.lastName}`;
  }

  private async fetchStatisticsData(): Promise<void> {
    const [totalViews, avgTimeSpent, avgPercentView] = await Promise.all([
      this.courseTrackingService.getTotalViewsByUserId(this.userId, this.courseId),
      this.courseTrackingService.getAverageTimeSpentByUserId(this.userId, this.courseId),
      this.courseTrackingService.getAveragePercentViewByUserId(this.userId, this.courseId),
    ]);

    this.updateStatCards(totalViews, avgTimeSpent, avgPercentView);
    this.loading = false;
  }

  private updateStatCards(totalViews: number, avgTimeSpent: number, avgPercentView: number): void {
    this.statCards[0].value = totalViews.toString();
    this.statCards[1].value = `${avgTimeSpent.toFixed(1)} ${this.translateService.instant('courseReports.min')}`;
    this.statCards[2].value = avgPercentView.toFixed(1);
  }

  private async fetchAssetsData(): Promise<void> {
    const userCoursesTrackings = await this.courseTrackingService.getAllByUserId(
      this.userId,
      this.courseId,
    );
    this.assets = await this.assetsService.getAssetsByIds(
      userCoursesTrackings.map((ct) => ct.assetId),
    );
    this.entries = await this.getInfoForAssets(this.assets);
    console.log('@entries', this.entries);

    this.loadingEntries = false;
  }

  private async getInfoForAssets(assets: IAsset[]): Promise<any[]> {
    const infoPromises = assets.map(async (asset) => {
      const info = await this.courseTrackingService.getAllInfoByUserAndAssetId(
        this.userId,
        asset.id,
        this.courseId,
      );
      return { ...asset, ...info };
    });

    return Promise.all(infoPromises);
  }

  private setSearchFilterFields() {
    this.searchFilterFields = ['title', 'viewed', 'views', 'averagePercentWatched'];
  }
}
