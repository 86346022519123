import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subject, from } from 'rxjs';
import { debounceTime, switchMap, takeUntil, tap } from 'rxjs/operators';
import { MessageService } from 'primeng/api';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { TranslateService } from '@ngx-translate/core';

import { IEvent, ITag, IUser, IUserEvent } from 'src/app/core/models';
import { EventsService, UsersService, EventUserService } from 'src/app/core/services';
import { EventsStore, TagsStore, UsersStore } from 'src/app/core/stores';
import { Timestamp } from 'src/app/firebase';
import { AppStore } from 'src/app/app.store';
import { IAdminTableColumn } from 'src/app/shared';
import { ButtonSize } from 'src/app/standalone';

@Component({
  templateUrl: './attach-speakers-dialog.component.html',
  styleUrls: ['./attach-speakers-dialog.component.scss'],
})
export class AttachSpeakersDialogComponent implements OnInit, OnDestroy {
  attachLoading = false;
  loadingEntries = true;
  entries: IUser[] = [];
  selectedEntries: IUser[] = [];
  totalRecords = 0;
  pageSize = 10;
  tags: ITag[] = [];
  selectedTags: ITag[] = [];
  columns: IAdminTableColumn[] = [
    { field: 'firstName', titleTranslationKey: 'adminUsers.tableColFirstName' },
    { field: 'lastName', titleTranslationKey: 'adminUsers.tableColLastName' },
    { field: 'email', titleTranslationKey: 'adminUsers.tableColEmail' },
    { field: 'company', titleTranslationKey: 'adminUsers.tableColCompany' },
    { field: 'position', titleTranslationKey: 'adminUsers.tableColPosition' },
  ];
  speakerSearchTerm = new FormControl('');
  tagSearchTerm = new FormControl('');
  buttonSize = ButtonSize;

  private page = 0;
  private unsubscribe$ = new Subject<void>();

  constructor(
    private eventStore: EventsStore,
    private tagStore: TagsStore,
    private eventService: EventsService,
    private messageService: MessageService,
    private translateService: TranslateService,
    private usersStore: UsersStore,
    private usersService: UsersService,
    private eventUserService: EventUserService,
    public appStore: AppStore,
    private ref: DynamicDialogRef,
  ) {}

  get filteredTags(): ITag[] {
    return this.tags.filter((tag) =>
      tag.title.toLowerCase().includes(this.tagSearchTerm.value.toLowerCase()),
    );
  }

  get event(): IEvent {
    return this.eventStore.adminEvent;
  }

  async ngOnInit(): Promise<void> {
    await Promise.all([this.fetchSpeakers()]);
    this.tags = this.tagStore.tags.filter((tag) => this.event.tags.includes(tag.id));
    this.loadingEntries = false;

    this.speakerSearchTerm.valueChanges
      .pipe(
        debounceTime(300),
        tap(() => {
          this.loadingEntries = true;
        }),
        switchMap(() => from(this.fetchSpeakers())),
        tap(() => {
          this.loadingEntries = false;
        }),
        takeUntil(this.unsubscribe$),
      )
      .subscribe();
  }

  async fetchEntries(event: any): Promise<void> {
    this.loadingEntries = true;
    this.pageSize = event.rows;
    this.page = event.first / this.pageSize;
    await this.fetchSpeakers();
    this.loadingEntries = false;
  }

  async attach(): Promise<void> {
    this.attachLoading = true;

    await Promise.all(
      this.selectedEntries.map((speaker: IUser) => {
        const newUserEvent: IUserEvent = {
          id: null,
          eventId: this.event.id,
          userId: speaker.id,
          _firstName_: speaker.firstName.toLowerCase(),
          _lastName_: speaker.lastName.toLowerCase(),
          _company_: speaker?.company?.toLowerCase() || null,
          _position_: speaker.position ? speaker.position.toLowerCase() : null,
          _event_title_: this.event.title.toLowerCase(),
          role: 'speaker',
          isBrandPerson: false,
          ticketId: null,
          ticketPrice: null,
          status: 'pending',
          createdAt: Timestamp.now(),
          createdBy: this.usersStore.user.id,
          updatedAt: null,
          updatedBy: null,
          tags: speaker.tags,
          joinAt: null,
          acceptedConsents: null,
          acceptedMultiConsents: null,
          popUpConsents: null,
          hotel: null,
          roomType: null,
          roomPartner: null,
          preferredAirport: null,
          alternativeAirport: null,
          checkedIn: false,
        };
        return this.eventService.addUserToEvent(newUserEvent);
      }),
    );

    this.messageService.add({
      key: 'user',
      severity: 'success',
      summary: this.translateService.instant('success'),
      detail: this.translateService.instant('adminEvents.userSuccessfullyAttached', {
        count: this.selectedEntries.length,
      }),
      styleClass: 'custom-toast',
    });
    this.attachLoading = false;
    this.selectedEntries = [];
    await this.fetchSpeakers();
  }

  async tagSelectionChanged(tags: ITag[]): Promise<void> {
    this.selectedTags = tags;
    await this.fetchSpeakers();
  }

  onClearSpeakerSearchTerm(): void {
    this.speakerSearchTerm.setValue('');
  }

  onClearTagSearchTerm(): void {
    this.tagSearchTerm.setValue('');
  }

  onCloseDialog(): void {
    this.ref.close();
  }

  private async fetchSpeakers(): Promise<void> {
    const response = await this.eventUserService.getUnattachedUsersFromAlgolia(
      this.event.hubId,
      this.event.id,
      this.page,
      this.pageSize,
      [...this.selectedTags.map((t) => t.id), this.speakerSearchTerm.value].join(' '),
    );

    if (this.appStore.generalSystemSettings.enableEncryption) {
      response.results = response.results.map((b) => {
        return this.usersService.decryptUserData(b, ['_highlightResult', 'objectID']) as IUser;
      });
    }

    this.entries = response.results;
    this.totalRecords = response.total;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
