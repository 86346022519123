import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';

import { CoursesStore } from 'src/app/core/stores';

export const courseInstructorGuard: CanActivateFn = () => {
  const coursesStore = inject(CoursesStore);
  coursesStore.updateStateForHidingSidebarInAdminCourse(true);

  return true;
};
