import { Component, computed, inject, OnInit, signal, Signal } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { FilterMatchMode, MessageService } from 'primeng/api';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { TranslateService } from '@ngx-translate/core';

import {
  DailyCoService,
  SessionsService,
  UserSessionsService,
  OpenDialogService,
} from 'src/app/core/services';
import { IAdminTableColumn, SharedModule } from 'src/app/shared';
import { HubsStore, EventsStore } from 'src/app/core/stores';
import { IEvent, ISession, IStage } from 'src/app/core/models';
import { NewSessionComponent } from 'src/app/admin';
import { Timestamp } from 'src/app/firebase';
import { ButtonSize, ButtonStyle, ButtonType } from 'src/app/standalone/shared/enums';
import { ButtonComponent, StatusTagComponent } from 'src/app/standalone/shared/components';

interface Session extends Omit<ISession, 'date'> {
  date: Timestamp | Date;
}

type SessionEntry = Session & { numberUserSessions: number; date: Date };

@Component({
  selector: 'app-event-sessions',
  standalone: true,
  imports: [SharedModule, ButtonComponent, StatusTagComponent],
  templateUrl: './event-sessions.component.html',
  styleUrl: './event-sessions.component.scss',
})
export class EventSessionsComponent implements OnInit {
  loading = signal(true);
  loadingEntries = signal(true);
  searchValue = signal('');
  searchFilterFields: Signal<string[]> = signal([
    'title',
    'stageName',
    'sessionDate',
    'start',
    'end',
  ]);
  columns = signal<IAdminTableColumn[]>(null);
  entries = signal<SessionEntry[]>([]);
  buttonType: Signal<typeof ButtonType> = signal(ButtonType);
  buttonSize: Signal<typeof ButtonSize> = signal(ButtonSize);
  buttonStyle: Signal<typeof ButtonStyle> = signal(ButtonStyle);

  #filterBooleanOptions = signal<{ name: string; value: boolean }[]>(null);
  #stagedUsedInSessions = computed<IStage[]>(() => {
    const sessions: SessionEntry[] = this.entries();
    return sessions
      .reduce((acc: IStage[], curr: SessionEntry) => {
        if (curr?.stage) {
          acc.push(curr.stage);
        }

        return acc;
      }, [])
      .filter((stage: IStage, index: number, self: IStage[]) => {
        return index === self.findIndex((element: IStage) => element?.id === stage?.id);
      });
  });

  #router = inject(Router);
  #route = inject(ActivatedRoute);
  #eventStore = inject(EventsStore);
  #dialogService = inject(DialogService);
  #messageService = inject(MessageService);
  #sessionsService = inject(SessionsService);
  #dailyCoService = inject(DailyCoService);
  #translateService = inject(TranslateService);
  #userSessionsService = inject(UserSessionsService);
  #openDialogService = inject(OpenDialogService);
  #hubsStore = inject(HubsStore);

  get event(): IEvent {
    return this.#eventStore.adminEvent;
  }

  ngOnInit(): void {
    this.#filterBooleanOptions.set([
      { name: this.#translateService.instant('adminEventsSessions.yes'), value: true },
      { name: this.#translateService.instant('adminEventsSessions.no'), value: false },
    ]);
    this.columns.set([
      { field: 'title', titleTranslationKey: 'adminEventsSessions.tableCells.title' },
      {
        field: 'stageName',
        titleTranslationKey: 'adminEventsSessions.tableCells.stage',
        filterable: true,
        filterMatchMode: FilterMatchMode.IN,
        filterOptions: null,
        filterType: 'text',
      },
      {
        field: 'sessionDate',
        titleTranslationKey: 'adminEventsSessions.tableCells.date',
        template: 'dateCell',
        filterable: true,
        filterMatchMode: FilterMatchMode.DATE_IS,
        filterType: 'date',
        validatorMinDate: this.event.start.toDate(),
        validatorMaxDate: this.event.end.toDate(),
      },
      {
        field: 'start',
        titleTranslationKey: 'adminEventsSessions.tableCells.start',
        template: 'startCell',
      },
      {
        field: 'end',
        titleTranslationKey: 'adminEventsSessions.tableCells.end',
        template: 'endCell',
      },
      {
        field: 'space',
        titleTranslationKey: 'adminEventsSessions.tableCells.space',
        template: 'statusCell',
        filterable: true,
        filterMatchMode: FilterMatchMode.IS,
        filterOptions: this.#filterBooleanOptions(),
        filterType: 'boolean',
      },
      {
        field: 'featuredSession',
        titleTranslationKey: 'adminEventsSessions.tableCells.featured',
        template: 'featuredCell',
        filterable: true,
        filterMatchMode: FilterMatchMode.IS,
        filterOptions: this.#filterBooleanOptions(),
        filterType: 'boolean',
      },
      {
        field: 'availability',
        titleTranslationKey: 'adminEventsSessions.tableCells.availability',
        template: 'availabilityCell',
      },
      { field: 'actions', template: 'actionsCell' },
    ]);
    this.fetchEntries();
  }

  textForAvailability(sessionEntry: SessionEntry): string {
    if (!sessionEntry?.availability) {
      return '-';
    }

    if (sessionEntry.availability === sessionEntry.numberUserSessions) {
      return this.#translateService.instant('adminEventsSessions.bookedUp');
    }

    return `${sessionEntry.numberUserSessions}/${sessionEntry.availability}`;
  }

  edit(event: Event, entry: ISession): void {
    event.stopPropagation();
    this.#router.navigate(
      [
        `/${this.#hubsStore.useHubUrl}/admin/event/${this.event.link}/content/sessions/${entry.id}/basic-info`,
      ],
      {
        relativeTo: this.#route,
        state: {
          entry: entry,
        },
      },
    );
  }

  async remove(event: Event, entry: ISession): Promise<void> {
    event.stopPropagation();
    const deleteDialogRef: DynamicDialogRef = this.#openDialogService.openConfirmDialog(
      'adminEventsSessions.removeSession',
      'adminEventsSessions.removeSessionText',
      'adminEventsSessions.confirmBtn',
      'adminEventsSessions.cancelBtn',
    );

    const result: 'cancel' | 'confirm' = await firstValueFrom(deleteDialogRef.onClose);
    if (result === 'confirm') {
      try {
        const numberUserSessions: number = await this.#userSessionsService.numberUserSessions(
          this.event.id,
          entry.id,
        );
        if (numberUserSessions) {
          this.showToastMessage(
            'error',
            this.#translateService.instant('adminEventsSessions.notPossibleDeleteSessionAlert'),
          );
          return;
        }
        let deleteResult = await this.#sessionsService.remove(entry.id, this.event.id);
        if (entry.meetingRoomName) {
          await firstValueFrom(this.#dailyCoService.deleteRoom(entry.meetingRoomName));
        }
        await this.fetchEntries();
        if (deleteResult) {
          this.showToastMessage(
            'success',
            this.#translateService.instant('adminEventsSessions.successDeleteSession'),
          );
        }
      } catch (error) {
        this.showToastMessage(
          'error',
          this.#translateService.instant('adminEventsSessions.errorDeleteSession'),
        );
      }
    }
  }

  async openNewSessionDialog(): Promise<void> {
    const newSessionDialogRef: DynamicDialogRef = this.#dialogService.open(NewSessionComponent, {
      width: '40%',
      height: '60%',
      closable: false,
      styleClass: 'attach-new-session-dialog',
    });
    await firstValueFrom(newSessionDialogRef.onClose);
    await this.fetchEntries();
  }

  onRowClicked(id: string): void {
    this.#router.navigate([
      `/${this.#hubsStore.useHubUrl}/admin/event/${this.event.link}/content/sessions/${id}/basic-info`,
    ]);
  }

  private async fetchEntries(): Promise<void> {
    this.loading.set(true);
    this.loadingEntries.set(true);
    const sessions: ISession[] = await this.#sessionsService.getAll(this.event.id);
    const entries = await Promise.all([
      ...sessions.map(async (session: ISession) => {
        const numberUserSessions: number = await this.#userSessionsService.numberUserSessions(
          this.event.id,
          session.id,
        );
        return { ...session, date: session.date.toDate(), numberUserSessions };
      }),
    ]);
    this.entries.set(entries);
    this.updateColumns();
    this.loading.set(false);
    this.loadingEntries.set(false);
  }

  private updateColumns(): void {
    this.columns.update((columnsCurrentValue: IAdminTableColumn[]) =>
      columnsCurrentValue.map((column: IAdminTableColumn) => {
        if (column.field === 'stageName') {
          return {
            ...column,
            filterOptions: this.#stagedUsedInSessions().map((stage: IStage) => ({
              name: stage?.name,
              value: stage?.name,
            })),
          };
        }
        return column;
      }),
    );
  }

  private showToastMessage(severity: 'success' | 'error', detail: string): void {
    this.#messageService.add({
      severity,
      summary: this.#translateService.instant(severity),
      detail: this.#translateService.instant(detail),
      styleClass: 'custom-toast',
    });
  }
}
