@if (!!activeMenuItem()?.sidebarItems && !isHideFeatureSidebar()) {
  <div class="two-column-layout">
    <div class="left-side">
      <app-feature-sidebar
        [currentMenuItem]="activeMenuItem()"
        [checkedMenuItems]="checkedMenuItems()"
        (checkClicked)="toggleActive($event)" />
    </div>
    <div class="right-side">
      <router-outlet></router-outlet>
    </div>
  </div>
} @else {
  <div class="one-column-layout">
    <router-outlet></router-outlet>
  </div>
}
