import * as moment from 'moment';
import firebase from 'firebase/compat/app';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, from } from 'rxjs';
import { map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { saveAs } from 'file-saver';

import { parseToMoment, API_ROUTES as apiRoutes } from 'src/app/shared';
import {
  AlgoliaSearchResult,
  IEvent,
  defaultEventRegistrationForm,
  IUser,
  IUserEvent,
  IEventSettings,
  IEventRegistrationForm,
  IEventProduct,
  IBrandProduct,
  IBrand,
  ISubscription,
  IEventSubscription,
  IEventConsent,
  IEventBrand,
  IStage,
} from 'src/app/core/models';
import { UsersStore, EventsStore } from 'src/app/core/stores';
import { environment } from 'src/environments/environment';
import {
  Firestore,
  IDocumentData,
  IDocumentReference,
  IDocumentSnapshot,
  IFirestore,
  IQuery,
  IQueryDocumentSnapshot,
  IQuerySnapshot,
  ServerTimestamp,
  Timestamp,
} from 'src/app/firebase';
import { AppStore } from 'src/app/app.store';
import {
  AlgoliaService,
  StorageService,
  AuthorizationService,
  SearchResultGroup,
  algoliaSearchClient,
  UsersService,
  CryptoJSService,
  StagesService,
} from 'src/app/core/services';

@Injectable({
  providedIn: 'root',
})
export class EventsService {
  private searchClient;
  private firestore: IFirestore;

  constructor(
    private http: HttpClient,
    private authorizationService: AuthorizationService,
    private storageService: StorageService,
    private eventsStore: EventsStore,
    private usersStore: UsersStore,
    private algoliaService: AlgoliaService,
    private translateService: TranslateService,
    private usersService: UsersService,
    private cryptoJSService: CryptoJSService,
    private stagesService: StagesService,
    public appStore: AppStore,
  ) {
    this.firestore = Firestore();
    this.searchClient = algoliaSearchClient();
  }

  async getEventByUserId(userId: string): Promise<IEvent[]> {
    try {
      const events: IEvent[] = (
        await this.firestore.collection(apiRoutes.events).where('user', '==', userId).get()
      ).docs.map((doc) => doc.data() as IEvent);

      return events;
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  async getEventBrands(eventId: string): Promise<IBrand[]> {
    try {
      const brands: IBrand[] = [];
      const eventBrandsQuery: IQuerySnapshot<IDocumentData> = await this.firestore
        .collection(apiRoutes.eventBrands)
        .where('eventId', '==', eventId)
        .get();

      const promises: Promise<IQuerySnapshot<IDocumentData>>[] = [];
      const brandsIds: string[] = [];
      const brandsObjects: { [key: string]: IEventBrand } = {};
      const brandsOwnersObjects = {};

      eventBrandsQuery.docs.forEach((eventBrandDoc: IQueryDocumentSnapshot<IDocumentData>) => {
        const eventBrand: IEventBrand = eventBrandDoc.data() as IEventBrand;
        brandsIds.push(eventBrand.brandId);
        brandsObjects[eventBrand.brandId] = eventBrand;
      });

      while (brandsIds.length) {
        const chunk: string[] = brandsIds.splice(0, 10);
        promises.push(
          this.firestore
            .collection(apiRoutes.brands)
            .where('id', 'in', chunk)
            .orderBy('_name_')
            .get(),
        );
      }

      const queryAllBrands: IQuerySnapshot<IDocumentData>[] = await Promise.all(promises);
      queryAllBrands.forEach((queryBrandsChunk: IQuerySnapshot<IDocumentData>) => {
        queryBrandsChunk.docs.forEach((doc: IQueryDocumentSnapshot<IDocumentData>) => {
          brands.push(doc.data() as IBrand);
        });
      });

      const fullBrands = await Promise.all(
        brands.map(async (brand) => {
          if (brand.user) {
            if (brandsOwnersObjects[brand.user]) {
              brand.owner = brandsOwnersObjects[brand.user];
            } else {
              const ownerDoc: IDocumentSnapshot<IDocumentData> = await this.firestore
                .collection(apiRoutes.users)
                .doc(brand.user)
                .get();
              brand.owner = ownerDoc.exists ? (ownerDoc.data() as IUser) : null;

              if (brand.owner && this.appStore.generalSystemSettings.enableEncryption) {
                brand.owner = this.usersService.decryptUserData(brand.owner) as IUser;
              }

              if (ownerDoc.data()) {
                brandsOwnersObjects[brand.user] = brand.owner;
              }
            }
          }

          brand['eventBrandId'] = brandsObjects[brand.id].id;
          brand['ownerDisplayEmail'] = brand.owner?.displayEmail
            ? brand.owner?.displayEmail
            : brand.owner?.email;
          brand['ownerEmail'] = brand.owner?.email.toLowerCase();
          brand['ownerName'] = brand.owner
            ? `${brand.owner?.firstName} ${brand.owner?.lastName}`
            : '-';

          return brand;
        }),
      );

      return fullBrands;
    } catch (error) {
      console.warn(error);
      throw error;
    }
  }

  async getEventsFromEventBrandsByBrandId(brandId: string): Promise<IEvent[]> {
    try {
      let events: IEvent[] = [];
      const eventBrandsQuery: IQuerySnapshot<IDocumentData> = await this.firestore
        .collection(apiRoutes.eventBrands)
        .where('brandId', '==', brandId)
        .get();

      const promises: Promise<IQuerySnapshot<IDocumentData>>[] = [];
      const eventsIds: string[] = [];

      eventBrandsQuery.docs.map(async (eventBrandDoc) => {
        const eventBrand: IEventBrand = eventBrandDoc.data() as IEventBrand;
        eventsIds.push(eventBrand.eventId);
      });

      while (eventsIds.length) {
        const chunk: string[] = eventsIds.splice(0, 10);
        promises.push(
          this.firestore
            .collection(apiRoutes.events)
            .where('id', 'in', chunk)
            .orderBy('_name_')
            .get(),
        );
        const res: IEvent[] = await this.getByIds(chunk);
        events = res;
      }

      return events;
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  async getEventBrandPeople(eventId: string): Promise<IUser[]> {
    try {
      const people: IUser[] = [];
      const peopleIds: string[] = [];
      const userEvents: Record<string, IUserEvent> = {};
      const attachedBrandIds: string[] = [];

      const [eventUsersQuery, eventBrandsQuery] = await Promise.all([
        this.firestore
          .collection(apiRoutes.userEvents)
          .where('eventId', '==', eventId)
          .where('isBrandPerson', '==', true)
          .get(),
        this.firestore.collection(apiRoutes.eventBrands).where('eventId', '==', eventId).get(),
      ]);

      eventUsersQuery.docs.map((doc) => {
        const userEventObj: IUserEvent = doc.data() as IUserEvent;
        peopleIds.push(userEventObj.userId);
        userEvents[userEventObj.userId] = doc.data() as IUserEvent;
      });

      eventBrandsQuery.docs.map((doc) => {
        const brandEventObj: IEventBrand = doc.data() as IEventBrand;
        attachedBrandIds.push(brandEventObj.brandId);
      });

      while (peopleIds.length) {
        const chunk: string[] = peopleIds.splice(0, 10);
        const queryUsers: IQuerySnapshot<IDocumentData> = await this.firestore
          .collection(apiRoutes.users)
          .where('id', 'in', chunk)
          .orderBy('_firstName_')
          .orderBy('_lastName_')
          .get();

        people.push(
          ...queryUsers.docs.map((doc) => {
            let userObj: IUser = doc.data() as IUser;

            if (userObj && this.appStore.generalSystemSettings.enableEncryption) {
              userObj = this.usersService.decryptUserData(userObj) as IUser;
            }

            userObj['isOwner'] = attachedBrandIds.includes(userObj.brandId);
            userObj['eventStatus'] = userEvents[userObj.id].status;

            return userObj;
          }),
        );
      }

      return people;
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  async fetch(
    hubId: string = null,
    pageIndex: number,
    pageSize: number,
    searchTerm: string,
    filters: string,
  ): Promise<AlgoliaSearchResult<IEvent>> {
    return await this.algoliaService.search<IEvent>(
      'events',
      [hubId, searchTerm].join(' '),
      pageSize,
      pageIndex * pageSize,
      filters,
    );
  }

  async eventBriteSync(event: IEvent, sendEmail: boolean = false): Promise<void> {
    const headers: HttpHeaders = await this.authorizationService.buildHeaders();

    return await this.http
      .get<any>(apiRoutes.eventBriteSync(event.id, sendEmail), { headers })
      .toPromise();
  }

  async getAllEventUsersByRole(eventId: string, role: 'attendee' | 'speaker'): Promise<IUser[]> {
    const usersResult: IUser[] = [];
    const allEventUsersReq: IQuerySnapshot<IDocumentData> = await this.firestore
      .collection(apiRoutes.userEvents)
      .where('eventId', '==', eventId)
      .where('role', '==', role)
      .orderBy('_firstName_')
      .get();

    const allEventUsersIds: string[] = [];
    allEventUsersIds.push(...allEventUsersReq.docs.map((doc) => doc.data().userId));

    while (allEventUsersIds.length) {
      const batch: string[] = allEventUsersIds.splice(0, 10);
      const usersQuery: IQuerySnapshot<IDocumentData> = await this.firestore
        .collection(apiRoutes.users)
        .where('id', 'in', batch)
        .get();

      usersQuery.forEach((doc) => {
        let user: IUser = doc.data() as IUser;
        if (this.appStore.generalSystemSettings.enableEncryption) {
          user = this.usersService.decryptUserData(user) as IUser;
        }
        usersResult.push(user);
      });
    }

    return usersResult;
  }

  async addUserToEvent(userEvent: IUserEvent): Promise<IUserEvent> {
    try {
      const userEventsQuery = await this.firestore
        .collection(apiRoutes.userEvents)
        .where('eventId', '==', userEvent.eventId)
        .where('userId', '==', userEvent.userId)
        .get();

      if (userEventsQuery.empty) {
        const userEventDoc: IDocumentReference<IDocumentData> = this.firestore
          .collection(apiRoutes.userEvents)
          .doc();
        userEvent.id = userEventDoc.id;
        if (this.appStore.generalSystemSettings.enableEncryption) {
          userEvent = this.usersService.encryptUserData(userEvent) as unknown as IUserEvent;
        }
        await userEventDoc.set({ ...userEvent });
        await this.algoliaService.createUserEvent(userEvent);

        if (this.appStore.generalSystemSettings.enableEncryption) {
          userEvent = this.usersService.decryptUserData(userEvent) as IUserEvent;
        }

        return userEvent;
      } else {
        throw new Error(
          `User ID: ${userEvent.userId} is already attached to the event ID: ${userEvent.eventId}`,
        );
      }
    } catch (error) {
      console.warn(error);
      throw error;
    }
  }

  async updateUserEventStatus(id: string, status: string): Promise<void> {
    try {
      if (this.appStore.generalSystemSettings.enableEncryption) {
        status = this.cryptoJSService.encrypt(status);
      }

      await this.firestore
        .collection(apiRoutes.userEvents)
        .doc(id)
        .set({ status }, { merge: true });
    } catch (error) {
      console.warn(error);
      throw error;
    }
  }

  async updateUserEvent(userEvent: IUserEvent): Promise<IUserEvent> {
    if (this.appStore.generalSystemSettings.enableEncryption) {
      userEvent = this.usersService.encryptUserData(userEvent) as unknown as IUserEvent;
    }

    await this.firestore
      .collection(apiRoutes.userEvents)
      .doc(userEvent.id)
      .update({ ...userEvent });

    if (this.appStore.generalSystemSettings.enableEncryption) {
      userEvent = this.usersService.decryptUserData(userEvent) as unknown as IUserEvent;
    }

    return userEvent;
  }

  async unattachUser(eventId: string, userId: string): Promise<void> {
    const userEventId: string = (
      await this.firestore
        .collection(apiRoutes.userEvents)
        .where('eventId', '==', eventId)
        .where('userId', '==', userId)
        .get()
    ).docs[0].id;

    await this.firestore.collection(apiRoutes.userEvents).doc(userEventId).delete();
    await this.algoliaService.removeUserEvent(userEventId);

    (
      await this.firestore
        .collection(apiRoutes.userInvites)
        .where('eventId', '==', eventId)
        .where('userId', '==', userId)
        .get()
    ).docs.map((invite) => invite.ref.delete());

    // remove association to stages
    const stages: IStage[] = await this.stagesService.getEventStages(eventId);
    await Promise.all(
      stages.map(async (stage: IStage) => {
        let updateStage = false;
        const brandPeopleIndex: number = stage.brandPeople?.indexOf(userId);
        const moderatorsIndex: number = stage.moderators?.indexOf(userId);
        const speakersIndex: number = stage.speakers?.indexOf(userId);

        if (brandPeopleIndex && brandPeopleIndex >= 0) {
          stage.brandPeople.splice(brandPeopleIndex, 1);
          updateStage = true;
        }

        if (moderatorsIndex && moderatorsIndex >= 0) {
          stage.moderators.splice(moderatorsIndex, 1);
          updateStage = true;
        }

        if (speakersIndex && speakersIndex >= 0) {
          stage.speakers.splice(speakersIndex, 1);
          updateStage = true;
        }

        if (updateStage) {
          await this.stagesService.update(eventId, stage.id, stage);
        }
      }),
    );
  }

  get(hubId: string = null, event: IEvent = null, pageSize: number = 12): Observable<IEvent[]> {
    const events: IEvent[] = [];
    let query: IQuery<IDocumentData> = this.firestore
      .collection(apiRoutes.events)
      .orderBy('start')
      .orderBy('_title_')
      .orderBy('id');

    if (!hubId && !event) {
      query = query.where('isPublished', '==', true);
    }

    if (event) {
      query = query
        .where('isPublished', '==', true)
        .startAfter(event.start, event._title_, event.id);
    }

    if (hubId) {
      query = query.where('hubId', '==', hubId);
    }

    query = query.limit(pageSize);

    return from(query.get()).pipe(
      map((response) => {
        response.forEach((doc) => events.push(doc.data() as IEvent));
        return events;
      }),
    );
  }

  async getAllByHubId(hubId: string = null): Promise<IEvent[]> {
    const querySnapshot: IQuerySnapshot<IDocumentData> = await this.firestore
      .collection(apiRoutes.events)
      .where('isDeleted', '==', false)
      .where('hubId', '==', hubId)
      .get();
    const events: IEvent[] = querySnapshot.docs.map((doc) => doc.data() as IEvent);

    return events;
  }

  async getUpcomingEventsAfterCurrentDate(
    hubId: string = null,
    event: IEvent = null,
    eventsAfter: Date,
    pageSize: number = 12,
    order: 'asc' | 'desc' = 'asc',
  ): Promise<IEvent[]> {
    const events: IEvent[] = [];
    let query: IQuery<IDocumentData> = this.firestore
      .collection(apiRoutes.events)
      .where('isDeleted', '==', false)
      .where('start', '>=', eventsAfter)
      .where('isPublished', '==', true)
      .orderBy('start', order)
      .orderBy('_title_', order)
      .orderBy('id');

    if (event) {
      query = query.startAfter(event.start, event._title_, event.id);
    }

    if (hubId) {
      query = query.where('hubId', '==', hubId);
    }

    query = query.limit(pageSize);

    const response: IQuerySnapshot<IDocumentData> = await query.get();
    response.forEach((doc) => events.push(doc.data() as IEvent));

    return events;
  }

  async getCurrentLiveEvents(
    hubId: string = null,
    order: 'asc' | 'desc' = 'asc',
  ): Promise<IEvent[]> {
    const nowDate = new Date();
    let beforeQuery: IQuery<IDocumentData> = this.firestore
      .collection(apiRoutes.events)
      .where('isDeleted', '==', false)
      .where('start', '<=', nowDate)
      .where('isPublished', '==', true)
      .orderBy('start', order)
      .orderBy('_title_', order);

    if (hubId) {
      beforeQuery = beforeQuery.where('hubId', '==', hubId);
    }

    let afterQuery: IQuery<IDocumentData> = this.firestore
      .collection(apiRoutes.events)
      .where('isDeleted', '==', false)
      .where('end', '>=', nowDate)
      .where('isPublished', '==', true)
      .orderBy('end', order)
      .orderBy('_title_', order);

    if (hubId) {
      afterQuery = afterQuery.where('hubId', '==', hubId);
    }

    const [startBefore, endAfter] = await Promise.all([beforeQuery.get(), afterQuery.get()]);
    const startBeforeEvents: IEvent[] = startBefore.docs.map((d) => d.data() as IEvent);
    const endAfterEventsIds: string[] = endAfter.docs.map((d) => d.id);

    return startBeforeEvents.filter((d) => endAfterEventsIds.includes(d.id));
  }

  async getEventsByHubId(
    hubId: string = null,
    event: IEvent = null,
    pageSize: number = 12,
    order: 'asc' | 'desc' = 'asc',
  ): Promise<IEvent[]> {
    try {
      let query: IQuery<IDocumentData> = this.firestore
        .collection(apiRoutes.events)
        .where('hubId', '==', hubId)
        .where('isPublished', '==', true)
        .orderBy('start', order)
        .orderBy('_title_', order)
        .orderBy('id');

      if (event) {
        query = query.startAfter(event.start, event._title_, event.id);
      }

      query = query.limit(pageSize);
      const events: IEvent[] = (await query.get()).docs.map((d) => d.data() as IEvent);

      return events;
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  async getLiveUpcomingEvents(
    hubId: string = null,
    event: IEvent = null,
    pageSize: number = 12,
    order: 'asc' | 'desc' = 'asc',
  ): Promise<IEvent[]> {
    try {
      const nowDate = new Date();
      let query: IQuery<IDocumentData> = this.firestore
        .collection(apiRoutes.events)
        .where('end', '>=', nowDate)
        .where('isPublished', '==', true)
        .orderBy('end')
        .orderBy('start', order)
        .orderBy('_title_', order)
        .orderBy('id');

      if (hubId) {
        query = query.where('hubId', '==', hubId);
      }

      if (event) {
        query = query.startAfter(event.end, event.start, event._title_, event.id);
      }
      query = query.limit(pageSize);
      const events: IEvent[] = (await query.get()).docs.map((d) => d.data() as IEvent);

      return events;
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  async getPastEventsBeforeCurrentDate(
    hubId: string = null,
    event: IEvent = null,
    eventsBefore: Date,
    pageSize: number = 12,
    order: 'asc' | 'desc' = 'asc',
  ): Promise<IEvent[]> {
    const events: IEvent[] = [];
    let query: IQuery<IDocumentData> = this.firestore
      .collection(apiRoutes.events)
      .where('isDeleted', '==', false)
      .where('end', '<', eventsBefore)
      .where('isPublished', '==', true)
      .orderBy('end', order === 'asc' ? 'desc' : 'asc')
      .orderBy('_title_', order)
      .orderBy('id');

    if (event) {
      query = query.startAfter(event.end, event._title_, event.id);
    }

    if (hubId) {
      query = query.where('hubId', '==', hubId);
    }
    query = query.limit(pageSize);
    const response: IQuerySnapshot<IDocumentData> = await query.get();
    response.forEach((doc) => events.push(doc.data() as IEvent));

    const result: IEvent[] = events.sort((firstEvent: IEvent, secondEvent: IEvent) => {
      return order === 'desc'
        ? moment
            .utc(parseToMoment(firstEvent.start))
            .diff(moment.utc(parseToMoment(secondEvent.start)))
        : moment
            .utc(parseToMoment(secondEvent.start))
            .diff(moment.utc(parseToMoment(firstEvent.start)));
    });

    return result;
  }

  async getDemandAndDemandComingSoonEvents(
    hubId: string = null,
    order: 'asc' | 'desc' = 'asc',
  ): Promise<IEvent[]> {
    try {
      let onDemandQuery: IQuery<IDocumentData> = this.firestore
        .collection(apiRoutes.events)
        .where('isDeleted', '==', false)
        .where('isOnDemandMode', '==', true)
        .where('isPublished', '==', true);

      if (hubId) {
        onDemandQuery = onDemandQuery.where('hubId', '==', hubId);
      }

      let onDemandComingSoonQuery: IQuery<IDocumentData> = this.firestore
        .collection(apiRoutes.events)
        .where('isDeleted', '==', false)
        .where('isOnDemandComingSoon', '==', true)
        .where('isPublished', '==', true);

      if (hubId) {
        onDemandComingSoonQuery = onDemandComingSoonQuery.where('hubId', '==', hubId);
      }

      const [demandEventsQuery, demandComingSoonEventsQuery] = await Promise.all([
        onDemandQuery.get(),
        onDemandComingSoonQuery.get(),
      ]);
      const events: IEvent[] = [];
      demandEventsQuery.forEach((event) => {
        events.push(event.data() as IEvent);
      });
      demandComingSoonEventsQuery.forEach((event) => {
        events.push(event.data() as IEvent);
      });

      const result: IEvent[] = events.sort((firstEvent: IEvent, secondEvent: IEvent) => {
        return order === 'desc'
          ? moment
              .utc(parseToMoment(firstEvent.start))
              .diff(moment.utc(parseToMoment(secondEvent.start)))
          : moment
              .utc(parseToMoment(secondEvent.start))
              .diff(moment.utc(parseToMoment(firstEvent.start)));
      });

      return result;
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  getAllPublishEvents(): Observable<IEvent[]> {
    const events: IEvent[] = [];
    const query: IQuery<IDocumentData> = this.firestore
      .collection(apiRoutes.events)
      .where('isPublished', '==', true);

    return from(query.get()).pipe(
      map((response) => {
        response.forEach((doc) => events.push(doc.data() as IEvent));

        return events;
      }),
    );
  }

  async getFeatured(hubId: string = null): Promise<IEvent[]> {
    let eventsQuery: IQuery<IDocumentData> = this.firestore
      .collection(apiRoutes.events)
      .orderBy('start')
      .orderBy('_title_')
      .orderBy('id')
      .where('featured', '==', true)
      .where('isPublished', '==', true);

    if (!hubId) {
      eventsQuery = eventsQuery.where('isPublished', '==', true);
    }

    if (hubId) {
      eventsQuery = eventsQuery.where('hubId', '==', hubId);
    }
    eventsQuery = eventsQuery.limit(5);
    const result: IEvent[] = (await eventsQuery.get()).docs.map((event) => event.data() as IEvent);

    return result;
  }

  checkEventByLinkExists(eventLink: string, eventId: string = null): Observable<boolean> {
    const events: IEvent[] = [];
    let eventsQuery: IQuery<IDocumentData> = this.firestore
      .collection(apiRoutes.events)
      .where('link', '==', eventLink);

    return from(eventsQuery.get()).pipe(
      map((response) => {
        response.forEach((doc) => {
          if (doc.data().id === eventId) {
            return;
          }
          events.push(doc.data() as IEvent);
        });

        return events.length > 0;
      }),
    );
  }

  async getBasedOnTags(
    hubId: string = null,
    entry: IEvent = null,
    pageSize: number = 12,
    order: 'asc' | 'desc' = 'asc',
  ): Promise<IEvent[]> {
    try {
      const events: IEvent[] = [];
      let tags: string[] = this.usersStore.user ? [...this.usersStore.user.tags] : [];
      let queryEventsByTags: IQuery<IDocumentData> | IQuerySnapshot<IDocumentData> = null;

      while (tags.length > 0) {
        const chunk: string[] = tags.splice(0, 10);
        queryEventsByTags = this.firestore
          .collection(apiRoutes.events)
          .where('isDeleted', '==', false)
          .where('isPublished', '==', true)
          .where('tags', 'array-contains-any', chunk)
          .orderBy('start', order)
          .orderBy('_title_', order)
          .orderBy('id');

        if (entry) {
          queryEventsByTags = queryEventsByTags.startAfter(entry.start, entry._title_, entry.id);
        }

        if (hubId) {
          queryEventsByTags = queryEventsByTags.where('hubId', '==', hubId);
        }

        queryEventsByTags = await queryEventsByTags.limit(pageSize).get();

        if (!queryEventsByTags.empty) {
          queryEventsByTags.forEach((doc) => {
            if (events.length < pageSize) {
              events.push(doc.data() as IEvent);
            }
          });
        }

        if (events.length === pageSize) {
          tags = [];
        }
      }

      return events;
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  async getBookmarked(
    hubId: string = null,
    entry: IEvent = null,
    userId: string,
    pageSize: number = 12,
    sort: 'asc' | 'desc' = 'asc',
  ): Promise<IEvent[]> {
    try {
      const events: IEvent[] = [];
      let eventsIds: string[] = [];
      const bookmarkedEventsQuery: IQuerySnapshot<IDocumentData> = await this.firestore
        .collection(apiRoutes.bookmarkedEvents(userId))
        .get();
      eventsIds.push(...bookmarkedEventsQuery.docs.map((doc) => doc.id));

      if (entry) {
        eventsIds = eventsIds.slice(eventsIds.indexOf(entry.id));
      }

      let eventsQuery: IQuery<IDocumentData> | IQuerySnapshot<IDocumentData> = null;
      while (eventsIds.length > 0) {
        const chunk: string[] = eventsIds.splice(0, 10);
        eventsQuery = this.firestore
          .collection(apiRoutes.events)
          .where('isDeleted', '==', false)
          .where('id', 'in', chunk)
          .where('isPublished', '==', true)
          .orderBy('start', sort)
          .orderBy('_title_', sort);

        if (entry) {
          eventsQuery = eventsQuery.startAfter(entry.start, entry._title_);
        }

        if (hubId) {
          eventsQuery = eventsQuery.where('hubId', '==', hubId);
        }

        eventsQuery = await eventsQuery.limit(pageSize).get();

        if (!eventsQuery.empty) {
          eventsQuery.forEach((doc) => {
            if (events.length < pageSize) {
              events.push(doc.data() as IEvent);
            }
          });
        }

        if (events.length === pageSize) {
          eventsIds = [];
        }
      }

      return events;
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  async getById(eventId: string): Promise<IEvent[]> {
    const event: IEvent[] = (
      await this.firestore.collection(apiRoutes.events).where('id', '==', eventId).get()
    ).docs.map((doc) => doc.data() as IEvent);

    return event;
  }

  async getByIds(
    eventIds: string[],
    entry?: IEvent,
    order: 'asc' | 'desc' = 'desc',
    pageSize: number = 12,
  ): Promise<IEvent[]> {
    try {
      let eventIdSearch: string[] = [];
      const events: IEvent[] = [];
      let query: IQuerySnapshot<IDocumentData>;
      const startFromIndex: number = entry ? eventIds.indexOf(entry.id) + 1 : 0;
      eventIdSearch = eventIds.splice(startFromIndex, pageSize);

      while (eventIdSearch?.length > 0) {
        const chunk: string[] = eventIdSearch.splice(0, 10);
        query = await this.firestore
          .collection(apiRoutes.events)
          .where('id', 'in', chunk)
          .orderBy('_title_', order)
          .get();

        if (!query.empty) {
          query.forEach((doc) => {
            const ev = doc.data() as IEvent;
            if (!eventIds.includes(ev.id)) {
              events.push(ev);
            }
          });
        }
      }

      return events;
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  async getCreatedByIds(
    eventIds: string[],
    userId: string,
    entry?: IEvent,
    order: 'asc' | 'desc' = 'desc',
    pageSize: number = 12,
  ): Promise<IEvent[]> {
    try {
      let eventIdSearch: string[] = [];
      const events: IEvent[] = [];
      let query: IQuerySnapshot<IDocumentData>;
      const startFromIndex: number = entry ? eventIds.indexOf(entry.id) + 1 : 0;
      eventIdSearch = eventIds.splice(startFromIndex, pageSize);

      while (eventIdSearch?.length > 0) {
        const chunk: string[] = eventIdSearch.splice(0, 10);
        query = await this.firestore
          .collection(apiRoutes.events)
          .where('id', 'in', chunk)
          .where('user', '==', userId)
          .orderBy('_title_', order)
          .get();

        if (!query.empty) {
          query.forEach((doc) => {
            const ev = doc.data() as IEvent;
            events.push(ev);
          });
        }
      }

      return events;
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  async getInvitedByIds(
    eventIds: string[],
    userId: string,
    entry?: IEvent,
    order: 'asc' | 'desc' = 'asc',
    pageSize: number = 12,
  ): Promise<IEvent[]> {
    try {
      let eventIdSearch: string[] = [];
      const events: IEvent[] = [];
      let query: IQuerySnapshot<IDocumentData>;
      const startFromIndex: number = entry ? eventIds.indexOf(entry.id) + 1 : 0;
      eventIdSearch = eventIds.splice(startFromIndex, pageSize);

      while (eventIdSearch?.length > 0) {
        const chunk: string[] = eventIdSearch.splice(0, 10);
        query = await this.firestore
          .collection(apiRoutes.events)
          .where('id', 'in', chunk)
          .where('user', '!=', userId)
          .orderBy('user')
          .orderBy('_title_', order)
          .get();

        if (!query.empty) {
          query.forEach((doc) => {
            const ev = doc.data() as IEvent;
            events.push(ev);
          });
        }
      }

      return events;
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  async getOne(
    id: string,
    forceUpdate: boolean = false,
    setInStore: boolean = true,
  ): Promise<IEvent> {
    if (!forceUpdate && this.eventsStore.event) {
      return this.eventsStore.event;
    }

    try {
      const eventQuery: IDocumentSnapshot<IDocumentData> = await this.firestore
        .collection(apiRoutes.events)
        .doc(id)
        .get();
      if (eventQuery.exists) {
        if (setInStore) {
          this.eventsStore.setEvent(eventQuery.data() as IEvent);
        }

        return eventQuery.data() as IEvent;
      } else {
        return null;
      }
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  async getByUrl(link: string): Promise<IEvent> {
    try {
      const eventQuery: IQuerySnapshot<IDocumentData> = await this.firestore
        .collection(apiRoutes.events)
        .where('link', '==', link)
        .get();

      if (!eventQuery.empty) {
        return eventQuery.docs[0].data() as IEvent;
      }

      return null;
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  async getAll(hubId: string): Promise<IEvent[]> {
    let eventsQuery: IQuery<IDocumentData> = this.firestore
      .collection(apiRoutes.events)
      .where('isPublished', '==', true);

    if (hubId) {
      eventsQuery = eventsQuery.where('hubId', '==', hubId);
    }
    const events: IQuerySnapshot<IDocumentData> = await eventsQuery.get();

    return events.docs.map((d) => d.data() as IEvent);
  }

  async publishEvent(eventId: string): Promise<void> {
    await this.firestore.collection(apiRoutes.events).doc(eventId).update({
      isPublished: true,
      publicationDate: Timestamp.now(),
      updatedAt: Timestamp.now(),
      updatedBy: this.usersStore.userId,
    });

    const eventQuery: IDocumentSnapshot<IDocumentData> = await this.firestore
      .collection(apiRoutes.events)
      .doc(eventId)
      .get();
    const eventDocument = eventQuery.data();
    await this.algoliaService.updateEvent(eventDocument);
  }

  async unpublishEvent(eventId: string): Promise<void> {
    await this.firestore.collection(apiRoutes.events).doc(eventId).update({
      publicationDate: null,
      isPublished: false,
      updatedAt: Timestamp.now(),
      updatedBy: this.usersStore.userId,
    });

    const eventQuery: IDocumentSnapshot<IDocumentData> = await this.firestore
      .collection(apiRoutes.events)
      .doc(eventId)
      .get();
    const eventDocument = eventQuery.data();
    await this.algoliaService.updateEvent(eventDocument);
  }

  async getByUser(
    userId: string,
    hubId: string = null,
    entry: IEvent = null,
    pageSize: number = 20,
    order: 'asc' | 'desc' = 'asc',
  ): Promise<IEvent[]> {
    const userEvents: { [key: string]: IUserEvent } = {};
    (
      await this.firestore
        .collection(apiRoutes.userEvents)
        .where('userId', '==', userId)
        .where('role', 'in', ['speaker', 'attendee'])
        .orderBy('_event_title_', order)
        .get()
    ).forEach((doc) => {
      let userEvent = doc.data() as IUserEvent;

      if (this.appStore.generalSystemSettings.enableEncryption) {
        userEvent = this.usersService.decryptUserData(userEvent) as IUserEvent;
      }

      userEvents[userEvent.eventId] = userEvent;
    });

    const eventsIds: string[] = Object.keys(userEvents);
    const startFromIndex: number = entry ? eventsIds.indexOf(entry.id) + 1 : 0;
    const eventIdsToQuery: string[] = eventsIds.splice(startFromIndex, pageSize);
    const events: IEvent[] = [];

    while (eventIdsToQuery.length) {
      const chunk: string[] = eventIdsToQuery.splice(0, 10);
      let query: IQuery<IDocumentData> = this.firestore
        .collection(apiRoutes.events)
        .where('isDeleted', '==', false)
        .where('id', 'in', chunk)
        .orderBy('_title_', order);

      if (hubId) {
        query = query.where('hubId', '==', hubId);
      }

      const queryEvents: IQuerySnapshot<IDocumentData> = await query.get();
      events.push(
        ...queryEvents.docs.map((doc) =>
          Object.assign(doc.data() as IEvent, { type: userEvents[doc.id] }),
        ),
      );
    }

    return events;
  }

  async getAllEventProducts(brandId: string, eventId: string): Promise<IEventProduct[]> {
    return (
      await this.firestore
        .collection(apiRoutes.eventProducts)
        .where('brandId', '==', brandId)
        .where('eventId', '==', eventId)
        .orderBy('productTitle')
        .get()
    ).docs.map((doc) => doc.data() as IEventProduct);
  }

  async createEventProduct(event: IEvent, product: IBrandProduct): Promise<IEventProduct> {
    try {
      const preEventReqDoc: IDocumentReference<IDocumentData> = this.firestore
        .collection(apiRoutes.eventProducts)
        .doc();
      const eventProduct: IEventProduct = {
        id: preEventReqDoc.id,
        eventId: event.id,
        productId: product.id,
        brandId: product.brandId,
        eventTitle: event.title,
        productTitle: product.title,
        brandName: product._brandName_,
        createdAt: ServerTimestamp(),
        createdBy: this.usersStore.userId,
        updatedAt: null,
        updatedBy: null,
      } as IEventProduct;
      const prodDocument: IDocumentReference<IDocumentData> = this.firestore
        .collection(apiRoutes.eventProducts)
        .doc(eventProduct.id);
      await prodDocument.set(eventProduct);

      return eventProduct;
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  async deleteEventProductByProductId(productId: string): Promise<boolean> {
    try {
      await this.firestore.collection(apiRoutes.eventProducts).doc(productId).delete();

      return true;
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  async deleteAllUserEvents(eventId: string): Promise<void> {
    const eventQuery: IQuerySnapshot<IDocumentData> = await this.firestore
      .collection(apiRoutes.userEvents)
      .where('eventId', '==', eventId)
      .where('isBrandPerson', '==', true)
      .get();

    if (!eventQuery.empty) {
      eventQuery.forEach(async (doc) => {
        await this.firestore.collection(apiRoutes.userEvents).doc(doc.id).delete();
      });
    }
  }

  async saveUserEvents(eventId: string, userEvents: IUserEvent[]): Promise<void[]> {
    try {
      const eventIds: string[] = userEvents.map((x) => x.id);
      const userIds: string[] = userEvents.map((x) => x.userId);

      while (userIds.length > 0) {
        const eventQuery: IQuerySnapshot<IDocumentData> = await this.firestore
          .collection(apiRoutes.userEvents)
          .where('eventId', '==', eventId)
          .where('isBrandPerson', '==', true)
          .get();

        if (!eventQuery.empty) {
          eventQuery.forEach(async (doc) => {
            const entryData = doc.data();

            if (!eventIds.includes(entryData.id)) {
              await this.firestore.collection(apiRoutes.userEvents).doc(doc.id).delete();
            }
          });
        }
      }

      const promises: Array<Promise<void>> = userEvents.map(async (user: IUserEvent) => {
        if (this.appStore.generalSystemSettings.enableEncryption) {
          user = this.usersService.encryptUserData(user) as unknown as IUserEvent;
        }

        if (user.id) {
          return this.firestore
            .collection(apiRoutes.userEvents)
            .doc(user.id)
            .update(Object.assign({}, user));
        } else {
          const preHubTagReq: IDocumentReference<IDocumentData> = await this.firestore
            .collection(apiRoutes.userEvents)
            .doc();
          user.id = preHubTagReq.id;

          return preHubTagReq.set(Object.assign({}, user));
        }
      });

      return Promise.all(promises);
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  async saveEventProducts(
    eventId: string,
    brandId: string,
    eventProducts: IEventProduct[],
  ): Promise<void[]> {
    try {
      const productsToSave: string[] = eventProducts.map((p) => p.productId);
      const productsQuery: IQuerySnapshot<IDocumentData> = await this.firestore
        .collection(apiRoutes.eventProducts)
        .where('brandId', '==', brandId)
        .where('eventId', '==', eventId)
        .get();

      if (!productsQuery.empty) {
        productsQuery.forEach(async (entry) => {
          const entryData = entry.data();

          if (!productsToSave.includes(entryData.productId)) {
            await this.firestore.collection(apiRoutes.eventProducts).doc(entry.id).delete();
          }
        });
      }

      const promises: Array<Promise<void>> = eventProducts.map(async (product: IEventProduct) => {
        if (product.id) {
          return this.firestore
            .collection(apiRoutes.eventProducts)
            .doc(product.id)
            .update(Object.assign({}, product));
        } else {
          const preHubTagReq = await this.firestore.collection(apiRoutes.eventProducts).doc();
          product.id = preHubTagReq.id;
          return preHubTagReq.set(Object.assign({}, product));
        }
      });

      return Promise.all(promises);
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  async create(event: IEvent): Promise<IEvent> {
    try {
      const preEventReqDoc: IDocumentReference<IDocumentData> = this.firestore
        .collection(apiRoutes.events)
        .doc();
      event.id = preEventReqDoc.id;
      await preEventReqDoc.set(event);
      await this.createEventRegisterSettings(event.id);
      await this.algoliaService.createEvent(event);

      return event;
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  async updateEventBrandTags(eventBrandId: string, tags: string[]): Promise<void> {
    const eventDocument = this.firestore.collection(apiRoutes.eventBrands).doc(eventBrandId);

    await eventDocument.update({ tags });
  }

  async updateEventBrandLevel(eventBrandId: string, level: string): Promise<void> {
    const eventDocument = this.firestore.collection(apiRoutes.eventBrands).doc(eventBrandId);

    await eventDocument.update({ level });
  }

  async update(eventId: string, event: IEvent): Promise<IEvent> {
    try {
      const eventDocument: IDocumentReference<IDocumentData> = this.firestore
        .collection(apiRoutes.events)
        .doc(eventId);
      const condition: boolean =
        event.logoDark instanceof File ||
        event.logoLight instanceof File ||
        event.banner instanceof File ||
        event.icon instanceof File ||
        event.featuredImage instanceof File;

      if (condition) {
        const eventDocData: IDocumentSnapshot<IDocumentData> = await eventDocument.get();

        if (event.logoDark instanceof File) {
          const currentLogoImage = eventDocData.get('logoDark');

          if (currentLogoImage) {
            await this.storageService.delete(currentLogoImage);
          }

          if (event.logoDark !== null) {
            const fileName = `file_${new Date().getTime()}`;
            const newLogoImage = await this.storageService.upload(
              event.logoDark,
              'events',
              fileName,
            );
            event.logoDark = newLogoImage;
          } else {
            event.logoDark = null;
          }
        }

        if (event.logoLight instanceof File) {
          const currentLogoImage = eventDocData.get('logoLight');

          if (currentLogoImage) {
            await this.storageService.delete(currentLogoImage);
          }

          if (event.logoLight !== null) {
            const fileName = `file_${new Date().getTime()}`;
            const newLogoImage = await this.storageService.upload(
              event.logoLight,
              'events',
              fileName,
            );
            event.logoLight = newLogoImage;
          } else {
            event.logoLight = null;
          }
        }

        if (event.banner instanceof File) {
          const currentBannerImage = eventDocData.get('banner');

          if (currentBannerImage) {
            await this.storageService.delete(currentBannerImage);
          }

          if (event.banner !== null) {
            const fileName = `file_${new Date().getTime()}`;
            const newCoverImage = await this.storageService.upload(
              event.banner,
              'events',
              fileName,
            );
            event.banner = newCoverImage;
          } else {
            event.banner = null;
          }
        }

        if (event.icon instanceof File) {
          const currentIcon = eventDocData.get('icon');

          if (currentIcon) {
            await this.storageService.delete(currentIcon);
          }

          if (event.icon !== null) {
            const fileName = `file_${new Date().getTime()}`;
            const newIcon = await this.storageService.upload(event.icon, 'events', fileName);
            event.icon = newIcon;
          } else {
            event.icon = null;
          }
        }

        if (event.featuredImage instanceof File) {
          const currentFeaturedImage = eventDocData.get('featuredImage');

          if (currentFeaturedImage) {
            await this.storageService.delete(currentFeaturedImage);
          }

          if (event.featuredImage !== null) {
            const fileName = `file_${new Date().getTime()}`;
            const newFeaturedImage = await this.storageService.upload(
              event.featuredImage,
              'events',
              fileName,
            );
            event.featuredImage = newFeaturedImage;
          } else {
            event.featuredImage = null;
          }
        }
      }
      const eventForUpdate: IEvent = {
        ...event,
        updatedAt: Timestamp.now(),
        updatedBy: this.usersStore.userId,
      };
      await eventDocument.update({ ...eventForUpdate });
      await this.algoliaService.updateEvent(eventForUpdate);
      return event;
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  async delete(eventDocId: string): Promise<boolean> {
    try {
      await this.firestore.collection(apiRoutes.events).doc(eventDocId).delete();
      await this.algoliaService.removeEvent(eventDocId);
      return true;
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  async search(eventId: string, query: string): Promise<SearchResultGroup[]> {
    const res = await this.searchClient.search([
      {
        query: `${eventId} ${query}`,
        indexName: `${this.appStore.environment.algolia.indexPrefix}eventBrands`,
      },
      {
        query: `${eventId} ${query}`,
        indexName: `${this.appStore.environment.algolia.indexPrefix}userEvents`,
      },
      {
        query: `${eventId} ${query}`,
        indexName: `${this.appStore.environment.algolia.indexPrefix}eventSessions`,
      },
    ]);
    const [eventBrands, userEvents, eventSessions] = res.results;
    const getFullName = (data: any) => {
      let firstName: string = data.firstName;
      let lastName: string = data.lastName;

      if (!firstName && data._firstName_) {
        firstName = `${data._firstName_[0].toUpperCase()}${data._firstName_.substring(1, data._firstName_.length)}`;
      }

      if (!lastName && data._lastName_) {
        lastName = `${data._lastName_[0].toUpperCase()}${data._lastName_.substring(1, data._lastName_.length)}`;
      }

      return `${firstName} ${lastName}`;
    };
    const communityList = userEvents.hits.filter((u) => u.role === 'attendee');
    const community = communityList.map((u) => ({ key: u.userId, description: getFullName(u) }));
    const speakersList = userEvents.hits.filter((u) => u.role === 'speaker');
    const speakers = speakersList.map((u) => ({ key: u.userId, description: getFullName(u) }));
    const brands = eventBrands.hits.map((u) => ({
      key: u.brandId,
      description: `${u.brandName ?? u._brand_name_}`,
    }));
    const sessions = eventSessions.hits.map((u) => ({
      key: u.id,
      description: u.title,
      metadata: { stageId: u.stageId },
    }));
    const result = [];

    if (community.length > 0) {
      result.push({
        description: this.translateService.instant('search.eventCommunityLabel'),
        items: community,
        key: 'EVENT_ATTENDEE',
      });
    }

    if (speakers.length > 0) {
      result.push({
        description: this.translateService.instant('search.eventSpeakersLabel'),
        items: speakers,
        key: 'EVENT_SPEAKER',
      });
    }

    if (brands.length > 0) {
      result.push({
        description: this.translateService.instant('search.eventBrandsLabel'),
        items: brands,
        key: 'EVENT_BRAND',
      });
    }

    if (sessions.length > 0) {
      result.push({
        description: this.translateService.instant('search.eventSessionsLabel'),
        items: sessions,
        key: 'EVENT_SESSION',
      });
    }

    return result;
  }

  async createEventRegisterSettings(eventId: string): Promise<IEventSettings> {
    try {
      const settings: IEventSettings = {
        eventRegistrationForm: {
          ...defaultEventRegistrationForm,
          enableGroupCheckboxes: false,
          allowSkipRegistration: false,
          isGlobalSignup: true,
          isGlobalTags: true,
          isShowProfileAndTags: true,
          isShowEventTags: true,
          isShowProfileAppearance: true,
          isShowConsents: false,
          isShowAccommodationTransfer: false,
          isEventCode: false,
          eventCode: null,
          isRsvpRegistration: false,
          isEventTagsMandatory: false,
        },
      };
      await this.firestore
        .collection(apiRoutes.eventSettings(eventId))
        .doc(apiRoutes.eventRegistrationForm)
        .set({ ...settings.eventRegistrationForm });

      return settings;
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  async updateEventRegisterSettings(
    eventId: string,
    eventRegistrationForm: IEventRegistrationForm,
  ): Promise<IEventRegistrationForm> {
    try {
      const isSettingExist: IDocumentSnapshot<IDocumentData> = await this.firestore
        .collection(apiRoutes.eventSettings(eventId))
        .doc(apiRoutes.eventRegistrationForm)
        .get();

      if (isSettingExist.exists) {
        await this.firestore
          .collection(apiRoutes.eventSettings(eventId))
          .doc(apiRoutes.eventRegistrationForm)
          .update(eventRegistrationForm);
      } else {
        await this.firestore
          .collection(apiRoutes.eventSettings(eventId))
          .doc(apiRoutes.eventRegistrationForm)
          .set(eventRegistrationForm);
      }

      return eventRegistrationForm;
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  async makeEventRegisterTagsNotMandatory(eventId: string): Promise<void> {
    try {
      const isSettingExist: IDocumentSnapshot<IDocumentData> = await this.firestore
        .collection(apiRoutes.eventSettings(eventId))
        .doc(apiRoutes.eventRegistrationForm)
        .get();

      if (isSettingExist.exists) {
        await this.firestore
          .collection(apiRoutes.eventSettings(eventId))
          .doc(apiRoutes.eventRegistrationForm)
          .update({ isEventTagsMandatory: false });
      }
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  async getEventRegisterSettings(eventId: string): Promise<IEventRegistrationForm> {
    try {
      const settingsQuery: IDocumentSnapshot<IDocumentData> = await this.firestore
        .collection(apiRoutes.eventSettings(eventId))
        .doc(apiRoutes.eventRegistrationForm)
        .get();
      const eventRegistrationForm = settingsQuery.data() as IEventRegistrationForm;
      const defaultEventRegForm: IEventRegistrationForm = {
        ...defaultEventRegistrationForm,
        enableGroupCheckboxes: false,
        allowSkipRegistration: false,
        isGlobalSignup: true,
        isGlobalTags: true,
        isShowProfileAndTags: true,
        isShowEventTags: true,
        isShowProfileAppearance: true,
        isShowConsents: false,
        isShowAccommodationTransfer: false,
        isEventCode: false,
        eventCode: null,
        isRsvpRegistration: false,
        isEventTagsMandatory: false,
      };

      return eventRegistrationForm ? eventRegistrationForm : defaultEventRegForm;
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  async reorderEventConsents(eventId: string, orderedIds: string[]): Promise<void> {
    let order = 1;
    await Promise.all(
      orderedIds.map((orderedId: string) =>
        this.firestore
          .collection(apiRoutes.eventConsents(eventId))
          .doc(orderedId)
          .update({ order: order++ }),
      ),
    );
  }

  async addNewConsentToEvent(eventId: string, eventConsent: IEventConsent): Promise<IEventConsent> {
    try {
      const preConsentReqDoc: IDocumentReference<IDocumentData> = this.firestore
        .collection(apiRoutes.eventConsents(eventId))
        .doc();
      eventConsent.id = preConsentReqDoc.id;
      await preConsentReqDoc.set(eventConsent);

      return eventConsent;
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  async updateEventConsent(eventId: string, eventConsent: IEventConsent): Promise<IEventConsent> {
    try {
      const preConsentReqDoc: IDocumentReference<IDocumentData> = this.firestore
        .collection(apiRoutes.eventConsents(eventId))
        .doc(eventConsent.id);
      await preConsentReqDoc.update(eventConsent);

      return eventConsent;
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  async downloadConsentReport(eventId, consent: IEventConsent): Promise<void> {
    try {
      const headers: HttpHeaders = await this.authorizationService.buildHeaders();
      const responseType = 'blob';

      return await this.http
        .post(
          `${environment.apiUrl}events/${eventId}/consent/${consent.id}/report`,
          { consent },
          { headers, responseType },
        )
        .toPromise()
        .then((blob) => {
          const date = new Date();
          const reportName =
            'report-' +
            date.getUTCDate().toString() +
            '_' +
            (date.getMonth() + 1) +
            '_' +
            date.getFullYear() +
            '.xlsx';
          saveAs(blob, reportName);
        });
    } catch (error) {
      console.error('Download error: ', error);
      throw new Error(error);
    }
  }

  async getAllConsentsFromEvent(eventId: string): Promise<IEventConsent[]> {
    try {
      let consents: IEventConsent[] = [];
      const consentsQuery: IQuerySnapshot<IDocumentData> = await this.firestore
        .collection(apiRoutes.eventConsents(eventId))
        .where('isDeleted', '==', false)
        .orderBy('order')
        .get();

      if (!consentsQuery.empty) {
        consentsQuery.docs.forEach((consentQuery) => {
          consents.push(consentQuery.data() as IEventConsent);
        });

        return consents;
      }

      return [];
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  async getLatestConsentByEventId(eventId): Promise<IEventConsent> {
    try {
      const consentQuery: IQuerySnapshot<IDocumentData> = await this.firestore
        .collection(apiRoutes.eventConsents(eventId))
        .orderBy('order')
        .limitToLast(1)
        .get();

      if (consentQuery.empty) {
        return null;
      }

      return consentQuery.docs[0].data() as IEventConsent;
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  async getEventSubscriptions(eventId: string = null): Promise<IEventSubscription[]> {
    try {
      const eventSubscriptions: IEventSubscription[] = (
        await this.firestore
          .collection(apiRoutes.eventSubscriptions)
          .where('eventId', '==', eventId)
          .orderBy('orderIndex')
          .get()
      ).docs.map((doc) => doc.data() as IEventSubscription);

      return eventSubscriptions;
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  async addSubscriptionToEvent(eventId: string, subscription: ISubscription): Promise<void> {
    const exists: IEventSubscription = (
      await this.firestore
        .collection(apiRoutes.eventSubscriptions)
        .where('eventId', '==', eventId)
        .where('subscriptionId', '==', subscription.id)
        .get()
    ).docs.map((doc) => doc.data() as IEventSubscription)[0];

    if (exists) {
      return;
    }

    try {
      const eventSubscriptionDoc: IDocumentReference<IDocumentData> = this.firestore
        .collection(apiRoutes.eventSubscriptions)
        .doc();
      const newEventSubscription: IEventSubscription = {
        id: eventSubscriptionDoc.id,
        eventId: eventId,
        subscriptionId: subscription.id,
        createdAt: ServerTimestamp(),
        createdBy: this.usersStore.user.id,
        subscriptionName: subscription.name,
        updatedAt: null,
        updatedBy: null,
        platform: subscription.platform,
        price: Number(subscription.price),
        currency: subscription.currency ?? null,
        stripePriceId: subscription.stripePriceId ?? null,
        orderIndex: subscription.orderIndex,
        isDefault: subscription.isDefault,
      };

      await eventSubscriptionDoc.set({ ...newEventSubscription });
    } catch (error) {
      console.log(error);
      throw new Error(error);
    }
  }

  async removeSubscriptionToEvent(eventId: string, subscriptionId: string): Promise<void> {
    try {
      const eventSubscription = (
        await this.firestore
          .collection(apiRoutes.eventSubscriptions)
          .where('eventId', '==', eventId)
          .where('subscriptionId', '==', subscriptionId)
          .get()
      ).docs[0];

      if (eventSubscription) {
        await this.firestore
          .collection(apiRoutes.eventSubscriptions)
          .doc(eventSubscription.id)
          .delete();
      }
    } catch (error) {
      console.log(error);
      throw new Error(error);
    }
  }

  async increaseSoldAmount(eventId: string, ticketId: string): Promise<void> {
    try {
      const ticket: IDocumentReference<IDocumentData> = await this.firestore
        .collection(apiRoutes.eventTickets(eventId))
        .doc(ticketId);
      ticket.update({ quantitySold: firebase.firestore.FieldValue.increment(1) });
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  async getDiscoveryEventsReport(userId: string): Promise<any> {
    try {
      const headers: HttpHeaders = await this.authorizationService.buildHeaders();

      return this.http
        .post<any>(
          `${environment.apiUrl}mixpanel/getDiscoveryEventsReport`,
          { userId: userId },
          { headers },
        )
        .toPromise();
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  async isEventFree(eventId: string): Promise<boolean> {
    try {
      const tickets = (
        await this.firestore
          .collection(apiRoutes.eventTickets(eventId))
          .where('isPaid', '==', true)
          .where('isPublished', '==', true)
          .get()
      ).docs;

      const subscriptions = (
        await this.firestore
          .collection(apiRoutes.eventSubscriptions)
          .where('eventId', '==', eventId)
          .get()
      ).docs;

      return tickets.length == 0 && subscriptions.length == 0;
    } catch (error) {
      console.warn(error);
      throw error;
    }
  }

  async getAllWithoutAnyRestrictions(): Promise<IEvent[]> {
    let events: IQuerySnapshot<IDocumentData> = await this.firestore
      .collection(apiRoutes.events)
      .get();

    return events.docs.map((d) => d.data() as IEvent);
  }
}
