import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';

import { UserHubsService } from 'src/app/core/services';
import { HubsStore } from 'src/app/core/stores';

export const currentMonthUserRegistrationsResolver: ResolveFn<
  number
> = async (): Promise<number> => {
  const hubsStore = inject(HubsStore);
  const userHubsService = inject(UserHubsService);

  const hubId: string = hubsStore.hubId;
  const [currentMonthUserRegistrations] = await userHubsService.getUserRegistrationsOverPeriods(
    hubId,
    'month',
    1,
    new Date(),
  );

  return currentMonthUserRegistrations;
};
