<form
  class="c-pl-8"
  [formGroup]="form">
  <!-- Ticket block -->
  <div class="form-block c-mt-8 c-mb-9">
    <div class="left-side">
      <h2 class="title">{{ 'adminUserCourseDetails.ticketTitle' | translate }}</h2>
      <p class="description">{{ 'adminUserCourseDetails.ticketDescription' | translate }}</p>
    </div>
    <div class="right-side">
      <!-- Ticket input -->
      <div class="form-field-block c-mb-4">
        <app-input-label
          for="ticket-input"
          text="adminUserCourseDetails.ticketTitle">
        </app-input-label>
        <p-dropdown
          class="custom-dropdown"
          panelStyleClass="custom-dropdown-panel"
          [options]="courseTickets()"
          formControlName="ticket"
          optionLabel="name"
          inputId="ticket-input"
          emptyMessage="{{ 'adminUserCourseDetails.emptyDefaultMessage' | translate }}"
          placeholder="{{ 'adminUserCourseDetails.ticketTitle' | translate }}">
        </p-dropdown>
      </div>
    </div>
  </div>

  <!-- Tags block -->
  <div class="form-block c-mt-9 c-mb-4">
    <div class="left-side">
      <h2 class="title">{{ 'adminUserCourseDetails.tagsTitle' | translate }}</h2>
      <p class="description">
        {{ 'adminUserCourseDetails.tagsDescription' | translate }}
      </p>
    </div>
    <div class="right-side">
      <!-- Tags multi select -->
      <div class="form-field-block">
        <app-tags-multi-list
          [possibleTags]="courseTags()"
          [selectedTags]="form.controls.tags.value"
          [adminTheme]="true"
          (onSelected)="selectTags($event)">
        </app-tags-multi-list>
        @if (!courseTags()?.length) {
          <h3>{{ 'adminUserCourseDetails.noTagsAvailable' | translate }}</h3>
        }
      </div>
    </div>
  </div>

  <div class="flex justify-content-end c-mt-4 c-mr-8 c-mb-8">
    <app-button
      buttonText="{{ 'shared.update' | translate }}"
      [buttonSize]="buttonSize().MEDIUM"
      [loading]="isUpdating()"
      [disabled]="!canUpdate"
      (click)="update()" />
  </div>
</form>

<p-toast>
  <ng-template
    let-message
    pTemplate="message">
    <app-toast
      [severity]="message.severity"
      [summary]="message.summary"
      [detail]="message.detail">
    </app-toast>
  </ng-template>
</p-toast>
