<ng-container *ngIf="loading">
  <app-loader></app-loader>
</ng-container>

<p-table
  *ngIf="!loading"
  #adminTable
  styleClass="admin-table-container"
  [paginatorDropdownAppendTo]="'body'"
  [class]="scssClass"
  [rowTrackBy]="trackByFn"
  [responsive]="true"
  [autoLayout]="true"
  [loading]="lazyLoading"
  [columns]="columns"
  [value]="entries"
  [lazy]="lazy"
  [lazyLoadOnInit]="lazyLoadOnInit"
  (onLazyLoad)="lazyLoad.next($event)"
  [paginator]="paginator"
  [rows]="pageSize"
  [rowsPerPageOptions]="pageSizeOptions"
  [globalFilterFields]="searchFilterFields"
  [totalRecords]="totalRecords ? totalRecords : entries.length"
  [showCurrentPageReport]="showCurrentPageReport"
  [currentPageReportTemplate]="pageReportTemplate | translate"
  [(selection)]="selectedEntries"
  (selectionChange)="selectedEntriesChange.emit($event)"
  [dataKey]="dataKey"
  [reorderableColumns]="enableReorder"
  (onRowReorder)="rowReorder.emit(entries)"
  (sortFunction)="customSort($event)"
  [customSort]="true"
  [sortField]="sortField"
  [filterDelay]="0">
  <ng-template
    pTemplate="header"
    let-columns>
    <tr>
      <th *ngIf="checkBoxSelection">
        <p-tableHeaderCheckbox class="custom-input-checkbox-sm"></p-tableHeaderCheckbox>
      </th>
      <th
        *ngIf="enableReorder"
        style="width: 3rem"></th>
      <ng-container *ngFor="let col of columns">
        <th *ngIf="!col.sortable && !col.filterable">{{ col.titleTranslationKey | translate }}</th>
        <th
          *ngIf="col.sortable"
          [pSortableColumn]="col.field">
          {{ col.titleTranslationKey | translate }}
          <p-sortIcon [field]="col.field"></p-sortIcon>
        </th>
        <th *ngIf="col.filterable">
          {{ col.titleTranslationKey | translate }}

          <ng-container *ngIf="col.filterType === 'date'">
            <p-columnFilter
              class="custom-filter"
              display="menu"
              [type]="col.filterType"
              [field]="col.field"
              [showMatchModes]="false"
              [showOperator]="false"
              [showAddButton]="false"
              [placeholder]="col.titleTranslationKey | translate">
              <ng-template
                pTemplate="filter"
                let-value
                let-filter="filterCallback">
                <p-calendar
                  #calendar
                  [ngModel]="value"
                  (onSelect)="filter(calendar.value)"
                  (onInput)="setup(calendar.value, col.field)"
                  dateFormat="dd.mm.yy"
                  [minDate]="col.validatorMinDate"
                  [maxDate]="col.validatorMaxDate">
                </p-calendar>
              </ng-template>
            </p-columnFilter>
          </ng-container>

          <ng-container *ngIf="col.filterType !== 'date'">
            <p-columnFilter
              class="custom-filter"
              [field]="col.field"
              display="menu"
              [matchMode]="col.filterMatchMode"
              [showMatchModes]="false"
              [showOperator]="false"
              [showAddButton]="false"
              [type]="col.filterType">
              <ng-template
                pTemplate="filter"
                let-value
                let-filter="filterCallback">
                <p-multiSelect
                  [ngModel]="value"
                  [options]="col.filterOptions"
                  [placeholder]="col.titleTranslationKey | translate"
                  optionLabel="name"
                  optionValue="value"
                  (onChange)="filter($event.value)">
                  <ng-template
                    let-option
                    pTemplate="item">
                    {{ option.name }}
                  </ng-template>
                </p-multiSelect>
              </ng-template>
            </p-columnFilter>
          </ng-container>
        </th>
      </ng-container>
    </tr>
  </ng-template>
  <ng-template
    pTemplate="body"
    let-rowData
    let-columns="columns"
    let-rowIndex="rowIndex">
    <tr
      *ngIf="enableReorder"
      [pReorderableRow]="rowIndex">
      <td>
        <span
          class="far fa-bars"
          pReorderableRowHandle></span>
      </td>
      <td *ngIf="checkBoxSelection">
        <p-tableCheckbox
          class="custom-input-checkbox-sm"
          [value]="rowData"></p-tableCheckbox>
      </td>
      <td
        *ngFor="let col of columns"
        (click)="onRowClick(rowData.id)">
        <ng-template
          *ngIf="templateForKey(col.template)"
          [ngTemplateOutlet]="templateForKey(col.template)"
          [ngTemplateOutletContext]="{ $implicit: rowData }">
        </ng-template>
        <ng-container *ngIf="!templateForKey(col.template)">{{ rowData[col.field] }}</ng-container>
      </td>
    </tr>
    <tr *ngIf="!enableReorder">
      <td *ngIf="checkBoxSelection">
        <p-tableCheckbox
          class="custom-input-checkbox-sm"
          [value]="rowData"></p-tableCheckbox>
      </td>
      <td
        *ngFor="let col of columns"
        (click)="onRowClick(rowData.id)">
        <ng-template
          *ngIf="templateForKey(col.template)"
          [ngTemplateOutlet]="templateForKey(col.template)"
          [ngTemplateOutletContext]="{ $implicit: rowData }">
        </ng-template>
        <ng-container *ngIf="!templateForKey(col.template)">{{ rowData[col.field] }}</ng-container>
      </td>
    </tr>
  </ng-template>
  <ng-template
    *ngIf="!paginator"
    pTemplate="summary">
    <div
      *ngIf="isShowTotalEntries"
      class="flex align-items-center justify-content-center">
      {{ 'adminTable.footerReportTemplate' | translate: footerReportTemplateParameters }}
    </div>
  </ng-template>
  <ng-template
    pTemplate="emptymessage"
    let-columns>
    <tr>
      <td
        class="empty-row-container"
        [attr.colspan]="columns.length + 1">
        <app-empty-state
          *ngIf="!emptyStateIcon"
          [icon]="'far fa-rectangle-list'"
          text="{{ emptyStateKey | translate }}"
          title="{{ emptyStateTitleKey | translate }}">
        </app-empty-state>
        <app-empty-state
          *ngIf="emptyStateIcon"
          [icon]="emptyStateIcon"
          text="{{ emptyStateKey | translate }}"
          title="{{ emptyStateTitleKey | translate }}">
        </app-empty-state>
      </td>
    </tr>
  </ng-template>
</p-table>
