<ng-container *ngIf="loading">
  <app-loader></app-loader>
</ng-container>

<div
  *ngIf="!loading"
  class="system-home-container">
  <div class="admin-system-home-table-container">
    <div class="c-mb-4">
      <admin-table-header-navbar
        [title]="'adminSystemHomePages.title' | translate"></admin-table-header-navbar>
    </div>

    <admin-table-filter-navbar
      (onSearchTermChange)="searchValue = $event"></admin-table-filter-navbar>

    <admin-table
      [loading]="loadingEntries"
      [columns]="columns"
      [entries]="entries"
      [paginator]="true"
      [lazy]="false"
      [emptyStateKey]="'adminSystemHomePages.emptyState'"
      [emptyStateIcon]="'far fa-tag'"
      [searchValue]="searchValue"
      [searchFilterFields]="searchFilterFields">
      <ng-template
        let-entry
        epTemplateKey="actionsCell">
        <div class="table-btn-actions-container">
          <app-button
            [buttonType]="buttonType.ROUND"
            [buttonSize]="buttonSize.MEDIUM"
            [buttonStyle]="buttonStyle.FILLED_SECONDARY"
            buttonRoundIcon="far fa-pen-to-square"
            (click)="edit($event, entry)" />
        </div>
      </ng-template>
    </admin-table>
  </div>
</div>
