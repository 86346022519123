<ng-container *ngIf="loading">
  <app-loader></app-loader>
</ng-container>

<div
  *ngIf="!loading"
  class="admin-hub-team-table-container">
  <div class="c-mb-4">
    <admin-table-header-navbar [title]="'adminHubTeam.title' | translate">
      <div class="btn-actions-container">
        <app-button
          buttonText="{{ 'adminHubTeam.sendInvite' | translate }}"
          buttonIconLeft="far fa-envelope"
          [buttonStyle]="buttonStyle.FILLED_SECONDARY"
          [buttonSize]="buttonSize.MEDIUM"
          [disabled]="!selectedEntries.length"
          (click)="sendInvites()" />
        <app-button
          buttonText="{{ 'adminHubTeam.attachUser' | translate }}"
          buttonIconLeft="far fa-user-plus"
          [buttonStyle]="buttonStyle.FILLED_SECONDARY"
          [buttonSize]="buttonSize.MEDIUM"
          [disabled]="loadingEntries"
          (click)="openAttachUserDialog()" />
        <app-button
          buttonText="{{ 'adminHubTeam.addBtnLabel' | translate }}"
          buttonIconLeft="far fa-plus"
          [buttonSize]="buttonSize.MEDIUM"
          (click)="onAddNewAdmin()" />
      </div>
    </admin-table-header-navbar>
  </div>

  <admin-table-filter-navbar (onSearchTermChange)="searchValue = $event">
  </admin-table-filter-navbar>

  <admin-table
    [loading]="loadingEntries"
    [columns]="columns"
    [entries]="entries"
    [paginator]="true"
    [lazy]="false"
    [emptyStateKey]="'adminHubTeam.emptyState'"
    [searchValue]="searchValue"
    [searchFilterFields]="searchFilterFields"
    checkBoxSelection="true"
    [(selectedEntries)]="selectedEntries"
    (rowClicked)="onRowClicked($event)">
    <ng-template
      let-entry
      epTemplateKey="logoCell">
      <image
        [src]="entry?.profileImage"
        [text]="imageText(entry)"
        [size]="'extra-small'"></image>
    </ng-template>

    <ng-template
      let-entry
      epTemplateKey="statusCell">
      <app-status-tag
        [iconClass]="entry.isActive ? 'fa-regular fa-check' : 'fa-regular fa-xmark'"
        tagText="{{
          (entry.isActive ? 'adminTable.statusActive' : 'adminTable.statusInactive') | translate
        }}"
        [bgColor]="entry.isActive ? 'var(--appPrimaryColor)' : '#ff244c'" />
    </ng-template>

    <ng-template
      let-entry
      epTemplateKey="actionsCell">
      <div class="table-btn-actions-container">
        <app-button
          [buttonType]="buttonType.ROUND"
          [buttonSize]="buttonSize.MEDIUM"
          [buttonStyle]="buttonStyle.FILLED_SECONDARY"
          buttonRoundIcon="far fa-pen-to-square"
          (click)="edit($event, entry)" />
        <app-button
          [buttonType]="buttonType.ROUND"
          [buttonSize]="buttonSize.MEDIUM"
          [buttonStyle]="buttonStyle.FILLED_DANGER"
          buttonRoundIcon="far fa-trash-can"
          (click)="unAttachUser($event, entry)" />
      </div>
    </ng-template>
  </admin-table>
</div>
