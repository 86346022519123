import * as moment from 'moment';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';

import { environment } from 'src/environments/environment';
import { ProgressBarDialogComponent, parseToMoment, IUserType } from 'src/app/shared';
import { IUser } from '../models';

export const JPEG_PNG_FORMATS = ['image/jpeg', 'image/jpg', 'image/png'];
export const IMG_FORMATS = ['image/jpeg', 'image/jpg', 'image/png', 'image/svg', 'image/svg+xml'];
export const VALID_LINK_PATTERN =
  /https?:\/\/?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,100000000}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/;
export const VALID_PHONE_PATTERN = /^([0-9]|#|\+|\*|\s)+$/;
export const FACEBOOK_PROFILE_LINK_PATTERN = /^(http(s)?:\/\/)(www\.)?(facebook|fb)\.com/;
export const INSTAGRAM_PROFILE_LINK_PATTERN = /^(http(s)?:\/\/)(www\.)?instagram\.com/;
export const YOUTUBE_PROFILE_LINK_PATTERN = /^(http(s)?:\/\/)(www\.)?youtube\.com/;
export const LINKEDIN_PROFILE_LINK_PATTERN = /^(http(s)?:\/\/)(www\.)?linkedin\.com/;
export const XING_PROFILE_LINK_PATTERN = /^(http(s)?:\/\/)(www\.)?xing\.com/;
export const TWITTER_PROFILE_LINK_PATTERN = /^(http(s)?:\/\/)(www\.)?twitter\.com/;
export const CALENDLY_PROFILE_LINK_PATTERN = /^(http(s)?:\/\/)(www\.)?calendly\.com/;
export const PROFILE_IMAGE_MAX_SIZE = 4_194_304;
export const LETTERS_NUMBERS_SPACE = /^[0-9a-zA-ZÜüÖöÄä ]+$/;
export const VIRTUAL_SCROLL_ITEM_SIZE = 30;

export const httpLoaderFactory = (http: HttpClient): TranslateHttpLoader =>
  new TranslateHttpLoader(http);

export const trackByFn = (index: number, item: any): number => item.id;

export const asyncDelay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

// TODO remove and use array.indexOf(element);
export const getArrayElementIndexById = (array, id): any => {
  for (let index = 0; index < array.length; index++) {
    const element = array[index];

    if (element.id === id) {
      return index;
    }
  }
};

export const capitalizeFirstLetter = (value: string): string =>
  value.charAt(0).toUpperCase() + value.slice(1);

export const goToLink = (url: string): void => {
  window.open(url, '_blank');
};

export const isLocalEnvironment = (): boolean => environment.env === 'LOCAL';

export const isObjectEmpty = (obj): boolean => Object.keys(obj).length === 0;

export const eventIsPassed = (event): boolean => moment().isAfter(parseToMoment(event.end));

export const compareDate = (date1: Date, date2: Date): boolean => getDate(date1) === getDate(date2);

export const getDate = (date: Date): string =>
  `${date.getDate()}.${date.getMonth()}.${date.getFullYear()}`;

export const sortByFirstAndLastName = (
  a: { _firstName_: string; _lastName_: string },
  b: { _firstName_: string; _lastName_: string },
  order: 'asc' | 'desc',
): number => {
  const firstNameA = order === 'desc' ? b._firstName_.toLowerCase() : a._firstName_.toLowerCase();
  const firstNameB = order === 'desc' ? a._firstName_.toLowerCase() : b._firstName_.toLowerCase();
  const lastNameA = order === 'desc' ? b._lastName_.toLowerCase() : a._lastName_.toLowerCase();
  const lastNameB = order === 'desc' ? a._lastName_.toLowerCase() : b._lastName_.toLowerCase();

  return firstNameA === firstNameB
    ? lastNameA.localeCompare(lastNameB)
    : firstNameA.localeCompare(firstNameB);
};

export const sortItems = (firstValue: string, secondValue: string, order: 'asc' | 'desc') => {
  return order === 'asc'
    ? firstValue.localeCompare(secondValue)
    : secondValue.localeCompare(firstValue);
};

export const sortItemsWithNumeric = (
  firstValue: string,
  secondValue: string,
  order: 'asc' | 'desc',
) => {
  return order === 'asc'
    ? firstValue.localeCompare(secondValue, ['en', 'de'], { numeric: true })
    : secondValue.localeCompare(firstValue, ['en', 'de'], { numeric: true });
};

export const createCalendarEvent = (
  events: {
    start: Date;
    end?: Date;
    summary: string;
    description?: string;
    location?: string;
    url?: string;
  }[],
) => {
  const formatDate = (date: Date): string => {
    if (!date) {
      return '';
    }
    // don't use date.toISOString() here, it will be always one day off (cause of the timezone)
    const day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
    const month = date.getMonth() < 9 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1;
    const year = date.getFullYear();
    const hour = date.getHours() < 10 ? '0' + date.getHours() : date.getHours();
    const minutes = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
    const seconds = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
    return `${year}${month}${day}T${hour}${minutes}${seconds}`;
  };
  let vCalendar = `BEGIN:VCALENDAR
PRODID:-//Events Calendar//HSHSOFT 1.0//DE
VERSION:2.0
`;

  for (const event of events) {
    const timeStamp = formatDate(new Date());
    const uuid = `${timeStamp}Z-uid@hshsoft.de`;
    /**
     * Don't ever format this string template!!!
     */
    const EVENT = `BEGIN:VEVENT
DTSTAMP:${timeStamp}Z
DTSTART:${formatDate(event.start)}
DTEND:${formatDate(event.end)}
SUMMARY:${event.summary}
DESCRIPTION:${event.description}
LOCATION:${event.location}
URL:${event.url}
UID:${uuid}
END:VEVENT`;
    vCalendar += `${EVENT}
`;
  }
  vCalendar += `END:VCALENDAR`;

  return vCalendar;
};

export const downloadFile = (filename, text) => {
  const element = document.createElement('a');
  element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
  element.setAttribute('download', filename);
  element.setAttribute('target', '_blank');
  element.style.display = 'none';
  element.click();
};

export const shareInXIng = (url: string): void => {
  window.open('https://www.xing.com/social/share/spi?url=' + url, '_blank');
};

export const shareInFacebook = (url: string): void => {
  window.open('https://www.facebook.com/sharer/sharer.php?u=' + url, '_blank');
};

export const shareInLinkedIn = (url: string, title: string = '', text: string = ''): void => {
  window.open('https://www.linkedin.com/sharing/share-offsite/?url=' + url, '_blank');
};

export const shareInX = (url: string): void => {
  window.open('https://x.com/intent/post?url=' + url, '_blank');
};

export const scrollSectionToTop = (): void => {
  const el = document.getElementById('scrollableContent');
  if (el) {
    el.scrollTop = 0;
    el.scrollTop = 0;
  }
};

export const defaultDialogConfig = (): DynamicDialogConfig => ({
  width: window.screen.width < 460 ? '100%' : '80%',
  height: window.screen.width < 460 ? '100%' : '80%',
  dismissableMask: true,
  contentStyle: {
    overflow: 'auto',
    filter: 'brightness(85%)',
    padding: '0',
  },
  styleClass: window.screen.width < 460 ? 'mobile' : '',
});

export const isMobile = () => window.screen.width <= 460;
export const isDesktop = () => window.screen.width > 767;

export const getUserNameAbbreviation = (user: IUser): string => {
  let abr = '';

  if (!user) {
    return abr;
  }

  let firstLetter = user.firstName
    ? user.firstName.substring(0, 1)
    : user._firstName_
      ? user._firstName_.substring(0, 1)
      : '';
  let secondLetter = user.lastName
    ? user.lastName.substring(0, 1)
    : user._lastName_
      ? user._lastName_.substring(0, 1)
      : '';
  abr = (firstLetter + secondLetter).toUpperCase();
  return abr;
};

export const initProgressBar = async (loadingDelay, dialogService): Promise<void> => {
  await dialogService.open(ProgressBarDialogComponent, {
    width: '40%',
    height: '30%',
    dismissableMask: true,
    contentStyle: {
      overflow: 'auto',
      padding: '0',
      'border-radius': '20px',
    },
    closable: false,
    data: {
      loadingDelay,
    },
  });
};

export const getWindow: any = () => window;

export const IVSPlayer = () => (window as any).IVSPlayer;

export const mapRoleToUserType = (role: string): IUserType => {
  if (role === 'attendee') {
    return IUserType.Attendee;
  } else if (role === 'speaker') {
    return IUserType.Speaker;
  } else {
    console.error('Unknown role');
  }
};

export const DOMAINS_MAP: any = {
  'localhost:4444': 'test',
  'myevents.site': 'myevents',
  'planetschwarz.eventshub.io': 'planetschwarz',
  'recruitment.eventshub.io': 'recruitment',
};

export const DOMAINS_LIST = (): string[] => {
  const domains = [];
  for (const domain in DOMAINS_MAP) {
    if (Object.prototype.hasOwnProperty.call(DOMAINS_MAP, domain)) {
      const domainUrl = DOMAINS_MAP[domain];
      domains.push(domain);
    }
  }

  return domains;
};

// ==============================================================================================================================================================================================
// DEVICE
// ==============================================================================================================================================================================================
export interface DeviceState {
  isDesktopPlatform: boolean;
  desktopOS: DesktopOS | undefined;
  isWindowsDesktop: boolean;
  isLinuxOrUnixDesktop: boolean;

  isMobilePlatform: boolean;
  mobileOS: MobileOS | undefined;
  isAndroidDevice: boolean;
  isAppleDevice: boolean;
  isUnknownMobileDevice: boolean;

  isTablet: boolean;
  isLandscapeOrientation: () => boolean;
  isPortraitOrientation: () => boolean;
}

const userAgent: string =
  navigator.userAgent || navigator.vendor || (window as any).opera || undefined;

// Device typology

const isMobileDevice = (): boolean => {
  const regexs = [
    /(Android)(.+)(Mobile)/i,
    /BlackBerry/i,
    /iPhone|iPod/i,
    /Opera Mini/i,
    /IEMobile/i,
  ];
  return regexs.some((b) => userAgent.match(b));
};

const isTabletDevice = (): boolean => {
  const regex =
    /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/;
  return regex.test(userAgent.toLowerCase());
};

const isDesktopDevice = (): boolean => !isMobileDevice() && !isTabletDevice();

const isDesktopPlatform = isDesktopDevice();
const isMobilePlatform = isMobileDevice();
const isTablet = isTabletDevice();

// Device Operating System

enum MobileOS {
  Android = 'android',
  iOS = 'ios',
  Unknown = 'unknown',
  WindowsPhone = 'Windows Phone',
}

const getMobileOS = (): MobileOS | undefined => {
  if (isMobileDevice()) {
    if (/windows phone/i.test(userAgent)) return MobileOS.WindowsPhone;
    else if (/android/i.test(userAgent)) return MobileOS.Android;
    else if (/iPad|iPhone|iPod/.test(userAgent) && !(window as any).MSStream) return MobileOS.iOS;

    return MobileOS.Unknown;
  } else return undefined;
};

enum DesktopOS {
  Linux = 'linux',
  MacOS = 'mac_os',
  Unix = 'unix',
  Unknown = 'unknown',
  Windows = 'windows',
}

const getDesktopOS = (): DesktopOS | undefined => {
  if (isDesktopDevice()) {
    if (userAgent.indexOf('Win') !== -1) return DesktopOS.Windows;
    else if (userAgent.indexOf('Mac') !== -1) return DesktopOS.MacOS;
    else if (userAgent.indexOf('X11') !== -1) return DesktopOS.Unix;
    else if (userAgent.indexOf('Linux') !== -1) return DesktopOS.Linux;

    return DesktopOS.Unknown;
  } else return undefined;
};

type DeviceOS = DesktopOS | MobileOS;

const getDeviceOS = (): DeviceOS | undefined => getMobileOS() ?? getDesktopOS();

const mobileOS: MobileOS | undefined = getMobileOS();
const isAndroidDevice = getDeviceOS() === MobileOS.Android;
const isAppleDevice = getDeviceOS() === MobileOS.iOS || getDeviceOS() === DesktopOS.MacOS;
const isUnknownMobileDevice = getDeviceOS() === MobileOS.Unknown;

const desktopOS: DesktopOS | undefined = getDesktopOS();
const isWindowsDesktop = getDeviceOS() === DesktopOS.Windows;
const isLinuxOrUnixDesktop = getDeviceOS() === DesktopOS.Linux || getDeviceOS() === DesktopOS.Unix;

// Device orientation

const supportedScreenOrientation =
  (screen?.orientation || {}).type ??
  (screen as any).mozOrientation ??
  (screen as any).msOrientation;

const safariScreenOrientation: OrientationType =
  !screen?.orientation && matchMedia('(orientation: portrait)').matches
    ? 'portrait-primary'
    : 'landscape-primary';

const initialScreenOrientation =
  supportedScreenOrientation ?? safariScreenOrientation ?? 'portrait-primary';
let screenOrientation: OrientationType = initialScreenOrientation;

if (screen.orientation) {
  screen.orientation.addEventListener(
    'change',
    (ev: Event) => (screenOrientation = (ev.target ?? ({} as any)).type),
  );
}

const isLandscapeOrientation = () =>
  ['landscape-primary', 'landscape-secondary'].includes(screenOrientation);
const isPortraitOrientation = () =>
  ['portrait-primary', 'portrait-secondary'].includes(screenOrientation);

export const useDevice = (): DeviceState => ({
  isDesktopPlatform,
  desktopOS,
  isWindowsDesktop,
  isLinuxOrUnixDesktop,
  isMobilePlatform,
  mobileOS,
  isAndroidDevice,
  isAppleDevice,
  isUnknownMobileDevice,
  isTablet,
  isLandscapeOrientation,
  isPortraitOrientation,
});

// ==============================================================================================================================================================================================
// ==============================================================================================================================================================================================
