import { Injectable } from '@angular/core';

import { Firestore, IDocumentData, IDocumentReference, IFirestore } from 'src/app/firebase';
import { API_ROUTES as apiRoutes } from 'src/app/shared';
import { AlgoliaSearchResult, IUser, IUserCourse, IUserHub } from '../../models';
import { AlgoliaService } from '../algolia/algolia.service';

@Injectable({
  providedIn: 'root',
})
export class CourseUserService {
  private firestore: IFirestore;

  constructor(private algoliaService: AlgoliaService) {
    this.firestore = Firestore();
  }

  async getAllCourseUsers(courseId: string): Promise<IUserCourse[]> {
    const query = await this.firestore
      .collection(apiRoutes.userCourses)
      .where('courseId', '==', courseId)
      .get();

    const courseUsers: IUserCourse[] = query.docs.map((doc) => doc.data() as IUserCourse);

    return courseUsers;
  }

  async getUnattachedUsersFromAlgolia(
    hubId: string,
    courseId: string,
    pageIndex: number,
    pageSize: number,
    searchTerm: string,
  ): Promise<AlgoliaSearchResult<IUser>> {
    const attachedUsers: IUserCourse[] = await this.getAllCourseUsers(courseId);
    const attachedUsersIds: string[] = attachedUsers.map((user: IUserCourse) => user.userId);
    const facetFilters = attachedUsersIds.map((id) => `userId:-${id}`).join(',');

    const resultFromAlgolia: AlgoliaSearchResult<IUserHub> =
      await this.algoliaService.search<IUserHub>(
        apiRoutes.userHubs,
        `${searchTerm} ${hubId}`,
        pageSize,
        pageIndex * pageSize,
        '',
        facetFilters,
      );

    const result: AlgoliaSearchResult<IUser> = {
      total: resultFromAlgolia.total,
      results: resultFromAlgolia.results.map((item: any) => item.user),
    };

    return result;
  }

  async getUserCourseById(userCourseId: string): Promise<IUserCourse> {
    try {
      const userCourse = (
        await this.firestore.collection(apiRoutes.userCourses).doc(userCourseId).get()
      ).data() as IUserCourse;
      return userCourse;
    } catch (error) {
      console.warn(error);
      throw error;
    }
  }

  async getUserCourse(courseId: string, userId: string): Promise<IUserCourse> {
    try {
      const userCourse: IUserCourse = (
        await this.firestore
          .collection(apiRoutes.userCourses)
          .where('courseId', '==', courseId)
          .where('userId', '==', userId)
          .get()
      ).docs[0]?.data() as IUserCourse;

      return userCourse;
    } catch (error) {
      console.warn(error);
      throw error;
    }
  }

  async getUserCoursesByUserId(userId: string): Promise<IUserCourse[]> {
    try {
      const userCourses: IUserCourse[] = (
        await this.firestore.collection(apiRoutes.userCourses).where('userId', '==', userId).get()
      ).docs.map((doc) => doc.data() as IUserCourse);

      return userCourses;
    } catch (error) {
      console.warn(error);
      throw error;
    }
  }

  async updateCourseUser(userCourse: IUserCourse): Promise<IUserCourse> {
    try {
      const userCourseDocument: IDocumentReference<IDocumentData> = this.firestore
        .collection(apiRoutes.userCourses)
        .doc(userCourse.id);
      await userCourseDocument.update({ ...userCourse });

      return userCourse;
    } catch (error) {
      console.warn(error);
      throw error;
    }
  }

  async deleteUserCourse(userCourseId: string): Promise<boolean> {
    try {
      await this.firestore.collection(apiRoutes.userCourses).doc(userCourseId).delete();

      return true;
    } catch (error) {
      console.warn(error);
      throw error;
    }
  }
}
