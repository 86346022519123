export const removeSpecificStylesFromUnlayerHtml = (html: string): string => {
  // Create a DOM parser
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, 'text/html');

  // Find all style tags
  const styleTags = doc.getElementsByTagName('style');

  for (let i = 0; i < styleTags.length; i++) {
    let styleContent = styleTags[i].textContent;
    if (styleContent) {
      // Remove font-size from html tag
      styleContent = styleContent.replace(
        /(html\s*{[^}]*)(font-size\s*:[^;}]+;?)/g,
        (match, p1) => p1,
      );

      // Remove line-height from body tag
      styleContent = styleContent.replace(
        /(body\s*{[^}]*)(line-height\s*:[^;}]+;?)/g,
        (match, p1) => p1,
      );

      styleTags[i].textContent = styleContent;
    }
  }

  // Convert back to string
  return doc.documentElement.outerHTML;
};
