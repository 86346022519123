<ng-container *ngIf="loading">
  <app-loader></app-loader>
</ng-container>
<div
  *ngIf="!loading"
  class="admin-course-promotions-conatiner">
  <div class="admin-course-promotions-table-container">
    <div class="c-mb-4">
      <admin-table-header-navbar [title]="'adminCourseCoupons.title' | translate">
        <app-button
          routerLink="/{{ hubsStore.useHubUrl }}/admin/courses/{{
            courseStore.adminCourse?.link
          }}/setup/promotions/new-promotion"
          buttonText="{{ 'adminCourseCoupons.addBtn' | translate }}"
          buttonIconLeft="far fa-plus"
          [buttonSize]="buttonSize.MEDIUM" />
      </admin-table-header-navbar>
    </div>

    <admin-table-filter-navbar
      (onSearchTermChange)="searchValue = $event"></admin-table-filter-navbar>
    <admin-table
      [loading]="loading"
      [paginator]="true"
      [lazy]="false"
      [columns]="columns"
      [entries]="entries"
      [emptyStateKey]="'adminCourseCoupons.emptyState'"
      [emptyStateIcon]="'fa-regular fa-ticket'"
      [searchValue]="searchValue"
      [searchFilterFields]="['code', 'name']"
      (rowClicked)="onRowClicked($event)">
      <ng-template
        let-entry
        epTemplateKey="nameCell">
        {{ entry.name }}
        <div class="percentage-info">
          {{ getPercentageText(entry, 'expires_at') }}
        </div>
      </ng-template>

      <ng-template
        let-entry
        epTemplateKey="codeCell">
        <div class="code-label">
          {{ entry.code }}
        </div>
      </ng-template>

      <ng-template
        let-entry
        epTemplateKey="expiresCell">
        <p class="m-0 expire-date">{{ getDateForCoupon(entry, 'expires_at') }}</p>
        <p class="m-0 expire-time">{{ getTimeForCoupon(entry, 'expires_at') }}</p>
      </ng-template>

      <ng-template
        let-entry
        epTemplateKey="redemptionsCell">
        <div class="redemptions">
          {{ entry.timesRedeemed }}
        </div>
      </ng-template>

      <ng-template
        let-entry
        epTemplateKey="actionsCell">
        <div class="table-btn-actions-container">
          <app-button
            [buttonType]="buttonType.ROUND"
            [buttonSize]="buttonSize.MEDIUM"
            [buttonStyle]="buttonStyle.FILLED_SECONDARY"
            buttonRoundIcon="far fa-pen-to-square"
            (click)="edit($event, entry)" />
          <app-button
            [buttonType]="buttonType.ROUND"
            [buttonSize]="buttonSize.MEDIUM"
            [buttonStyle]="buttonStyle.FILLED_DANGER"
            buttonRoundIcon="far fa-trash-can"
            (click)="remove($event, entry)" />
        </div>
      </ng-template>
    </admin-table>
  </div>
</div>
