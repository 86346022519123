<div class="container">
  <h1 class="title">{{ 'adminDashboardGetStarted.title' | translate }}</h1>
  <p class="subtitle">
    {{ 'adminDashboardGetStarted.subtitle' | translate }}
  </p>
  <div class="setup-list">
    <div class="setup-item">
      <div class="icon">
        <i class="fas fa-wrench"></i>
      </div>
      <div class="step-content">
        <div class="step-title">
          {{ 'adminDashboardGetStarted.setUpYourHubStepTitle' | translate }}
        </div>
        <div class="step-subtitle">
          {{ 'adminDashboardGetStarted.setUpYourHubStepSubtitle' | translate }}
        </div>
      </div>
      <span class="checkmark">
        <i class="fas fa-check"></i>
      </span>
    </div>
    <div
      class="setup-item cursor-pointer"
      [routerLink]="['/', hubUrl, 'admin', 'hub-settings', 'profile']">
      <div class="icon">
        <i class="fas fa-paint-brush"></i>
      </div>
      <div class="step-content">
        <div class="step-title">
          {{ 'adminDashboardGetStarted.customizeYourDesignStepTitle' | translate }}
        </div>
        <div class="step-subtitle">
          {{ 'adminDashboardGetStarted.customizeYourDesignStepSubtitle' | translate }}
        </div>
      </div>
      <span class="checkmark">
        @if (isHubCustomized) {
          <i class="fas fa-check"></i>
        } @else {
          <i class="fas fa-chevron-right"></i>
        }
      </span>
    </div>
    <div class="setup-item">
      <div class="icon"><i class="fas fa-box"></i></div>
      <div class="step-content">
        <div class="step-title">
          {{ 'adminDashboardGetStarted.createYourFirstProductStepTitle' | translate }}
        </div>
        <div class="step-subtitle">
          {{ 'adminDashboardGetStarted.createYourFirstProductStepSubtitleFirstPart' | translate }}
          <a
            class="inline-link"
            [routerLink]="['/', hubUrl, 'admin', 'courses']">
            {{
              'adminDashboardGetStarted.createYourFirstProductStepSubtitleSecondPart' | translate
            }}
          </a>
          {{ 'adminDashboardGetStarted.createYourFirstProductStepSubtitleThirdPart' | translate }}
          <a
            class="inline-link"
            [routerLink]="['/', hubUrl, 'admin', 'events']">
            {{
              'adminDashboardGetStarted.createYourFirstProductStepSubtitleFourthPart' | translate
            }}
          </a>
          {{ 'adminDashboardGetStarted.createYourFirstProductStepSubtitleFifthPart' | translate }}
        </div>
      </div>
      @if (isExistEventOrCourseInHub()) {
        <span class="checkmark">
          <i class="fas fa-check"></i>
        </span>
      }
    </div>
    <div
      class="setup-item cursor-pointer"
      [routerLink]="['/', hubUrl, 'admin', 'users']">
      <div class="icon"><i class="fas fa-users"></i></div>
      <div class="step-content">
        <div class="step-title">
          {{ 'adminDashboardGetStarted.buildYourAudienceStepTitle' | translate }}
        </div>
        <div class="step-subtitle">
          {{ 'adminDashboardGetStarted.buildYourAudienceStepSubtitle' | translate }}
        </div>
      </div>
      <span class="checkmark">
        @if (totalNumberOfUsersInHub()) {
          <i class="fas fa-check"></i>
        } @else {
          <i class="fas fa-chevron-right"></i>
        }
      </span>
    </div>
    <div
      class="setup-item cursor-pointer"
      (click)="onOpenShareDialog()">
      <div class="icon"><i class="fas fa-globe"></i></div>
      <div class="step-content">
        <div class="step-title">
          {{ 'adminDashboardGetStarted.shareYourHubStepTitle' | translate }}
        </div>
        <div class="step-subtitle">
          {{ 'adminDashboardGetStarted.shareYourHubStepSubtitle' | translate }}
        </div>
      </div>
      <span class="checkmark">
        @if (hub.isHubShared) {
          <i class="fas fa-check"></i>
        } @else {
          <i class="fas fa-chevron-right"></i>
        }
      </span>
    </div>
  </div>

  <div class="help-text">
    {{ 'adminDashboardGetStarted.helpTextFirstPart' | translate }}
    <a
      [href]="helpUrl()"
      target="_blank">
      {{ 'adminDashboardGetStarted.helpTextSecondPart' | translate }} </a
    >.
  </div>
  <button
    class="skip-button"
    (click)="onSkipOnboarding()">
    {{ 'adminDashboardGetStarted.skipBtn' | translate }}
  </button>
</div>
