import * as dayjs from 'dayjs';
import * as weekOfYear from 'dayjs/plugin/weekOfYear';

export const getWeeksFromToday = (count: number): number[] => {
  dayjs.extend(weekOfYear);
  const result: number[] = [];

  for (let i = count - 1; i >= 0; i--) {
    result.push(dayjs().subtract(i, 'week').week());
  }

  return result;
};
