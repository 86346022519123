import { Component, Output, EventEmitter, input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MenuItem } from 'primeng/api';

import { ICustomSidebarMenuItem } from 'src/app/core/models';
import { FeatureSidebarComponent } from '../feature-sidebar/feature-sidebar.component';

@Component({
  selector: 'app-feature-main-content',
  standalone: true,
  imports: [CommonModule, RouterModule, FeatureSidebarComponent],
  templateUrl: './feature-main-content.component.html',
  styleUrls: ['./feature-main-content.component.scss'],
})
export class FeatureMainContentComponent {
  activeMenuItem = input<ICustomSidebarMenuItem>();
  checkedMenuItems = input<string[]>();
  isHideFeatureSidebar = input<boolean>(false);
  @Output() checkClicked = new EventEmitter<MenuItem>();

  toggleActive(item: MenuItem): void {
    this.checkClicked.emit(item);
  }
}
