import { Component, Input, Output, EventEmitter } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MenuItem } from 'primeng/api';

import { ICustomSidebarMenuItem, SidebarItemsLayout } from 'src/app/core/models';
import { trackByFn } from 'src/app/core/utils';

@Component({
  selector: 'app-feature-sidebar',
  standalone: true,
  imports: [CommonModule, RouterModule],
  templateUrl: './feature-sidebar.component.html',
  styleUrls: ['./feature-sidebar.component.scss'],
})
export class FeatureSidebarComponent {
  @Input() currentMenuItem: ICustomSidebarMenuItem;
  @Input() checkedMenuItems: string[];
  @Output() checkClicked = new EventEmitter<MenuItem>();

  sidebarItemsLayout = SidebarItemsLayout;
  trackByFn = trackByFn;

  toggleActive(item: MenuItem, event: Event): void {
    event.stopPropagation();
    event.preventDefault();
    this.checkClicked.emit(item);
  }
}
