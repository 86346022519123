import { Component, inject, OnInit, signal } from '@angular/core';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { DialogService } from 'primeng/dynamicdialog';

import { HubsStore } from 'src/app/core/stores';
import { DashboardStore, HELP_URL } from 'src/app/standalone/shared';
import { ShareDialogComponent } from '../../dialogues';
import { IHub } from 'src/app/core/models';

@Component({
  selector: 'app-get-started',
  standalone: true,
  imports: [TranslateModule, RouterModule],
  templateUrl: './get-started.component.html',
  styleUrl: './get-started.component.scss',
})
export class GetStartedComponent implements OnInit {
  helpUrl = signal(HELP_URL);
  totalNumberOfUsersInHub = signal(0);
  isExistEventOrCourseInHub = signal(false);

  #dashboardStore = inject(DashboardStore);
  #hubsStore = inject(HubsStore);
  #dialogService = inject(DialogService);
  #route = inject(ActivatedRoute);

  get hubUrl(): string {
    return this.#hubsStore.useHubUrl;
  }

  get hub(): IHub {
    return this.#hubsStore.hub;
  }

  get isHubCustomized(): boolean {
    return !!(
      this.hub.logoDark &&
      this.hub.logoLight &&
      this.hub.icon &&
      this.hub.banner &&
      this.hub.primaryColor
    );
  }

  ngOnInit(): void {
    this.totalNumberOfUsersInHub.set(this.#route.snapshot.data['totalNumberOfUsersInHub']);
    this.isExistEventOrCourseInHub.set(this.#route.snapshot.data['isExistEventOrCourseInHub']);
  }

  onSkipOnboarding(): void {
    this.#dashboardStore.changeShowingGetStartedPage(false);
  }

  onOpenShareDialog(): void {
    this.#dialogService.open(ShareDialogComponent, {
      closable: false,
      styleClass: 'reset-dialog-styles',
    });
  }
}
