<ng-container *ngIf="loading">
  <app-loader></app-loader>
</ng-container>

<div
  *ngIf="!loading"
  class="admin-courses-container">
  <div class="admin-courses-table-container">
    <div class="c-mb-4">
      <admin-table-header-navbar [title]="'adminCourseList.title' | translate">
        @if (hubsStore.hub) {
          <app-button
            buttonText="{{ 'adminCourseList.addBtnTitle' | translate }}"
            buttonIconLeft="far fa-plus"
            [buttonSize]="buttonSize.MEDIUM"
            [buttonTooltip]="
              !canCreateNewCourse() ? ('adminCourseList.newCourseBtnTooltip' | translate) : ''
            "
            buttonTooltipPosition="bottom"
            [disabled]="!canCreateNewCourse()"
            (click)="openNewCourseDialog()" />
        }
      </admin-table-header-navbar>
    </div>

    <admin-table-filter-navbar
      [searchActive]="!appStore.generalSystemSettings.enableEncryption"
      [filters]="tableFilters"
      (onFiltersChange)="filtersChange($event)"
      (onSearchTermChange)="onSearchTermChange($event)">
    </admin-table-filter-navbar>

    <admin-table
      [lazyLoading]="loadingEntries"
      [columns]="columns"
      [entries]="entries"
      [pageSize]="pageSize"
      [totalRecords]="totalRecords"
      scssClass="show-row-border-bottom"
      emptyStateKey="adminCourseList.emptyState"
      emptyStateIcon="far fa-graduation-cap"
      (lazyLoad)="fetchEntries($event)"
      (rowClicked)="onRowClicked($event)">
      <ng-template
        let-entry
        epTemplateKey="titleCell">
        <div class="title-cell">
          <div>
            <img
              *ngIf="entry.banner"
              class="banner"
              [defaultImage]="imgPreloader"
              [lazyLoad]="entry.banner | imageSize" />
            <div
              *ngIf="!entry.banner"
              class="placeholder-container">
              <img
                class="placeholder"
                [src]="logoAvatar | imageSize" />
            </div>
          </div>
          <p class="course-title">{{ entry.title }}</p>
        </div>
      </ng-template>

      <ng-template
        let-entry
        epTemplateKey="statusCell">
        <app-status-tag
          tagText="{{
            (entry.isPublished ? 'adminCourseList.published' : 'adminCourseList.unpublished')
              | translate
          }}"
          [bgColor]="entry.isPublished ? 'var(--appPrimaryColor)' : '#ff244c'" />
      </ng-template>

      <ng-template
        let-entry
        epTemplateKey="actionsCell">
        <div class="table-btn-actions-container">
          <app-button
            [buttonType]="buttonType.ROUND"
            [buttonSize]="buttonSize.MEDIUM"
            [buttonStyle]="buttonStyle.FILLED_SECONDARY"
            [loading]="entry?.isActionBtnShowingSpinner"
            buttonRoundIcon="far fa-pen-to-square"
            (click)="edit($event, entry)" />
          <app-button
            [buttonType]="buttonType.ROUND"
            [buttonSize]="buttonSize.MEDIUM"
            [buttonStyle]="buttonStyle.FILLED_DANGER"
            [loading]="entry?.isActionBtnShowingSpinner"
            buttonRoundIcon="far fa-trash-can"
            (click)="remove($event, entry)" />
        </div>
      </ng-template>
    </admin-table>
  </div>
</div>
