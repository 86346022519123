import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AssetsService, CourseTrackingService, UsersService } from 'src/app/core/services';
import { ActivatedRoute, Router } from '@angular/router';
import { IAsset, IUser } from 'src/app/core/models';
import {
  StatCardComponent,
  ItemDetailHeaderComponent,
  StatusTagComponent,
} from 'src/app/standalone/shared';
import { trackByFn } from 'src/app/core/utils';
import { IAdminTableColumn, SharedModule, imgPreloader, logoAvatar } from 'src/app/shared';
import { TranslateService } from '@ngx-translate/core';
import { CoursesStore, HubsStore } from 'src/app/core/stores';
import { userAvatar } from 'src/app/shared/utils';

@Component({
  selector: 'app-asset-reports',
  standalone: true,
  imports: [
    CommonModule,
    ItemDetailHeaderComponent,
    StatCardComponent,
    SharedModule,
    StatusTagComponent,
  ],
  templateUrl: './asset-reports.component.html',
  styleUrls: ['./asset-reports.component.scss'],
})
export class AssetReportsComponent implements OnInit {
  loading = true;
  loadingEntries = true;

  trackByFn = trackByFn;
  statCards: { iconClass: string; text: string; value: string }[];
  columns: IAdminTableColumn[] = [];
  entries = [];
  totalRecords = 0;
  pageSize = 10;
  imgPreloader = imgPreloader;
  logoAvatar = logoAvatar;
  userAvatar = userAvatar;
  assetId: string;
  asset: IAsset;
  users: IUser[] = [];
  courseId: string;
  searchFilterFields: string[] = [];
  searchTerm = '';

  constructor(
    private assetsService: AssetsService,
    private router: Router,
    private route: ActivatedRoute,
    private translateService: TranslateService,
    private courseTrackingService: CourseTrackingService,
    private usersService: UsersService,
    private hubsStore: HubsStore,
    private coursesStore: CoursesStore,
  ) {}

  async ngOnInit(): Promise<void> {
    this.courseId = this.coursesStore.adminCourse.id;
    this.initializeStatCards();
    this.setSearchFilterFields();
    this.assetId = this.extractUserIdFromURL();
    await this.loadAssetData();
    this.fetchStatisticsData();
    await this.fetcUsershData();
  }

  getCardsContainerWidth(): string {
    const maxWidthPercentage = 100;
    const minItemWidthPercentage = 10;
    const items = this.statCards.length > 0 ? this.statCards.length : 1;
    const calculatedWidth = Math.min(maxWidthPercentage, minItemWidthPercentage * items);
    return `${calculatedWidth}%`;
  }

  getPercentageString(percentage: any): string {
    if (percentage == 0) {
      return `0 %`;
    } else {
      return `${percentage.toFixed(1)} %`;
    }
  }

  private async fetchStatisticsData(): Promise<void> {
    const [totalViews, totalViewers, avgTimeSpent, avgPercentView] = await Promise.all([
      this.courseTrackingService.getViewsCountForSingleAssetByAssetId(this.assetId, this.courseId),
      this.courseTrackingService.getViewersCountForSingleAssetByAssetId(
        this.assetId,
        this.courseId,
      ),
      this.courseTrackingService.getAverageTimeSpendByAssetId(this.assetId, this.courseId),
      this.courseTrackingService.getAveragePercentViewByAssetId(this.assetId, this.courseId),
    ]);

    this.updateStatCards(totalViews, totalViewers, avgTimeSpent, avgPercentView);
    this.loading = false;
  }

  private updateStatCards(
    totalViews: number,
    viewers: number,
    avgTimeSpent: number,
    averageWatched: number,
  ): void {
    // this.statCards[0].value = totalViews.toString();
    this.statCards[0].value = viewers.toString();
    this.statCards[1].value = `${avgTimeSpent.toFixed(2)} ${this.translateService.instant('courseReports.min')}`;
    this.statCards[2].value = this.getPercentageString(averageWatched);
  }

  async fetcUsershData(): Promise<void> {
    const userCourseTrackins = await this.courseTrackingService.getByAssetId(
      this.assetId,
      this.courseId,
    );
    this.users = await this.usersService.getAllByIds(userCourseTrackins.map((ct) => ct.userId));
    this.entries = await this.getInfoForSubscribers(this.users);
    this.loadingEntries = false;
  }

  async getInfoForSubscribers(users: IUser[]): Promise<any[]> {
    const infoPromises = users.map(async (cs) => {
      const info = await this.courseTrackingService.getAllInfoByUserAndAssetId(
        cs.id,
        this.assetId,
        this.courseId,
      );
      return { ...cs, ...info };
    });

    return Promise.all(infoPromises);
  }

  private initializeStatCards(): void {
    this.statCards = [
      // {
      //   iconClass: 'fa-regular fa-eye',
      //   text: 'courseReports.totalViews',
      //   value: '0'
      // },
      {
        iconClass: 'fa-regular fa-play',
        text: 'courseReports.viewers',
        value: '0',
      },
      {
        iconClass: 'fa-regular fa-timer',
        text: 'courseReports.averageTimeSpent',
        value: '0',
      },
      {
        iconClass: 'fa-regular fa-percent',
        text: 'courseReports.averageWathed',
        value: '0',
      },
    ];

    this.columns = [
      {
        field: 'firstName',
        template: 'titleCell',
        titleTranslationKey: 'courseReports.subscribersName',
        sortable: true,
      },
      {
        field: 'viewed',
        template: 'statusCell',
        titleTranslationKey: 'courseReports.viewed',
        sortable: true,
      },
      // {
      //   field: 'views',
      //   template: 'countCell',
      //   titleTranslationKey: 'courseReports.assetViews',
      //   sortable: true
      // },
      {
        field: 'averagePercentWatched',
        template: 'progressCell',
        titleTranslationKey: 'courseReports.assetAveragePercentWatched',
        sortable: true,
      },
    ];
  }

  private extractUserIdFromURL(): string {
    return this.router.url.substring(this.router.url.lastIndexOf('/') + 1);
  }

  private async loadAssetData(): Promise<void> {
    this.asset = await this.assetsService.getOne(this.assetId);
  }

  async onSearchTermChange(keyword: string): Promise<void> {
    this.searchTerm = keyword;
  }

  private setSearchFilterFields() {
    this.searchFilterFields = [
      'email',
      'firstName',
      'lastName',
      'averagePercentWatched',
      'viewed',
      'views',
    ];
  }

  onRowClick(userId: string) {
    this.router.navigate([
      `${this.hubsStore.useHubUrl}/admin/courses/${this.coursesStore.adminCourse.link}/subscriber-reports/${userId}`,
    ]);
  }

  onBackClick(): void {
    this.router.navigate([
      `${this.hubsStore.useHubUrl}/admin/courses/${this.coursesStore.adminCourse.link}/reports`,
    ]);
  }
}
