import { Component, OnInit } from '@angular/core';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { Router } from '@angular/router';

import { StatCardComponent } from 'src/app/standalone/shared';
import { SharedModule, IAdminTableColumn, imgPreloader, logoAvatar } from 'src/app/shared';
import {
  CourseSubscribersService,
  CourseTrackingService,
  UsersService,
} from 'src/app/core/services';
import { CoursesStore, HubsStore } from 'src/app/core/stores';
import { IUser, IUserCourse } from 'src/app/core/models';
import { userAvatar } from 'src/app/shared/utils';

@Component({
  selector: 'app-subscribers-reports',
  standalone: true,
  imports: [CommonModule, StatCardComponent, SharedModule],
  templateUrl: './subscribers-reports.component.html',
  styleUrls: ['./subscribers-reports.component.scss'],
})
export class SubscribersReportsComponent implements OnInit {
  loading = true;
  loadingEntries = true;
  courseSubscribers: IUserCourse[] = [];
  users: IUser[] = [];
  entries: any[] = [];
  courseId: string;
  columns: IAdminTableColumn[] = [
    {
      field: 'firstName',
      template: 'titleCell',
      titleTranslationKey: 'courseReports.subscribersName',
      sortable: true,
    },
    {
      field: 'assetsViewed',
      template: 'statusCell',
      titleTranslationKey: 'courseReports.assetsViewed',
      sortable: true,
    },
    {
      field: 'averagePercentageWatched',
      template: 'progressCell',
      titleTranslationKey: 'courseReports.assetAveragePercentWatched',
      sortable: true,
    },
  ];
  totalRecords = 0;
  pageSize = 10;
  imgPreloader = imgPreloader;
  logoAvatar = logoAvatar;
  userAvatar = userAvatar;
  searchTerm = '';
  searchFilterFields: string[] = [];

  constructor(
    private courseTrackingService: CourseTrackingService,
    private coursesStore: CoursesStore,
    private courseSubscribersService: CourseSubscribersService,
    private usersService: UsersService,
    private router: Router,
    private hubsStore: HubsStore,
  ) {}

  async ngOnInit(): Promise<void> {
    this.courseId = this.coursesStore.adminCourse.id;
    this.setSearchFilterFields();
    await this.fetchUsersData();
  }

  getPercentageString(percentage: number): string {
    return percentage === 0 ? `0 %` : `${percentage.toFixed(1)} %`;
  }

  async fetchUsersData(): Promise<void> {
    this.courseSubscribers = await this.courseSubscribersService.getAllCourseSubscribers(
      this.courseId,
    );
    this.users = await this.usersService.getAllByIds(this.courseSubscribers.map((cs) => cs.userId));
    this.entries = await this.getInfoForSubscribers(this.users);
    this.loadingEntries = false;
    this.totalRecords = this.entries.length;
    this.loading = false;
  }

  async getInfoForSubscribers(users: IUser[]): Promise<any[]> {
    const infoPromises = users.map(async (cs) => {
      const info = await this.courseTrackingService.getAllInfoByUserId(cs.id, this.courseId);
      return { ...cs, ...info };
    });

    return Promise.all(infoPromises);
  }

  onRowClick(userId: string) {
    this.router.navigate([
      `${this.hubsStore.useHubUrl}/admin/courses/${this.coursesStore.adminCourse.link}/subscriber-reports/${userId}`,
    ]);
  }

  private setSearchFilterFields() {
    this.searchFilterFields = [
      'email',
      'viewsCount',
      'assetsViewed',
      'averagePercentageWatched',
      'firstName',
      'lastName',
    ];
  }

  async onSearchTermChange(keyword: string): Promise<void> {
    this.searchTerm = keyword;
  }
}
