import { FilterMatchMode } from 'primeng/api';

export interface IAdminTableColumn {
  field?: string;
  titleTranslationKey?: string;
  template?: string;
  sortable?: boolean;
  filterable?: boolean;
  filterMatchMode?: FilterMatchMode;
  filterOptions?: { name: string; value: string | boolean | number | Date }[];
  filterType?: 'text' | 'numeric' | 'boolean' | 'date';
  validatorMinDate?: Date;
  validatorMaxDate?: Date;
}
