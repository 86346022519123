import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
  AsyncValidatorFn,
  ValidationErrors,
  AbstractControl,
} from '@angular/forms';
import { Router } from '@angular/router';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { MessageService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';

import { HubsStore, UsersStore } from 'src/app/core/stores';
import {
  HubPagesService,
  HubsService,
  SettingsService,
  ThemesService,
} from 'src/app/core/services';
import {
  IHub,
  IAppearanceSettings,
  IPrivacySettingsPage,
  IGeneralSettings,
} from 'src/app/core/models';
import { IMG_FORMATS } from 'src/app/core/utils';
import { imgPreloader } from 'src/app/shared';

@Component({
  selector: 'app-new-hub',
  templateUrl: './new-hub.component.html',
  styleUrls: ['./new-hub.component.scss'],
})
export class NewHubComponent implements OnInit {
  @ViewChild('logoUpload') logoUploadInput: ElementRef<HTMLInputElement>;

  loading = false;
  form: UntypedFormGroup;
  isPublicSelected = true;
  isUpdating = false;
  imgPreloader = imgPreloader;
  logoUploadProcess = false;
  copyIsClicked = false;
  systemAppearanceSettings$: Observable<IAppearanceSettings> = null;
  generalSettings: IGeneralSettings;

  readonly linkPrefix: string = '';

  private newLogoPreview: string;

  constructor(
    private hubsStore: HubsStore,
    private hubsService: HubsService,
    private router: Router,
    private usersStore: UsersStore,
    private themesService: ThemesService,
    private translateService: TranslateService,
    private messageService: MessageService,
    private sanitizer: DomSanitizer,
    private settingsService: SettingsService,
    private hubPagesService: HubPagesService,
  ) {
    this.linkPrefix = this.hubsStore.hub
      ? `${this.hubsStore.environmentBaseUrl}${this.hubsStore.useHubUrl}/`
      : `${this.hubsStore.environmentBaseUrl}/`;
    this.systemAppearanceSettings$ = this.themesService.systemAppearanceSettings$;
  }

  get logo(): string {
    if (this.themesService.activeTheme.name === 'dark') {
      return this.hubsStore.hub?.logoDark
        ? this.hubsStore.hub?.logoDark
        : this.themesService.systemAppearanceSettings?.logo;
    } else {
      return this.hubsStore.hub?.logoLight
        ? this.hubsStore.hub?.logoLight
        : this.themesService.systemAppearanceSettings?.logoLight;
    }
  }

  get logoImage(): string | ArrayBuffer | SafeUrl {
    if (this.logoUploadProcess) {
      return null;
    }

    return this.newLogoPreview && typeof this.newLogoPreview === 'string'
      ? this.sanitizer.bypassSecurityTrustUrl(this.newLogoPreview)
      : null;
  }

  get canCreate(): boolean {
    return !this.isUpdating && this.form.valid;
  }

  get tooltipText(): string {
    return !this.copyIsClicked
      ? this.translateService.instant('copyTooltip.hover')
      : this.translateService.instant('copyTooltip.action');
  }

  get linkErrorText(): string {
    if (this.form?.controls?.link?.errors?.required) {
      return this.translateService.instant('adminHubProfileForm.eventLinkReq');
    }

    if (this.form?.controls?.link?.errors?.pattern) {
      return this.translateService.instant('adminHubProfileForm.eventLinkInvalidPattern');
    }

    if (this.form?.controls?.link?.errors?.exists) {
      return this.translateService.instant('adminHubProfileForm.linkExists');
    }

    return '';
  }

  async ngOnInit(): Promise<void> {
    this.form = new UntypedFormGroup({
      isPublic: new UntypedFormControl(true, [Validators.required]),
      title: new UntypedFormControl(null, [Validators.required]),
      link: new UntypedFormControl(null, [Validators.required], [this.linkExistsValidator()]),
      logo: new UntypedFormControl(null),
      primaryColor: new UntypedFormControl(null),
    });

    this.form.get('title').valueChanges.subscribe((value) => {
      const slug = this.generateSlug(value);
      this.form.get('link').setValue(slug, { emitEvent: false });
    });

    this.form.updateValueAndValidity();
    this.generalSettings = await this.settingsService.getSettings();
  }

  onPublic(): void {
    this.form.controls.isPublic.setValue(true);
    this.isPublicSelected = true;
  }

  onPrivate(): void {
    this.form.controls.isPublic.setValue(false);
    this.isPublicSelected = false;
  }

  onSelectPrimaryColor(value: string): void {
    this.form.controls.primaryColor.setValue(value);
  }

  async create(): Promise<void> {
    this.isUpdating = true;
    try {
      const hub = this.form.getRawValue();
      const hubPayload: IHub = {
        id: null,
        title: hub.title,
        _title_: hub.title,
        headline: hub.title,
        tagline: null,
        url: hub.link,
        logoDark: hub.logo,
        logoLight: null,
        icon: null,
        banner: null,
        primaryColor: hub.primaryColor,
        isPrivate: !hub.isPublic,
        isMaintenance: false,
        events: true,
        academy: false,
        brands: false,
        updatedAt: null,
        updatedBy: null,
        createdAt: null,
        createdBy: null,
        tenantId: this.usersStore.user.tenantId,
        isShowSidePanel: true,
        isHubShared: false,
        customDomain: null,
        emailSenderSettings: { ...this.generalSettings.defaultEmailSenderSettings },
      };

      const createdHub: IHub = await this.hubsService.create(hubPayload, this.usersStore.user);
      const globalPages: IPrivacySettingsPage[] = await this.settingsService.getAllPages();
      await this.hubPagesService.createHubPages(globalPages, createdHub.id);

      this.router.navigate([`${createdHub.url}/admin`]);
      this.isUpdating = false;
    } catch (error) {
      console.log(error);
      this.messageService.add({
        severity: 'error',
        summary: this.translateService.instant('error'),
        detail: this.translateService.instant('application.toasters.error'),
        styleClass: 'custom-toast',
      });
      throw error;
    }
  }

  onLogoImageUpload(event: any): void {
    this.logoUploadProcess = true;

    const onSuccess = (img: string, logo: any) => {
      this.newLogoPreview = img;
      this.logoUploadProcess = false;
      this.form.controls.logo.setValue(logo);
    };

    const onError = (e) => {
      if (e === 'type_size') {
        this.messageService.add({
          severity: 'error',
          summary: this.translateService.instant('error'),
          detail: this.translateService.instant(
            'application.toasters.imageTypeOrSizeRuleViolation_PNG_JPEG',
            { size: 3 },
          ),
          styleClass: 'custom-toast',
        });
        this.logoUploadInput.nativeElement.value = '';
      }
      this.logoUploadProcess = false;
    };

    this.uploadImg(event, onSuccess, onError);
  }

  private uploadImg(
    event: Event,
    onSuccess: (img: string | ArrayBuffer, logo: any) => void,
    onError: (e) => void,
  ): void {
    const input = event.target as HTMLInputElement;

    if (!input.files || input.files.length === 0) {
      return;
    }

    const imageFile = input.files[0];

    if (!IMG_FORMATS.includes(imageFile.type) || imageFile.size > 3_194_304) {
      onError('type_size');
      return;
    }
    const reader = new FileReader();
    reader.readAsDataURL(imageFile);

    reader.onloadend = () => onSuccess(reader.result, imageFile);

    reader.onerror = (error) => {
      console.log(error);
      onError(error);
    };
  }

  private generateSlug(title: string): string {
    const slug = title.trim().replace(/\s+/g, ' ').toLowerCase().split(' ').join('-');
    return slug;
  }

  private linkExistsValidator(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> =>
      this.hubsService.checkHubByLinkExists(control.value).pipe(
        debounceTime(500),
        distinctUntilChanged(),
        map((linkExists) => (linkExists ? { exists: linkExists } : null)),
      );
  }
}
