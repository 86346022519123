import { CommonModule } from '@angular/common';
import { Component, inject, OnInit, signal } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MenuItem } from 'primeng/api';

import { HubsStore } from 'src/app/core/stores';
import { FeatureHeaderComponent, FeatureMainContentComponent } from '../../../../components';

@Component({
  selector: 'app-report-container',
  standalone: true,
  imports: [CommonModule, FeatureHeaderComponent, FeatureMainContentComponent],
  templateUrl: './report-container.component.html',
  styleUrls: ['./report-container.component.scss'],
})
export class ReportContainerComponent implements OnInit {
  items = signal<MenuItem[]>(null);

  #translateService = inject(TranslateService);
  #hubsStore = inject(HubsStore);

  ngOnInit(): void {
    this.items.set([
      {
        label: this.#translateService.instant('adminReports.accountOnboarding'),
        icon: 'fa-regular fa-clipboard-check',
        routerLink: `/${this.#hubsStore.useHubUrl}/admin/reports/account-onboarding`,
      },
      {
        label: this.#translateService.instant('adminReports.accountManagement'),
        icon: 'fa-regular fa-users-gear',
        routerLink: `/${this.#hubsStore.useHubUrl}/admin/reports/account-management`,
      },
      {
        label: this.#translateService.instant('adminReports.taxRegistrations'),
        icon: 'fa-regular fa-file-lines',
        routerLink: `/${this.#hubsStore.useHubUrl}/admin/reports/tax-registrations`,
      },
      {
        label: this.#translateService.instant('adminReports.taxSettings'),
        icon: 'fa-regular fa-gears',
        routerLink: `/${this.#hubsStore.useHubUrl}/admin/reports/tax-settings`,
      },
      {
        label: this.#translateService.instant('adminReports.payments'),
        icon: 'fa-regular fa-money-bill-trend-up',
        routerLink: `/${this.#hubsStore.useHubUrl}/admin/reports/payments`,
      },
      {
        label: this.#translateService.instant('adminReports.payouts'),
        icon: 'fa-regular fa-money-bill-transfer',
        routerLink: `/${this.#hubsStore.useHubUrl}/admin/reports/payouts`,
      },
    ]);
  }
}
