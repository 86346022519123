import { Timestamp } from 'src/app/firebase';
import { IEmailSenderSettings } from '../common';

export interface IHub {
  id: string;
  _title_: string;
  title: string;
  headline: string;
  tagline: string;
  url: string;
  logoDark: any;
  logoLight: any;
  icon: any;
  banner: any;
  primaryColor: string;
  isPrivate: boolean;
  isMaintenance: boolean;
  events: boolean;
  academy: boolean;
  brands: boolean;
  isShowSidePanel: boolean;
  isHubShared: boolean;
  emailSenderSettings: IEmailSenderSettings;
  updatedAt: Timestamp;
  updatedBy: string;
  createdAt: Timestamp;
  createdBy: string;
  brand?: any;
  isSalesforceSSO?: boolean;
  isAboutPageVisible?: boolean;
  isStripeConnected?: boolean;
  stripeConnectId?: string;
  tenantId?: string;
  isStripeExpress?: boolean;
  customDomain?: string;
}
