import Player from '@vimeo/player';
import * as moment from 'moment';
import {
  AfterViewChecked,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
  inject,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Subject, concatMap, filter, firstValueFrom, from, merge, takeUntil, tap } from 'rxjs';
import { TabViewModule } from 'primeng/tabview';
import { MessageService } from 'primeng/api';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { saveAs } from 'file-saver';

import {
  IAsset,
  IChapter,
  ICourse,
  IDailyMeeting,
  IDailyMeetingParticipant,
  IUserCourse,
  IVimeoPlayerOptions,
} from 'src/app/core/models';
import { RegisteredCourseStore, UsersStore } from 'src/app/core/stores';
import {
  BrowserDefaultVideoPlayerService,
  DailyCoService,
  EmailsService,
  MixpanelService,
  CourseUserService,
  VimeoPlayerService,
} from 'src/app/core/services';
import {
  ConfirmDialogComponent,
  SharedModule,
  parseToMoment,
  pdfImg,
  zipImg,
} from 'src/app/shared';
import {
  DailyCoAssetRoomComponent,
  AssetEventBannerComponent,
  ToastComponent,
} from 'src/app/standalone/shared/components';
import { AnalyticsEventTypes, AssetType } from 'src/app/core/enums';
import { EmailCourseActionTypes, goToLink, isDesktop } from 'src/app/core/utils';
import { ChaptersSidebarTabComponent } from '../chapters-sidebar-tab/chapters-sidebar-tab.component';
import { FilesSidebarTabComponent } from '../files-sidebar-tab/files-sidebar-tab.component';
import { RegisteredCourseOverviewComponent } from '../registered-course-overview/registered-course-overview.component';
import { RegisteredCourseAssetDetailsComponent } from '../registered-course-asset-details/registered-course-asset-details.component';

@Component({
  selector: 'app-registered-course',
  standalone: true,
  imports: [
    CommonModule,
    TabViewModule,
    TranslateModule,
    RegisteredCourseOverviewComponent,
    RegisteredCourseAssetDetailsComponent,
    SharedModule,
    ChaptersSidebarTabComponent,
    FilesSidebarTabComponent,
    AssetEventBannerComponent,
    ToastComponent,
  ],
  templateUrl: './registered-course.component.html',
  styleUrls: ['./registered-course.component.scss'],
})
export class RegisteredCourseComponent implements OnInit, AfterViewChecked, OnDestroy {
  @ViewChild('iframe', { static: false }) iframe: ElementRef<HTMLIFrameElement>;

  activeAsset: IAsset;
  activeAssetFileType: 'img' | 'zip' | 'pdf' | 'vimeo' | 'internalVideo' | 'event';
  fileSrc: string | SafeResourceUrl;
  player: Player;
  browserDefaultVideoPlayer: HTMLVideoElement;
  defaultVideoPlayerId = 'registeredCourseVideoPlayerId';
  assetEventBannerContainerStyles = {
    background: '#181818',
    'border-radius': '1rem',
  };
  assetEventBannerMonthStyles = {
    'font-size': '1.375rem',
    'line-height': '1.375rem',
  };
  assetEventBannerDateStyle = {
    'font-size': '2.75rem',
    'line-height': '2.75rem',
  };
  activeMainTabIndex = 1;
  isDesktop = isDesktop();
  pdfImg = pdfImg;

  private course: ICourse;
  private unsubscribe$ = new Subject<void>();
  private emailsService = inject(EmailsService);

  constructor(
    private sanitizer: DomSanitizer,
    private vimeoPlayerService: VimeoPlayerService,
    private registeredCourseStore: RegisteredCourseStore,
    private dialogService: DialogService,
    private browserDefaultVideoPlayerService: BrowserDefaultVideoPlayerService,
    private mixpanelService: MixpanelService,
    private messageService: MessageService,
    private translateService: TranslateService,
    private courseUserService: CourseUserService,
    private usersStore: UsersStore,
    private router: Router,
    private route: ActivatedRoute,
    private dailyCoService: DailyCoService,
  ) {}

  get isUpcomingAssetEvent(): boolean {
    if (!!this.activeAsset?.eventAssetStartDate) {
      return moment().isBefore(parseToMoment(this.activeAsset.eventAssetStartDate));
    }

    return false;
  }

  get isPastAssetEvent(): boolean {
    if (!!this.activeAsset?.eventAssetStartDate) {
      return moment().isAfter(parseToMoment(this.activeAsset.eventAssetEndDate));
    }

    return false;
  }

  async ngOnInit(): Promise<void> {
    const activeAsset$ = this.registeredCourseStore.activeAsset.pipe(
      filter((asset: IAsset) => !!asset),
      tap(async () => {
        await this.vimeoPlayerService.destroy();
        this.player = null;
        await this.browserDefaultVideoPlayerService.destroy();
        this.browserDefaultVideoPlayer = null;
      }),
      tap((asset: IAsset) => {
        this.activeAsset = asset;
        this.activeMainTabIndex = 1;

        if (window.screen.width < 905) {
          const scrollableBlock: HTMLElement = document.getElementById('scrollableContent');
          scrollableBlock.scrollTo({ top: 0, behavior: 'smooth' });
        }
      }),
    );

    const activeAssetFileType$ = this.registeredCourseStore.activeAssetFileType.pipe(
      tap((assetFileType: 'img' | 'zip' | 'pdf' | 'vimeo' | 'internalVideo' | 'event') => {
        this.activeAssetFileType = assetFileType;
        switch (assetFileType) {
          case 'img':
            this.fileSrc = this.activeAsset.file;
            break;
          case 'pdf':
            this.fileSrc = this.sanitizer.bypassSecurityTrustResourceUrl(
              this.activeAsset.file as string,
            );
            break;
          case 'zip':
            this.fileSrc = zipImg;
            break;
          case 'internalVideo':
            this.fileSrc = this.activeAsset.internalVideo;
            break;
          case 'event':
            this.fileSrc = null;
            break;
          case 'vimeo':
            this.fileSrc = null;
            break;
          default:
            this.fileSrc = null;
        }
      }),
    );

    const videoPause$ = merge(
      this.vimeoPlayerService.vimeoVideoPause,
      this.browserDefaultVideoPlayerService.videoPause,
    ).pipe(
      tap(({ seconds, percent, duration }) => {
        const assetForUpdate: IAsset = {
          ...this.activeAsset,
          userCourseTracking: {
            ...this.activeAsset.userCourseTracking,
            percentageViewed: percent * 100,
            secondsViewed: seconds,
            isWatched: this.activeAsset.userCourseTracking.isWatched || percent * 100 === 100,
            videoLink:
              this.activeAsset.type === AssetType.VIDEO
                ? ((this.activeAsset.internalVideo as string) ?? this.activeAsset.videoLink)
                : null,
          },
        };
        this.registeredCourseStore.updateUserCourseTracking(assetForUpdate);
      }),
    );

    const course$ = this.registeredCourseStore.course.pipe(
      tap((course: ICourse) => {
        this.course = course;
      }),
    );

    const courseChapters$ = this.registeredCourseStore.courseChapters.pipe(
      filter((courseChapters: IChapter[]) => !!courseChapters),
      concatMap((courseChapters: IChapter[]) => {
        return from(this.checkCourseChapters(courseChapters));
      }),
    );

    merge(activeAsset$, activeAssetFileType$, videoPause$, course$, courseChapters$)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe();
  }

  ngAfterViewChecked(): void {
    if (this.activeAssetFileType === 'vimeo' && !this.player) {
      this.createVimeoPlayer();
    }

    if (this.activeAssetFileType === 'internalVideo' && !this.browserDefaultVideoPlayer) {
      this.createDefaultPlayer();
    }
  }

  onDownloadZipFile(): void {
    saveAs(this.activeAsset.file, this.activeAsset.fileName);
    this.mixpanelService.courseAssetEvent(
      this.course,
      this.activeAsset,
      AnalyticsEventTypes.COURSE_ASSET_FILE,
    );
    this.showToastMessage();
  }

  onOpenPdf(): void {
    goToLink(this.activeAsset.file as string);
  }

  async onJoin(): Promise<void> {
    const dialogRef: DynamicDialogRef = this.dialogService.open(DailyCoAssetRoomComponent, {
      width: '100vw',
      height: '100vh',
      style: {
        maxHeight: '100vh',
      },
      contentStyle: {
        width: '100%',
        height: '100%',
        overflow: 'hidden',
        padding: 0,
        borderRadius: 0,
        border: 'none',
      },
      closable: false,
      showHeader: false,
      modal: true,
      data: { asset: this.activeAsset },
    });

    await firstValueFrom(dialogRef.onClose);
    this.updateAssetTypeEvent();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private async createVimeoPlayer(): Promise<void> {
    const options: IVimeoPlayerOptions = { url: this.activeAsset.videoLink };
    this.player = this.vimeoPlayerService.createVimeoPlayer(this.iframe?.nativeElement, options);
    this.vimeoPlayerService.setCurrentTime(this.activeAsset.userCourseTracking.secondsViewed);
  }

  private createDefaultPlayer(): void {
    this.browserDefaultVideoPlayer = this.browserDefaultVideoPlayerService.startManagingVideoPlayer(
      this.defaultVideoPlayerId,
    );
    this.browserDefaultVideoPlayerService.setCurrentTime(
      this.activeAsset.userCourseTracking.secondsViewed,
    );
  }

  private async updateUserCourse(): Promise<void> {
    const userCourse: IUserCourse = await this.courseUserService.getUserCourse(
      this.course.id,
      this.usersStore.userId,
    );
    if (!userCourse.isWatched) {
      await this.courseUserService.updateCourseUser({ ...userCourse, isWatched: true });
      await this.emailsService.sendCourseEmail(
        userCourse.courseId,
        this.usersStore.user.id,
        EmailCourseActionTypes.COURSE_COMPLETION,
      );
      const dialogRef: DynamicDialogRef = this.dialogService.open(ConfirmDialogComponent, {
        closable: false,
        styleClass: 'confirm-dialog',
        data: {
          titleKey: 'confirmDialog.completedCourse.title',
          descriptionKey: 'confirmDialog.completedCourse.description',
          confirmBtnKey: 'confirmDialog.completedCourse.confirmBtn',
          cancelBtnKey: 'confirmDialog.completedCourse.cancelBtn',
        },
      });
      const result: 'cancel' | 'confirm' = await firstValueFrom(dialogRef.onClose);
      if (result === 'confirm') {
        this.router.navigate(['../filter/all'], { relativeTo: this.route });
      }
    }
  }

  private showToastMessage(): void {
    this.messageService.add({
      severity: 'info',
      detail: this.translateService.instant('courseRegisteredPage.downloadAlert'),
      styleClass: 'custom-toast',
    });
  }

  private async updateAssetTypeEvent(): Promise<void> {
    const { data } = await firstValueFrom(
      this.dailyCoService.getAllSessionsForSpecificDailyRoom(
        this.activeAsset.eventAssetMeetingRoomName,
      ),
    );
    const numberOfSecondsWhenUserWasInTheRoom = data
      .map((dailyMeeting: IDailyMeeting) => dailyMeeting?.participants)
      .flat()
      .filter(
        (meetingParticipant: IDailyMeetingParticipant) =>
          meetingParticipant.user_id === this.usersStore.userId,
      )
      .reduce((acc: number, curr: IDailyMeetingParticipant) => acc + curr.duration, 0);

    const startDate = parseToMoment(this.activeAsset.eventAssetStartDate);
    const endDate = parseToMoment(this.activeAsset.eventAssetEndDate);
    const eventAssetDurationInMilliseconds = endDate.diff(startDate);
    const eventAssetDurationInSeconds = Math.floor(eventAssetDurationInMilliseconds / 1000);
    const percentageOfAssetViewed =
      (numberOfSecondsWhenUserWasInTheRoom / eventAssetDurationInSeconds) * 100;
    const preparedPercentageForSave = this.formatPercentage(percentageOfAssetViewed);

    const assetForUpdate: IAsset = {
      ...this.activeAsset,
      userCourseTracking: {
        ...this.activeAsset.userCourseTracking,
        percentageViewed: preparedPercentageForSave,
        isWatched: preparedPercentageForSave === 100,
        secondsViewed: numberOfSecondsWhenUserWasInTheRoom,
      },
    };
    this.registeredCourseStore.updateUserCourseTracking(assetForUpdate);
  }

  private formatPercentage(percentage: number) {
    let formattedPercentage: number;

    if (percentage < 1) {
      formattedPercentage = Number(percentage.toFixed(2));
    } else if (percentage <= 100) {
      formattedPercentage = Math.round(percentage);
    } else {
      formattedPercentage = 100;
    }

    return formattedPercentage;
  }

  private async sendEmailAfterChapterCompleted(courseChapters: IChapter[]): Promise<void> {
    let userCourse: IUserCourse = await this.courseUserService.getUserCourse(
      this.course.id,
      this.usersStore.userId,
    );
    await Promise.all([
      ...courseChapters.map(async (chapter: IChapter) => {
        if (chapter.isSendEmailAfterChapterCompleted) {
          const isUserCompletedThisChapterBefore = userCourse.completedChapters.includes(
            chapter.id,
          );
          if (isUserCompletedThisChapterBefore) {
            return chapter;
          }
          const isAllAssetsInChapterWatched = chapter.assetsFullModel.every(
            (asset: IAsset) => asset.userCourseTracking.isWatched,
          );
          if (isAllAssetsInChapterWatched && !isUserCompletedThisChapterBefore) {
            await this.emailsService.sendCourseEmail(
              this.course.id,
              this.usersStore.user.id,
              EmailCourseActionTypes.COURSE_CHAPTER_COMPLETION,
            );
            userCourse = await this.courseUserService.updateCourseUser({
              ...userCourse,
              completedChapters: [...userCourse.completedChapters, chapter.id],
            });
          }
        }

        return chapter;
      }),
    ]);
  }

  private async checkCourseChapters(courseChapters: IChapter[]): Promise<void> {
    const allAssetsInCourse: IAsset[] = courseChapters
      .map((chapter: IChapter) => chapter.assetsFullModel)
      .flat();

    await this.sendEmailAfterChapterCompleted(courseChapters);
    const firstNotWatchedAsset: IAsset = allAssetsInCourse.find(
      (asset: IAsset) => !asset.userCourseTracking.isWatched,
    );

    if (!firstNotWatchedAsset && allAssetsInCourse.length) {
      await this.updateUserCourse();
    }
  }
}
