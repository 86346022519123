<div class="container">
  <header class="header">
    <app-button
      [buttonType]="buttonType().ROUND"
      [buttonSize]="buttonSize().MEDIUM"
      [buttonStyle]="buttonStyle().GHOST_PRIMARY"
      buttonRoundIcon="fa-regular fa-chevron-left"
      (click)="onNavigateToSubscribersOrInstructorsList()" />
    <h2 class="user-name">{{ user?.firstName }} {{ user?.lastName }}</h2>
  </header>
  <aside class="aside">
    <app-course-subscriber-instructor-sidebar />
  </aside>
  <main class="main">
    <router-outlet></router-outlet>
  </main>
</div>
