import { Injectable, signal } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DashboardStore {
  #isShowGetStartedPageSignal = signal(true);
  isShowGetStartedPageSignal = this.#isShowGetStartedPageSignal.asReadonly();

  changeShowingGetStartedPage(value: boolean): void {
    if (value === this.#isShowGetStartedPageSignal()) {
      return;
    }
    this.#isShowGetStartedPageSignal.set(value);
  }
}
