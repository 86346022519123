import { Component, inject, OnDestroy } from '@angular/core';

import { DashboardStore } from 'src/app/standalone/shared';
import { GetStartedComponent } from '../get-started/get-started.component';
import { DashboardAnalyticsComponent } from '../dashboard-analytics/dashboard-analytics.component';

@Component({
  selector: 'app-admin-dashboard',
  standalone: true,
  imports: [GetStartedComponent, DashboardAnalyticsComponent],
  templateUrl: './admin-dashboard.component.html',
  styleUrl: './admin-dashboard.component.scss',
})
export class AdminDashboardComponent implements OnDestroy {
  #dashboardStore = inject(DashboardStore);

  isShowGetStartedPageSignal = this.#dashboardStore.isShowGetStartedPageSignal;

  ngOnDestroy(): void {
    this.#dashboardStore.changeShowingGetStartedPage(true);
  }
}
