import { Component, inject, OnInit, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { DividerModule } from 'primeng/divider';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

import {
  StatCardComponent,
  StatChartComponent,
  StatSingleProgressBarComponent,
} from 'src/app/standalone/shared/components/stats';
import { IStatCard, IStatChart } from 'src/app/standalone/shared/models';
import {
  getMonthsFromToday,
  getPeriodOfWeekFromToday,
  getWeeksFromToday,
} from 'src/app/standalone/shared';

@Component({
  selector: 'app-dashboard-analytics',
  standalone: true,
  imports: [
    StatCardComponent,
    DividerModule,
    StatChartComponent,
    StatSingleProgressBarComponent,
    CommonModule,
    TranslateModule,
  ],
  templateUrl: './dashboard-analytics.component.html',
  styleUrl: './dashboard-analytics.component.scss',
})
export class DashboardAnalyticsComponent implements OnInit {
  stats = signal<IStatCard[]>(null);
  charts = signal<IStatChart[]>(null);

  #route = inject(ActivatedRoute);
  #translateService = inject(TranslateService);

  ngOnInit(): void {
    this.initStats();
    this.initCharts();
  }

  private initStats(): void {
    this.stats.set([
      {
        iconClass: 'fa-regular fa-users',
        text: 'adminDashboardAnalytics.totalUsersStat',
        value: `${this.#route.snapshot.data['totalNumberOfUsersInHub']}`,
        isShowTooltip: true,
        tooltipText: this.#translateService.instant('adminDashboardAnalytics.totalUsersTooltip'),
      },
      {
        iconClass: 'fa-regular fa-money-bill-trend-up',
        text: 'adminDashboardAnalytics.userRegistrationGrowthStat',
        value: `${this.#route.snapshot.data['userRegistrationGrowth']}%`,
        isShowTooltip: true,
        tooltipText: this.#translateService.instant(
          'adminDashboardAnalytics.userRegistrationGrowthTooltip',
        ),
      },
      {
        iconClass: 'fa-regular fa-calendar-circle-user',
        text: 'adminDashboardAnalytics.currentWeekUserRegistrationsStat',
        value: `${this.#route.snapshot.data['currentWeekUserRegistrations']}`,
        isShowTooltip: true,
        tooltipText: this.#translateService.instant(
          'adminDashboardAnalytics.currentWeekUserRegistrationsTooltip',
        ),
      },
      {
        iconClass: 'fa-regular fa-calendar-circle-plus',
        text: 'adminDashboardAnalytics.currentMonthUserRegistrationsStat',
        value: `${this.#route.snapshot.data['currentMonthUserRegistrations']}`,
        isShowTooltip: true,
        tooltipText: this.#translateService.instant(
          'adminDashboardAnalytics.currentMonthUserRegistrationsTooltip',
        ),
      },
    ]);
  }

  private initCharts(): void {
    const documentStyle = getComputedStyle(document.documentElement);
    this.charts.set([
      {
        statName: this.#translateService.instant(
          'adminDashboardAnalytics.userRegistrationsByMonthGraph',
        ),
        type: 'line',
        data: {
          labels: getMonthsFromToday(6).map((month: string) =>
            this.#translateService.instant(month),
          ),
          datasets: [
            {
              data: this.#route.snapshot.data['userRegistrationsByMonth'],
              fill: false,
              tension: 0.5,
              borderColor: documentStyle.getPropertyValue('--brand-500'),
              pointRadius: 3,
              pointBackgroundColor: 'transparent',
              pointBorderColor: documentStyle.getPropertyValue('--brand-500'),
            },
          ],
        },
        options: {
          maintainAspectRatio: false,
          scales: {
            y: {
              beginAtZero: true,
              title: {
                display: true,
                text: this.#translateService.instant('adminDashboardAnalytics.graphAxisYTitle'),
              },
            },
          },
        },
        chartStyles: { height: '19rem' },
        chartLegend: false,
        isShowTooltip: true,
        tooltipText: this.#translateService.instant(
          'adminDashboardAnalytics.userRegistrationsByMonthGraphTooltip',
        ),
      },
      {
        statName: this.#translateService.instant(
          'adminDashboardAnalytics.userRegistrationsByWeekGraph',
        ),
        type: 'bar',
        data: {
          labels: getWeeksFromToday(6).map(
            (weekNumber: number) =>
              `${this.#translateService.instant('shared.week')} ${weekNumber}`,
          ),
          datasets: [
            {
              data: this.#route.snapshot.data['userRegistrationsByWeek'],
              borderColor: documentStyle.getPropertyValue('--brand-500'),
              backgroundColor: documentStyle.getPropertyValue('--brand-500'),
            },
          ],
        },
        options: {
          maintainAspectRatio: false,
          scales: {
            y: {
              beginAtZero: true,
              title: {
                display: true,
                text: this.#translateService.instant('adminDashboardAnalytics.graphAxisYTitle'),
              },
            },
          },
          plugins: {
            tooltip: {
              callbacks: {
                title: (tooltipItems) => {
                  const weekIndex = tooltipItems[0].dataIndex;
                  const weekDates = getPeriodOfWeekFromToday(6);

                  return weekDates[weekIndex];
                },
              },
            },
          },
        },
        chartStyles: { height: '19rem' },
        chartLegend: false,
        isShowTooltip: true,
        tooltipText: this.#translateService.instant(
          'adminDashboardAnalytics.userRegistrationsByWeekGraphTooltip',
        ),
      },
    ]);
  }
}
