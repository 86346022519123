import * as dayjs from 'dayjs';
import { Component, inject, OnDestroy, OnInit, signal } from '@angular/core';
import {
  AbstractControl,
  AsyncValidatorFn,
  FormGroup,
  UntypedFormBuilder,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { merge, Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, takeUntil, tap } from 'rxjs/operators';
import { ClipboardService } from 'ngx-clipboard';
import { MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { TranslateService } from '@ngx-translate/core';

import { IHub, ITag, ICourse, IHubTag, IUser, IUserHub } from 'src/app/core/models';
import {
  HubsService,
  TagsService,
  CoursesService,
  FormService,
  UsersService,
  UserHubsService,
} from 'src/app/core/services';
import { CoursesStore, HubsStore, UsersStore } from 'src/app/core/stores';
import { asyncDelay, validNumbers } from 'src/app/core/utils';
import { Timestamp } from 'src/app/firebase';
import { SharedModule } from 'src/app/shared';
import { CurrencyCode } from 'src/app/core/enums';
import {
  ButtonComponent,
  ButtonStyle,
  combineDateAndTime,
  ManageTranslationsComponent,
  SaveDiscardActionsComponent,
} from 'src/app/standalone/shared';

@Component({
  selector: 'app-course-basic-info',
  standalone: true,
  imports: [SharedModule, SaveDiscardActionsComponent, ButtonComponent],
  templateUrl: './course-basic-info.component.html',
  styleUrl: './course-basic-info.component.scss',
})
export class CourseBasicInfoComponent implements OnInit, OnDestroy {
  loading = signal(true);
  form: FormGroup;
  hubs = signal<IHub[]>(null);
  usersCanBeOwners = signal<IUser[]>(null);
  possibleTags = signal<ITag[]>(null);
  selectedTags = signal<string[]>([]);
  isUpdating = signal(false);
  minDate = signal<Date>(new Date());
  usersLoading = signal(true);
  copyIsClicked = signal(false);
  currencies = signal<string[]>(Object.keys(CurrencyCode));
  linkPrefix = signal<string>(null);
  currentDate = signal<string>(dayjs().format('DD/MM/YYYY'));
  buttonStyle = signal<typeof ButtonStyle>(ButtonStyle);
  supportedLangs = signal<string[]>(null);

  #unsubscribe$ = new Subject<void>();

  #fb = inject(UntypedFormBuilder);
  #coursesService = inject(CoursesService);
  #coursesStore = inject(CoursesStore);
  #hubsService = inject(HubsService);
  #usersService = inject(UsersService);
  #hubsStore = inject(HubsStore);
  #tagsService = inject(TagsService);
  #clipboardService = inject(ClipboardService);
  #messageService = inject(MessageService);
  #usersStore = inject(UsersStore);
  #translateService = inject(TranslateService);
  #formService = inject(FormService);
  #userHubsService = inject(UserHubsService);
  #dialogService = inject(DialogService);

  get titleErrorMessage(): string {
    if (this.form.controls.title.errors?.maxlength) {
      return this.#translateService.instant('application.forms.msxLengthErrorText');
    }

    return this.#translateService.instant('application.forms.required');
  }

  get linkErrorMessage(): string {
    if (this.form.controls.link.errors?.maxlength) {
      return this.#translateService.instant('application.forms.msxLengthErrorText');
    }

    if (this.form.controls.link.errors?.pattern) {
      return this.#translateService.instant('adminCourseSettings.eventLinkInvalidPattern');
    }

    return this.#translateService.instant('application.forms.required');
  }

  get canUpdate(): boolean {
    return (
      !this.isUpdating() && this.form.valid && this.form.dirty && this.#formService.isValueChanged()
    );
  }

  get course(): ICourse {
    return this.#coursesStore.adminCourse;
  }

  get tooltipText(): string {
    return !this.copyIsClicked()
      ? this.#translateService.instant('copyTooltip.hover')
      : this.#translateService.instant('copyTooltip.action');
  }

  get hub(): IHub {
    return this.#hubsStore.hub;
  }

  get priceErrorMessage(): string {
    if (this.form.controls.price.errors?.pattern) {
      return 'Enter a valid price';
    }

    return this.#translateService.instant('application.forms.required');
  }

  async ngOnInit(): Promise<void> {
    this.supportedLangs.set(this.#translateService.langs);
    this.linkPrefix.set(
      this.#hubsStore.hub
        ? `${this.#hubsStore.environmentBaseUrl}/${this.#hubsStore.useHubUrl}/courses/`
        : this.#hubsStore.environmentBaseUrl + '/courses/',
    );
    this.createForm();
    this.updateForm();
    this.#formService.setForm(this.form);

    const hubs: IHub[] = await this.#hubsService.getAll();
    this.hubs.set(hubs);

    const ownersAndAdminsUserHubs: IUserHub[] =
      await this.#userHubsService.getOwnersAndAdminsUserHubs(this.hub.id);
    const ownersAndAdminsUserHubIds: string[] = ownersAndAdminsUserHubs.map(
      (item: IUserHub) => item.userId,
    );
    const usersCanBeOwners: IUser[] =
      await this.#usersService.getUserByIds(ownersAndAdminsUserHubIds);
    this.usersCanBeOwners.set(usersCanBeOwners);
    this.usersLoading.set(false);

    this.getTags();
    this.loading.set(false);

    const startDateValue$ = this.form.controls.start.valueChanges;
    const startTimeValue$ = this.form.controls.startTime.valueChanges;
    const endDateValue$ = this.form.controls.end.valueChanges;
    const endTimeValue$ = this.form.controls.endTime.valueChanges;

    merge(startDateValue$, startTimeValue$, endDateValue$, endTimeValue$)
      .pipe(
        debounceTime(300),
        filter((value) => !!value),
        tap(() => {
          const start = combineDateAndTime(
            this.form.controls.start.value,
            this.form.controls.startTime.value,
          );
          const end = combineDateAndTime(
            this.form.controls.end.value,
            this.form.controls.endTime.value,
          );

          if (dayjs(this.minDate()).isAfter(start)) {
            this.form.controls.start.setValue(this.minDate(), { emitEvent: false });
            this.form.controls.startTime.setValue(this.minDate(), { emitEvent: false });
          }

          if (dayjs(start).isAfter(end)) {
            const validEnd = dayjs(start).add(1, 'hours');
            this.form.controls.startTime.setValue(this.form.controls.start.value, {
              emitEvent: false,
            });
            this.form.controls.end.setValue(validEnd.toDate(), { emitEvent: false });
            this.form.controls.endTime.setValue(validEnd.toDate(), { emitEvent: false });
          }
        }),
        takeUntil(this.#unsubscribe$),
      )
      .subscribe();

    this.form.controls.isDisplayedPrice.valueChanges
      .pipe(
        tap((value: boolean) => {
          if (value) {
            this.form.controls.price.addValidators([Validators.required, validNumbers()]);
          } else {
            this.form.controls.price.setValue(null);
            this.form.controls.strikePrice.setValue(null);
            this.form.controls.price.removeValidators([Validators.required, validNumbers()]);
            this.form.controls.price.reset();
            this.form.controls.strikePrice.reset();
          }
        }),
        takeUntil(this.#unsubscribe$),
      )
      .subscribe();
  }

  copyToClipboard(): void {
    this.#clipboardService.copy(`${this.linkPrefix()}${this.form.controls.link.value}`);
    this.copyIsClicked.set(true);
  }

  onSelectTags(tagIds: string[]): void {
    this.selectedTags.set(tagIds);
    this.form.controls.tags.patchValue(tagIds);
    this.makeFormControlDirty('tags');
  }

  linkExistsValidator(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> =>
      this.#coursesService.checkCourseByLinkExists(control.value, this.course.id).pipe(
        debounceTime(500),
        distinctUntilChanged(),
        map((linkExists) => (linkExists ? { exists: linkExists } : null)),
      );
  }

  onDiscard(): void {
    this.updateForm();
  }

  async onConfirm(): Promise<void> {
    try {
      this.isUpdating.set(true);
      await asyncDelay(1);
      await this.saveSettings();
      this.#formService.setForm(this.form);
    } catch (error) {
      console.error(error);
    }
  }

  onOpenManageTranslationsDialog(): void {
    this.#dialogService.open(ManageTranslationsComponent, {
      closable: false,
      styleClass: 'reset-dialog-styles',
    });
  }

  ngOnDestroy(): void {
    this.#unsubscribe$.next();
    this.#unsubscribe$.complete();
  }

  private createForm(): void {
    this.form = this.#fb.group({
      title: [null, [Validators.required, Validators.maxLength(100)]],
      accountingNumber: null,
      tagline: [null, Validators.maxLength(120)],
      link: [
        null,
        [
          Validators.required,
          Validators.pattern(/^\S+$/),
          Validators.pattern(/^((?!http:|www\.|https:|\/|\\).)*$/),
        ],
        [this.linkExistsValidator()],
      ],
      hubId: null,
      ownerId: [null, Validators.required],
      start: null,
      currency: [{ value: null, disabled: true }],
      startTime: null,
      end: null,
      endTime: null,
      tags: null,
      featured: false,
      isDisplayedPrice: false,
      price: null,
      strikePrice: null,
      supportedLanguages: null,
    });
  }

  private async updateForm(): Promise<void> {
    this.form.patchValue({
      ...this.course,
      start: this.course?.start ? this.course.start?.toDate() : null,
      startTime: this.course?.start ? this.course.start?.toDate() : null,
      end: this.course?.end ? this.course.end?.toDate() : null,
      endTime: this.course?.end ? this.course.end?.toDate() : null,
    });

    const shouldHideCurrency = await this.#coursesService.hasAnyTicketOrCoupon(
      this.#coursesStore.adminCourse.id,
    );
    if (!shouldHideCurrency) {
      this.form.get('currency').enable();
    }

    if (this.form.controls.isDisplayedPrice.value) {
      this.form.controls.price.addValidators([Validators.required, validNumbers()]);
    }
  }

  private async getTags(): Promise<void> {
    const hubTags: IHubTag[] = await this.#hubsService.getTagsRelation(this.#hubsStore.hubId);
    const hubTagIds: string[] = hubTags.map((hubTag) => hubTag.tagId);
    const tags: ITag[] = await this.#tagsService.getByIdsOrderedByTitle(hubTagIds);
    this.possibleTags.set(tags);
    this.selectedTags.set(this.course.tags);
  }

  private makeFormControlDirty(formControlName: string): void {
    this.form.controls[formControlName].markAsDirty();
  }

  private async saveSettings(): Promise<void> {
    this.isUpdating.set(true);

    const {
      start,
      startTime,
      end,
      endTime,
      title,
      accountingNumber,
      currency,
      updatedAt,
      updatedBy,
      ...others
    } = this.form.getRawValue();
    const dateStart: Date = combineDateAndTime(start, startTime);
    const dateEnd: Date = combineDateAndTime(end, endTime);

    const updatedCourse: ICourse = {
      ...this.course,
      ...others,
      title,
      accountingNumber,
      currency,
      _title_: title.toLowerCase(),
      start: start ? Timestamp.fromMillis(dateStart.getTime()) : null,
      end: end ? Timestamp.fromMillis(dateEnd.getTime()) : null,
      startTime: null,
      endTime: null,
      updatedAt: Timestamp.now(),
      updatedBy: this.#usersStore.userId,
    };

    try {
      await this.#coursesService.update(this.course.id, updatedCourse);
      this.showToastMessage('success', 'adminCourseSettings.courseSuccessfullyUpdated');
    } catch (error) {
      console.warn(error);
      this.showToastMessage('error', 'application.toasters.error');
    } finally {
      this.isUpdating.set(false);
    }
  }

  private showToastMessage(severity: 'success' | 'error', detail: string): void {
    this.#messageService.add({
      severity,
      summary: this.#translateService.instant(severity),
      detail: this.#translateService.instant(detail),
      styleClass: 'custom-toast',
    });
  }
}
