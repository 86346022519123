import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MessageService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { DynamicDialogRef } from 'primeng/dynamicdialog';

import { ICourse, ITag, IUser } from 'src/app/core/models';
import { CoursesService, CourseUserService, UsersService } from 'src/app/core/services';
import { CoursesStore, TagsStore } from 'src/app/core/stores';
import { AppStore } from 'src/app/app.store';
import { IAdminTableColumn } from 'src/app/shared';
import { ButtonSize } from 'src/app/standalone';

@Component({
  templateUrl: './attach-subscribers-dialog.component.html',
  styleUrls: ['./attach-subscribers-dialog.component.scss'],
})
export class AttachSubscribersDialogComponent implements OnInit {
  @ViewChild('filterSearch') filterSearch: ElementRef<HTMLInputElement>;

  attachLoading = false;
  loadingEntries = true;
  entries: IUser[] = [];
  selectedEntries: IUser[] = [];
  totalRecords = 0;
  pageSize = 10;
  tags: ITag[] = [];
  selectedTags: ITag[] = [];
  columns: IAdminTableColumn[] = [
    { field: 'firstName', titleTranslationKey: 'adminUsers.tableColFirstName' },
    { field: 'lastName', titleTranslationKey: 'adminUsers.tableColLastName' },
    { field: 'email', titleTranslationKey: 'adminUsers.tableColEmail' },
    { field: 'company', titleTranslationKey: 'adminUsers.tableColCompany' },
    { field: 'position', titleTranslationKey: 'adminUsers.tableColPosition' },
  ];
  buttonSize = ButtonSize;

  private attendeeSearchTerm = '';
  private tagSearchTerm = '';
  private page = 0;

  get filteredTags(): ITag[] {
    return this.tags.filter((tag) =>
      tag.title.toLowerCase().includes(this.tagSearchTerm.toLowerCase()),
    );
  }

  get course(): ICourse {
    return this.courseStore.adminCourse;
  }

  constructor(
    private courseStore: CoursesStore,
    private tagStore: TagsStore,
    private courseService: CoursesService,
    private messageService: MessageService,
    private translateService: TranslateService,
    private usersService: UsersService,
    private courseUserService: CourseUserService,
    public appStore: AppStore,
    private ref: DynamicDialogRef,
  ) {}

  async ngOnInit(): Promise<void> {
    await Promise.all([this.fetchSubscribers()]);
    this.tags = this.tagStore.tags.filter((tag) => this.course?.tags?.includes(tag.id));
    this.loadingEntries = false;
  }

  tagSearchChanged(value: string): void {
    this.tagSearchTerm = value;
  }

  async subscriberSearchChanged(value: string): Promise<void> {
    this.loadingEntries = true;
    this.attendeeSearchTerm = value;
    await this.fetchSubscribers();
    this.loadingEntries = false;
  }

  async fetchEntries(event: any): Promise<void> {
    this.loadingEntries = true;
    this.pageSize = event.rows;
    this.page = event.first / this.pageSize;
    await this.fetchSubscribers();
    this.loadingEntries = false;
  }

  async attach(): Promise<void> {
    this.attachLoading = true;
    await this.selectedEntries.forEach(
      async (user) =>
        await this.courseService.addUserToCourse(this.course, user, 'subscriber', true),
    );

    this.messageService.add({
      key: 'user',
      severity: 'success',
      summary: this.translateService.instant('success'),
      detail: this.translateService.instant('adminEvents.userSuccessfullyAttached', {
        count: this.selectedEntries.length,
      }),
      styleClass: 'custom-toast',
    });
    this.attachLoading = false;
    this.selectedEntries = [];
    await this.fetchSubscribers();
  }

  async tagSelectionChanged(tags: ITag[]): Promise<void> {
    this.selectedTags = tags;
    await this.fetchSubscribers();
  }

  onCloseDialog(): void {
    this.ref.close();
  }

  private async fetchSubscribers(): Promise<void> {
    const response = await this.courseUserService.getUnattachedUsersFromAlgolia(
      this.course.hubId,
      this.course.id,
      this.page,
      this.pageSize,
      [...this.selectedTags.map((t) => t.id), this.attendeeSearchTerm].join(' '),
    );

    if (this.appStore.generalSystemSettings.enableEncryption) {
      console.log(`ALGOLIA attach-subscribers-dialog fetchAttendees res.results`, response.results);
      response.results = response.results.map((b) => {
        console.log('res', b);
        return this.usersService.decryptUserData(b, ['_highlightResult', 'objectID']) as IUser;
      });
      console.log(`↑ ALGOLIA attach-subscribers-dialog fetchAttendees decrypted`);
    }

    this.entries = response.results;
    this.totalRecords = response.total;
  }

  onClearSubscribersSearch(): void {
    this.filterSearch.nativeElement.value = '';
    this.subscriberSearchChanged('');
  }
}
