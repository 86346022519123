<ng-container *ngIf="loading">
  <app-loader></app-loader>
</ng-container>

<div
  *ngIf="!loading"
  class="system-translations-container">
  <div class="admin-system-translations-table-container">
    <div class="c-mb-4">
      <admin-table-header-navbar [title]="'adminSystemTranslations.title' | translate">
        <app-button
          buttonText="{{ 'adminSystemTranslations.addBtnLabel' | translate }}"
          buttonIconLeft="far fa-plus"
          [buttonSize]="buttonSize.MEDIUM"
          (click)="add()" />
      </admin-table-header-navbar>
    </div>

    <admin-table-filter-navbar
      (onSearchTermChange)="searchValue = $event"></admin-table-filter-navbar>

    <admin-table
      [loading]="loadingEntries"
      [columns]="columns"
      [entries]="entries"
      [paginator]="true"
      [lazy]="false"
      [emptyStateKey]="'adminSystemTranslations.emptyState'"
      [emptyStateIcon]="'far fa-tag'"
      [searchValue]="searchValue"
      [searchFilterFields]="searchFilterFields">
      <ng-template
        let-entry
        epTemplateKey="actionsCell">
        <div class="table-btn-actions-container">
          <app-button
            [buttonType]="buttonType.ROUND"
            [buttonSize]="buttonSize.MEDIUM"
            [buttonStyle]="buttonStyle.FILLED_SECONDARY"
            buttonRoundIcon="far fa-pen-to-square"
            (click)="edit($event, entry)" />
          <app-button
            [buttonType]="buttonType.ROUND"
            [buttonSize]="buttonSize.MEDIUM"
            [buttonStyle]="buttonStyle.FILLED_DANGER"
            buttonRoundIcon="far fa-trash-can"
            (click)="remove($event, entry)" />
        </div>
      </ng-template>
    </admin-table>
  </div>
</div>
