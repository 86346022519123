<div class="container">
  <header class="header">
    <h1 class="title">{{ pageTitle() | translate }}</h1>
    <app-cross-icon (click)="onCloseDialog()" />
  </header>
  <main>
    <form
      [formGroup]="form"
      class="form">
      <app-input-label
        labelType="required"
        for="title-input"
        text="tagComponent.titleLabel">
      </app-input-label>
      <input
        class="custom-input-text custom-input-text-required"
        formControlName="title"
        id="title-input"
        pInputText
        type="text"
        placeholder="{{ 'tagComponent.titleLabel' | translate }}" />
      <app-input-error-message
        [showMessage]="form.controls.title.touched && form.controls.title.hasError('required')"
        errorMessage="{{ 'tagComponent.titleReq' | translate }}">
      </app-input-error-message>
    </form>
  </main>
  <div class="footer c-p-2 c-pl-5">
    <div class="col text-left form-field-input-checkbox-block c-p-0">
      <p-checkbox
        class="custom-input-checkbox-sm"
        binary="true"
        [formControl]="createAnotherControl"
        inputId="another-user-input">
      </p-checkbox>
      <app-input-label
        labelType="switch-checkbox"
        for="another-user-input"
        text="tagComponent.addAnotherTag">
      </app-input-label>
    </div>
    <div class="col text-right flex justify-content-end align-items-center">
      <app-button
        buttonText="{{ 'shared.cancel' | translate }}"
        buttonIconLeft="fa-regular fa-xmark"
        [buttonStyle]="buttonStyle().GHOST_PRIMARY"
        [buttonSize]="buttonSize().MEDIUM"
        (click)="onCloseDialog()" />
      <app-button
        class="c-ml-2"
        buttonText="{{ 'shared.save' | translate }}"
        buttonIconLeft="far fa-check"
        [buttonSize]="buttonSize().MEDIUM"
        [loading]="isUpdating()"
        [disabled]="!canUpdate"
        (click)="create()" />
    </div>
  </div>
</div>
