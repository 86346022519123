<div class="container">
  <div class="title">
    <span class="stat-name">{{ statName() | translate }}</span>
    @if (isShowTooltip()) {
      <i
        class="fa-regular fa-circle-info info"
        pTooltip="{{ tooltipText() | translate }}"
        [tooltipPosition]="tooltipPosition()"
        [tooltipStyleClass]="tooltipStyleClass()"
        [tooltipEvent]="tooltipEvent()"
        [tabIndex]="tabIndex()">
      </i>
    }
  </div>
  <div [ngStyle]="chartStyles()">
    <canvas
      baseChart
      [type]="chartType()"
      [data]="chartData()"
      [options]="chartOptions()"
      [legend]="chartLegend()">
    </canvas>
  </div>
</div>
