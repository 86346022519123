import { Component, computed, OnInit, signal } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { take } from 'rxjs/operators';
import { MessageService } from 'primeng/api';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { TranslateService } from '@ngx-translate/core';

import {
  IAdminTableFilter,
  IAdminTableFilterChange,
  imgPreloader,
  logoAvatar,
  ConfirmDialogComponent,
  IAdminTableColumn,
} from 'src/app/shared';
import { CoursesService, HubsService, TagsService, UserHubsService } from 'src/app/core/services';
import { CoursesStore, HubsStore, UsersStore } from 'src/app/core/stores';
import { ICourse, ITag, IBrand, IUserHub, UserHubRoles, IUser, IHub } from 'src/app/core/models';
import { AppStore } from 'src/app/app.store';
import { NewCourseComponent } from 'src/app/admin';
import { ButtonSize, ButtonStyle, ButtonType } from 'src/app/standalone';

@Component({
  selector: 'app-courses-list',
  templateUrl: './courses-list.component.html',
  styleUrls: ['./courses-list.component.scss'],
})
export class CoursesListComponent implements OnInit {
  loading = true;
  imgPreloader = imgPreloader;
  logoAvatar = logoAvatar;
  loadingEntries = true;
  columns: IAdminTableColumn[] = [
    { field: 'title', template: 'titleCell', titleTranslationKey: 'adminCourseList.tableTitle' },
    { field: 'isPublished', titleTranslationKey: 'adminCourseList.status', template: 'statusCell' },
    { field: 'actions', template: 'actionsCell' },
  ];
  entries: ICourse[] = [];
  totalRecords = 0;
  pageSize = 25;
  brands: IBrand[] = [];
  selectedBrands: string[] = [];
  assetBrands: IBrand[] = [];
  tags: ITag[] = [];
  tableFilters: IAdminTableFilter[] = [];
  buttonSize = ButtonSize;
  buttonType = ButtonType;
  buttonStyle = ButtonStyle;
  currentUserHub = signal<IUserHub>(null);
  canCreateNewCourse = computed(() => {
    const userHub: IUserHub = this.currentUserHub();
    if (!userHub) {
      return null;
    }

    return userHub.role === UserHubRoles.ADMIN || userHub.role === UserHubRoles.OWNER;
  });

  private selectedTags: string[] = [];
  private searchTerm = '';

  constructor(
    public hubsStore: HubsStore,
    public courseStore: CoursesStore,
    private courseService: CoursesService,
    private hubsService: HubsService,
    private router: Router,
    private route: ActivatedRoute,
    private messageService: MessageService,
    private dialogService: DialogService,
    private tagsService: TagsService,
    private translateService: TranslateService,
    private usersStore: UsersStore,
    public appStore: AppStore,
    private userHubsService: UserHubsService,
  ) {}

  get user(): IUser {
    return this.usersStore.user;
  }

  get hub(): IHub {
    return this.hubsStore.hub;
  }

  async ngOnInit(): Promise<void> {
    const userHub: IUserHub = await this.userHubsService.getUserHub(this.hub.id, this.user.id);
    this.currentUserHub.set(userHub);
    await this.fetchCourses(0);
    const hubTags = await this.hubsService.getTagsRelation(this.hubsStore.hubId);
    const tagsIds = hubTags.map((t) => t.tagId);
    this.tags = await this.tagsService.getByIdsOrderedByTitle(tagsIds);
    this.loadingEntries = false;
    this.loading = false;

    this.tableFilters = [
      {
        key: 'TAGS',
        label: 'adminCourseList.tags',
        options: this.tags.map((t) => ({ label: t.title, value: t.id })),
      },
    ];
  }

  async onSearchTermChange(e: string): Promise<void> {
    this.loadingEntries = true;
    this.searchTerm = e;
    await this.fetchCourses(0);
    this.loadingEntries = false;
  }

  async fetchEntries(event: any): Promise<void> {
    this.loadingEntries = true;
    this.pageSize = event.rows;
    await this.fetchCourses(event.first / this.pageSize);
    this.loadingEntries = false;
  }

  removeEntry(entry: ICourse): void {
    this.entries.splice(this.entries.indexOf(entry), 1);
    this.totalRecords -= 1;
  }

  async filtersChange(e: IAdminTableFilterChange[]): Promise<void> {
    this.loadingEntries = true;
    this.selectedTags = e.filter((f) => f.filterKey === 'TAGS').map((f) => f.optionKey);
    this.selectedBrands = e.filter((f) => f.filterKey === 'BRANDS').map((f) => f.optionKey);
    await this.fetchCourses(0);
    this.loadingEntries = false;
  }

  async openNewCourseDialog(): Promise<void> {
    const newCourseDialogRef: DynamicDialogRef = this.dialogService.open(NewCourseComponent, {
      width: '80vw',
      height: '80vh',
      closable: false,
      styleClass: 'attach-new-course-dialog',
    });

    const payload: ICourse = await firstValueFrom(newCourseDialogRef.onClose);
    if (payload) {
      this.router.navigate([`/${this.hubsStore.useHubUrl}/admin/courses/${payload?.link}/setup`]);
    } else {
      await this.fetchCourses(0);
    }
  }

  async onRowClicked(id: string): Promise<void> {
    const course: ICourse = await this.courseService.getOne(id, false, false);
    this.router.navigate([`/${this.hubsStore.useHubUrl}/admin/courses/${course.link}/setup`]);
  }

  edit(event: Event, entry: ICourse): void {
    event.stopPropagation();
    this.router.navigate([`/${this.hubsStore.useHubUrl}/admin/courses/${entry.link}/setup`], {
      relativeTo: this.route,
      state: {
        assetId: entry.id,
      },
    });
  }

  remove(event: Event, entry: ICourse): void {
    event.stopPropagation();
    const deleteDialogRef = this.dialogService.open(ConfirmDialogComponent, {
      closable: false,
      styleClass: 'confirm-dialog',
      data: {
        titleKey: 'adminCourseList.deleteCourse',
        descriptionKey: 'adminCourseList.deleteCourseText',
        confirmBtnKey: 'adminCourseList.confirmBtn',
        cancelBtnKey: 'adminCourseList.cancelBtn',
        entry,
      },
    });

    deleteDialogRef.onClose.pipe(take(1)).subscribe(async (result: 'cancel' | 'confirm') => {
      if (result === 'confirm') {
        try {
          this.entries[this.entries.indexOf(entry)].isActionBtnShowingSpinner = true;

          // TODO: delete all not needed logic anymore
          // const deleteResult = await this.courseService.deleteFromAPI(entry.id);
          const deleteResult = await this.courseService.update(
            entry.id,
            { ...entry, isDeleted: true },
            false,
          );

          if (deleteResult) {
            this.removeEntry(entry);

            this.messageService.add({
              severity: 'success',
              summary: this.translateService.instant('success'),
              detail: this.translateService.instant('adminCourseList.succesDeleteCourse'),
              styleClass: 'custom-toast',
            });
          }
        } catch (error) {
          this.messageService.add({
            severity: 'error',
            summary: this.translateService.instant('success'),
            detail: this.translateService.instant('adminCourseList.errorDeleteCourse'),
            styleClass: 'custom-toast',
          });
        }
      }
    });
  }

  private async fetchCourses(page: number): Promise<void> {
    const filters = 'isDeleted:false';
    let response = null;
    response = await this.courseService.getAll(
      this.hubsStore.hubId,
      page,
      this.pageSize,
      [...this.selectedTags, ...this.selectedBrands, this.searchTerm].join(' '),
      filters,
    );
    this.entries = response.results;
    this.totalRecords = response.total;
  }
}
