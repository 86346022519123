import { Component, inject, OnInit, signal } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { DynamicDialogRef } from 'primeng/dynamicdialog';

import { IHub } from 'src/app/core/models';
import { HubsService } from 'src/app/core/services';
import { HubsStore } from 'src/app/core/stores';
import { shareInFacebook, shareInLinkedIn, shareInX } from 'src/app/core/utils';
import { CrossIconComponent } from 'src/app/standalone/shared/components';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-share-dialog',
  standalone: true,
  imports: [CrossIconComponent, TranslateModule],
  templateUrl: './share-dialog.component.html',
  styleUrl: './share-dialog.component.scss',
})
export class ShareDialogComponent implements OnInit {
  emailHref = signal('');

  #emailSubject = signal('');
  #emailBody = signal('');
  #ref = inject(DynamicDialogRef);
  #hubsStore = inject(HubsStore);
  #hubsService = inject(HubsService);

  get hub(): IHub {
    return this.#hubsStore.hub;
  }

  ngOnInit(): void {
    this.#emailSubject.set(
      `Introducing ${encodeURIComponent(this.hub.title)}: Your New Destination for Learning and Events!`,
    );
    this.#emailBody.set(`
      We're thrilled to announce the launch of ${this.hub.title}, your new go-to platform for cutting-edge courses and exciting events!

      🚀 What's waiting for you:
      • Expert-led courses to boost your skills
      • Engaging events to connect and grow
      • A community of like-minded learners

      Ready to dive in? Visit [Your Hub URL] to explore our offerings and join our community.

      Have questions? We're here to help! Reply to this email or reach out to us at [contact email/phone].

      Let's learn, grow, and connect together!

      Best regards,
      [Your Name]
      [Your Position/Company Name]
    `);
    this.emailHref.set(
      `mailto:?subject=${this.#emailSubject()}&body=${encodeURIComponent(this.#emailBody())}`,
    );
  }

  onCloseDialog(): void {
    this.#ref.close();
  }

  shareHub(type: 'facebook' | 'linkedin' | 'x'): void {
    this.markHubAsShared();
    const url = `${environment.baseUrl}/${this.#hubsStore.useHubUrl}/dashboard`;

    switch (type) {
      case 'facebook':
        shareInFacebook(url);
        break;
      case 'linkedin':
        shareInLinkedIn(url);
        break;
      case 'x':
        shareInX(url);
        break;
    }
  }

  async markHubAsShared(): Promise<void> {
    if (this.hub.isHubShared) {
      return;
    }
    const updatedHub: IHub = await this.#hubsService.update(this.hub.id, {
      ...this.hub,
      isHubShared: true,
    });
    this.#hubsStore.setHub(updatedHub);
  }
}
