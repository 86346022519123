import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-course-subscriber-instructor-sidebar',
  standalone: true,
  imports: [RouterModule, TranslateModule],
  templateUrl: './course-subscriber-instructor-sidebar.component.html',
  styleUrl: './course-subscriber-instructor-sidebar.component.scss',
})
export class CourseSubscriberInstructorSidebarComponent {}
