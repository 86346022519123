import * as dayjs from 'dayjs';
import * as weekOfYear from 'dayjs/plugin/weekOfYear';

export const getPeriodOfWeekFromToday = (count: number): any[] => {
  dayjs.extend(weekOfYear);
  const periods: string[] = [];

  for (let i = count - 1; i >= 0; i--) {
    const weekDate: dayjs.Dayjs = dayjs().subtract(i, 'week');
    const startOfWeek = weekDate.startOf('week');
    const endOfWeek = weekDate.endOf('week');
    const period = `${startOfWeek.format('D MMM')} - ${endOfWeek.format('D MMM')}`;
    periods.push(period);
  }

  return periods;
};
