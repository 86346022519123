import { Timestamp } from 'src/app/firebase';
import { CurrencyCode } from 'src/app/core/enums';
import { ITag } from '../tag/tag.model';
import { CourseLandingPageType } from 'src/app/standalone';
import { ICourseLandingPageConfig } from './course-landing-page-config.model';

export interface ICourse {
  id: string;
  title: string;
  _title_: string;
  hubId: string;
  isPaid: boolean;
  isDisplayedPrice: boolean;
  currency: CurrencyCode;
  price: string;
  strikePrice: number;
  isPublished: boolean;
  tagline: string;
  link: string;
  ownerId: string;
  start: Timestamp | any;
  startTime?: Date;
  end: Timestamp | any;
  endTime?: Date;
  duration: string;
  featured: boolean;
  maintenance: boolean;
  banner: any;
  bannerGradient: string;
  featuredImage: any;
  video: string;
  internalVideo: File | string;
  description: string;
  features: string;
  supportedLanguages: string[];
  requirements: string;
  targetGroup: string;
  tags: string[];
  linkedin: string;
  facebook: string;
  xing: string;
  landingPageType: CourseLandingPageType;
  landingPage: ICourseLandingPageConfig;
  updatedAt: Timestamp;
  updatedBy: string;
  createdAt: Timestamp;
  createdBy: string;
  publicationDate?: Timestamp | null;
  tagsList?: ITag[];
  isActionBtnShowingSpinner?: boolean;
  accountingNumber: string;
  isDeleted: boolean;
  checkedNavigationItems?: string[];
}
