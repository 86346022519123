<div class="container">
  <div class="first-line">
    <i [class]="iconClass()"></i>
    <span class="text">{{ text() | translate }}</span>
    @if (isShowTooltip()) {
      <i
        class="fa-regular fa-circle-info"
        pTooltip="{{ tooltipText() | translate }}"
        [tooltipPosition]="tooltipPosition()"
        [tooltipStyleClass]="tooltipStyleClass()"
        [tooltipEvent]="tooltipEvent()"
        [tabIndex]="tabIndex()">
      </i>
    }
  </div>
  <div class="second-line">{{ value() | translate }}</div>
</div>
