import { Component, inject, OnInit, signal } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';

import { ICourseTicket, ITag, IUserCourse } from 'src/app/core/models';
import { CourseUserService } from 'src/app/core/services';
import { SharedModule } from 'src/app/shared';
import { ButtonComponent, ButtonSize, ToastComponent } from 'src/app/standalone/shared';

@Component({
  selector: 'app-course-instructor-subscriber-course-details',
  standalone: true,
  imports: [SharedModule, ButtonComponent, ToastComponent],
  templateUrl: './course-instructor-subscriber-course-details.component.html',
  styleUrl: './course-instructor-subscriber-course-details.component.scss',
})
export class CourseInstructorSubscriberCourseDetailsComponent implements OnInit {
  isUpdating = signal(false);
  courseTickets = signal<ICourseTicket[]>(null);
  form: FormGroup;
  courseUser = signal<IUserCourse>(null);
  courseTags = signal<ITag[]>(null);
  buttonSize = signal<typeof ButtonSize>(ButtonSize);

  #route = inject(ActivatedRoute);
  #fb = inject(FormBuilder);
  #messageService = inject(MessageService);
  #translateService = inject(TranslateService);
  #courseUserService = inject(CourseUserService);

  get canUpdate(): boolean {
    return !this.isUpdating() && this.form.valid && this.form.dirty;
  }

  ngOnInit(): void {
    this.courseTickets.set(this.#route.snapshot.data['courseTickets']);
    this.courseUser.set(this.#route.snapshot.data['courseUser']);
    this.courseTags.set(this.#route.snapshot.data['tagsByIds']);
    this.createForm();
    this.updateForm();
  }

  selectTags(tagIds: string[]): void {
    this.form.patchValue({ tags: tagIds });
  }

  async update(): Promise<void> {
    try {
      this.isUpdating.set(true);
      const currentValues = this.form.getRawValue();
      const courseUserForUpdate: IUserCourse = {
        ...this.courseUser(),
        ticketId: currentValues.ticket?.id,
        tags: currentValues.tags,
      };
      await this.#courseUserService.updateCourseUser(courseUserForUpdate);
      this.form.reset(currentValues);
      this.showToastMessage('success', 'adminUserCourseDetails.successfullyUpdated');
    } catch (error) {
      console.warn(error);
      this.showToastMessage('error', 'application.toasters.error');
    } finally {
      this.isUpdating.set(false);
    }
  }

  private createForm(): void {
    this.form = this.#fb.group({
      ticket: null,
      tags: null,
    });
  }

  private updateForm(): void {
    this.form.patchValue({
      ...this.courseUser(),
      ticket: this.courseUser()?.ticketId
        ? this.courseTickets().find(
            (ticket: ICourseTicket) => ticket.id === this.courseUser()?.ticketId,
          )
        : null,
    });
  }

  private showToastMessage(severity: 'success' | 'error', detail: string): void {
    this.#messageService.add({
      severity,
      summary: this.#translateService.instant(severity),
      detail: this.#translateService.instant(detail),
      styleClass: 'custom-toast',
    });
  }
}
