<div *ngIf="loading">
  <app-loader></app-loader>
</div>

<div
  *ngIf="!loading"
  class="event-pages-list-table-container">
  <div class="c-mb-4">
    <admin-table-header-navbar [title]="'adminEventPagesList.title' | translate">
      <app-button
        buttonText="{{ 'adminEventPagesList.addBtnLabel' | translate }}"
        buttonIconLeft="far fa-plus"
        [buttonSize]="buttonSize.MEDIUM"
        (click)="add()" />
    </admin-table-header-navbar>
  </div>

  <admin-table-filter-navbar
    (onSearchTermChange)="searchValue = $event"></admin-table-filter-navbar>

  <admin-table
    [loading]="loadingEntries"
    [columns]="columns"
    [entries]="entries"
    [paginator]="true"
    [lazy]="false"
    emptyStateKey="adminEventPagesList.emptyState"
    [searchValue]="searchValue"
    [searchFilterFields]="searchFilterFields"
    (rowClicked)="onRowClicked($event)">
    <ng-template
      let-entry
      epTemplateKey="langCell">
      <span
        class="flag-icon flag-icon-squared flag-icon-circle flag-icon-{{
          entry.language === 'en' ? 'gb' : entry.language
        }}">
      </span>
    </ng-template>

    <ng-template
      let-entry
      epTemplateKey="htmlCell">
      <app-status-tag
        [iconClass]="entry.isHtmlMode ? 'fa-regular fa-check' : 'fa-regular fa-xmark'"
        [bgColor]="entry.isHtmlMode ? 'var(--appPrimaryColor)' : '#ff244c'" />
    </ng-template>

    <ng-template
      let-entry
      epTemplateKey="defaultCell">
      <app-status-tag
        [iconClass]="entry.default ? 'fa-regular fa-check' : 'fa-regular fa-xmark'"
        [bgColor]="entry.default ? 'var(--appPrimaryColor)' : '#ff244c'" />
    </ng-template>

    <ng-template
      let-entry
      epTemplateKey="statusCell">
      <app-status-tag
        [iconClass]="entry.status ? 'fa-regular fa-check' : 'fa-regular fa-xmark'"
        tagText="{{
          (entry.status ? 'adminTable.statusActive' : 'adminTable.statusInactive') | translate
        }}"
        [bgColor]="entry.status ? 'var(--appPrimaryColor)' : '#ff244c'" />
    </ng-template>

    <ng-template
      let-entry
      epTemplateKey="actionsCell">
      <div class="table-btn-actions-container">
        <app-button
          [buttonType]="buttonType.ROUND"
          [buttonSize]="buttonSize.MEDIUM"
          [buttonStyle]="buttonStyle.FILLED_SECONDARY"
          buttonRoundIcon="far fa-pen-to-square"
          (click)="edit($event, entry)" />
        <app-button
          [buttonType]="buttonType.ROUND"
          [buttonSize]="buttonSize.MEDIUM"
          [buttonStyle]="buttonStyle.FILLED_DANGER"
          buttonRoundIcon="far fa-trash-can"
          (click)="remove($event, entry)" />
      </div>
    </ng-template>
  </admin-table>
</div>
