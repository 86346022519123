<ng-container *ngIf="loading">
  <app-loader></app-loader>
</ng-container>

<div
  *ngIf="!loading"
  class="new-event-container">
  <div class="header c-p-5">
    <div class="title">
      {{ 'adminNewEvent.createEvent' | translate }}
    </div>
    <i
      class="fa-regular fa-xmark close-icon"
      (click)="closeDialog()"></i>
  </div>

  <form [formGroup]="form">
    <div class="form-container c-p-5">
      <!-- Title input-->
      <div>
        <app-input-label
          labelType="required"
          for="title-input"
          text="adminNewEvent.name">
        </app-input-label>
        <input
          class="custom-input-text custom-input-text-required"
          formControlName="title"
          id="title-input"
          pInputText
          type="text"
          maxlength="{{ getMaxLengthValue('title') }}"
          placeholder="{{ 'adminNewEvent.eventTitleLabelPlaceholder' | translate }}" />
        <app-input-error-message
          [showMessage]="form.controls.title.invalid && form.controls.title.touched"
          errorMessage="{{ 'adminNewEvent.eventTitleReq' | translate }}">
        </app-input-error-message>
      </div>

      <!-- Tagline input -->
      <div class="form-field-block c-mb-4">
        <app-input-label
          for="tagline-input"
          text="adminNewEvent.tagline">
        </app-input-label>
        <input
          class="custom-input-text custom-input-text-required"
          formControlName="tagline"
          id="tagline-input"
          pInputText
          type="text"
          maxlength="{{ getMaxLengthValue('tagline') }}"
          placeholder="{{ 'adminNewEvent.taglinePlaceholder' | translate }}" />
      </div>

      <!-- Type Event -->
      <div class="form-field-block c-mb-4">
        <app-input-label
          labelType="tooltip-required"
          text="adminNewEvent.eventType"
          tooltipText="adminNewEvent.eventTypeTooltip"
          [tooltipEvent]="isMobile ? 'focus' : 'hover'"
          [tabIndex]="isMobile ? 0 : null">
        </app-input-label>
        <div class="form-field-input-radio-block c-mb-4 c-mt-3 c-p-0">
          <p-radioButton
            class="custom-input-radio"
            [value]="eventTypes.DIGITAL"
            formControlName="eventType"
            inputId="digitalId">
          </p-radioButton>
          <app-input-label
            for="digitalId"
            text="adminNewEvent.digital"
            labelType="switch-checkbox">
          </app-input-label>
        </div>
        <div class="form-field-input-radio-block c-p-0 c-mb-6">
          <p-radioButton
            class="custom-input-radio"
            [value]="eventTypes.HYBRID"
            formControlName="eventType"
            inputId="hybridId">
          </p-radioButton>
          <app-input-label
            for="hybridId"
            text="adminNewEvent.hybrid"
            labelType="switch-checkbox">
          </app-input-label>
        </div>
      </div>

      <!-- Location input -->
      <div
        *ngIf="form.controls.eventType.value === eventTypes.HYBRID"
        class="c-mb-4">
        <app-input-label
          labelType="required"
          for="location-input"
          text="adminNewEvent.location">
        </app-input-label>
        <input
          class="custom-input-text custom-input-text-required"
          formControlName="location"
          id="location-input"
          pInputText
          type="text"
          placeholder="{{ 'adminNewEvent.locationPlaceholder' | translate }}" />
      </div>

      <!-- Timezone input -->
      <div>
        <app-input-label
          labelType="required"
          for="timezone-input"
          text="adminNewEvent.timeZoneLabel">
        </app-input-label>
        <p-dropdown
          class="custom-dropdown custom-dropdown-required"
          panelStyleClass="custom-dropdown-panel"
          [filter]="true"
          [options]="timezones"
          [virtualScroll]="true"
          [virtualScrollItemSize]="virtualScrollItemSize"
          optionLabel="name"
          formControlName="timezone"
          inputId="timezone-input"
          placeholder="{{ 'adminNewEvent.timeZoneLabel' | translate }}">
        </p-dropdown>
        <app-input-error-message
          [showMessage]="form.controls.timezone.invalid && form.controls.timezone.touched"
          errorMessage="{{ 'adminNewEvent.timeZoneReq' | translate }}">
        </app-input-error-message>
      </div>

      <div class="grid">
        <div class="col-6">
          <!-- Start Date input -->
          <div>
            <app-input-label
              labelType="required"
              for="start-date-input"
              text="adminNewEvent.startDateLabel">
            </app-input-label>
            <p-calendar
              class="custom-calendar custom-calendar-required"
              panelStyleClass="custom-calendar-panel-style-class"
              appendTo="body"
              formControlName="startDate"
              [monthNavigator]="true"
              [yearNavigator]="true"
              [showIcon]="true"
              dateFormat="dd/mm/yy"
              yearRange="1900:2050"
              inputId="start-date-input"
              icon="far fa-calendar-day"
              placeholder="{{ 'adminNewEvent.startDateLabel' | translate }}">
            </p-calendar>
            <app-input-error-message
              [showMessage]="form.controls.startDate.invalid && form.controls.startDate.touched"
              errorMessage="{{ 'adminNewEvent.startDateReq' | translate }}">
            </app-input-error-message>
          </div>
        </div>

        <div class="col-6">
          <!-- Start Time input -->
          <div>
            <app-input-label
              labelType="required"
              for="start-time-input"
              text="adminNewEvent.startTimeLabel">
            </app-input-label>
            <p-calendar
              class="custom-calendar custom-calendar-required custom-calendar-time"
              formControlName="startTime"
              placeholder="{{ 'adminNewEvent.startTimeLabel' | translate }}"
              inputId="start-time-input"
              [timeOnly]="true"
              [showIcon]="true"
              icon="far fa-clock">
            </p-calendar>
            <app-input-error-message
              [showMessage]="form.controls.startTime.invalid && form.controls.startTime.touched"
              errorMessage="{{ 'adminNewEvent.startTimeReq' | translate }}">
            </app-input-error-message>
          </div>
        </div>
      </div>

      <div class="grid">
        <div class="col-6">
          <!-- End Date input -->
          <div>
            <app-input-label
              labelType="required"
              for="end-date-input"
              text="adminNewEvent.endDateLabel">
            </app-input-label>
            <p-calendar
              class="custom-calendar custom-calendar-required"
              panelStyleClass="custom-calendar-panel-style-class"
              appendTo="body"
              formControlName="endDate"
              [monthNavigator]="true"
              [yearNavigator]="true"
              [showIcon]="true"
              dateFormat="dd/mm/yy"
              yearRange="1900:2050"
              inputId="end-date-input"
              icon="far fa-calendar-day"
              placeholder="{{ 'adminNewEvent.endDateLabel' | translate }}">
            </p-calendar>
            <app-input-error-message
              [showMessage]="form.controls.endDate.invalid && form.controls.endDate.touched"
              errorMessage="{{ 'adminNewEvent.endDateReq' | translate }}">
            </app-input-error-message>
          </div>
        </div>

        <div class="col-6">
          <!-- End Time input -->
          <div>
            <app-input-label
              labelType="required"
              for="end-time-input"
              text="adminNewEvent.endTimeLabel">
            </app-input-label>
            <p-calendar
              class="custom-calendar custom-calendar-required custom-calendar-time"
              formControlName="endTime"
              placeholder="{{ 'adminNewEvent.endTimeLabel' | translate }}"
              inputId="end-time-input"
              [timeOnly]="true"
              [showIcon]="true"
              icon="far fa-clock">
            </p-calendar>
            <app-input-error-message
              [showMessage]="form.controls.endTime.invalid && form.controls.endTime.touched"
              errorMessage="{{ 'adminNewEvent.endTimeReq' | translate }}">
            </app-input-error-message>
          </div>
        </div>
      </div>

      <!-- Accounting number input -->
      <div class="form-field-block c-mb-4">
        <app-input-label
          labelType="tooltip"
          for="accounting-number-input"
          text="adminNewEvent.accountingNumberLabel"
          tooltipText="adminNewEvent.accountingNumberTooltip"
          [tooltipEvent]="isMobile ? 'focus' : 'hover'"
          [tabIndex]="isMobile ? 0 : null">
        </app-input-label>
        <input
          class="custom-input-text custom-input-text-required"
          formControlName="accountingNumber"
          id="accounting-number-input"
          pInputText
          type="text"
          placeholder="{{ 'adminNewEvent.accountingNumberPlaceholder' | translate }}" />
      </div>

      <!-- Tags input -->
      <div class="form-field-block c-mb-4">
        <app-input-label
          for="tags-input"
          text="adminNewEvent.tags">
        </app-input-label>
        <p-multiSelect
          class="custom-multi-select"
          panelStyleClass="custom-multi-select-panel-style-class"
          [options]="possibleTags"
          appendTo="body"
          optionLabel="title"
          optionValue="id"
          formControlName="tags"
          inputId="tags-input"
          placeholder="{{ 'adminNewEvent.tagsPlaceholder' | translate }}">
        </p-multiSelect>
      </div>
    </div>
  </form>

  <div class="footer c-p-2 c-pl-5">
    <div class="col text-left form-field-input-checkbox-block c-p-0">
      <p-checkbox
        class="custom-input-checkbox-sm"
        binary="true"
        [formControl]="createAnotherControl"
        inputId="another-event-input">
      </p-checkbox>
      <app-input-label
        labelType="switch-checkbox"
        for="another-event-input"
        text="adminNewEvent.anotherEvent">
      </app-input-label>
    </div>
    <div class="col text-right flex justify-content-end align-items-center">
      <app-button
        buttonText="{{ 'adminNewEvent.cancelButton' | translate }}"
        buttonIconLeft="fa-regular fa-xmark"
        [buttonStyle]="buttonStyle.GHOST_PRIMARY"
        [buttonSize]="buttonSize.MEDIUM"
        (click)="closeDialog()" />
      <app-button
        class="c-ml-2"
        buttonText="{{ 'adminNewEvent.createBtn' | translate }}"
        buttonIconLeft="far fa-check"
        [buttonSize]="buttonSize.MEDIUM"
        [loading]="isUpdating"
        [disabled]="!canUpdate"
        (click)="create()" />
    </div>
  </div>
</div>
