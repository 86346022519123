import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';

import { CourseSubscribersService } from 'src/app/core/services';
import { CoursesStore } from 'src/app/core/stores';

export const countOfCourseSubscribersResolver: ResolveFn<number> = (): Promise<number> => {
  const coursesStore = inject(CoursesStore);
  const courseSubscribersService = inject(CourseSubscribersService);

  const courseId: string = coursesStore.adminCourse.id;

  return courseSubscribersService.getCountOfCourseSubscribers(courseId);
};
