<ng-container *ngIf="loading">
  <app-loader></app-loader>
</ng-container>

<div
  *ngIf="!loading"
  class="admin-plans-container">
  <div class="admin-plans-table-container">
    <div class="c-mb-4">
      <admin-table-header-navbar [title]="'adminPlans.appPlans' | translate">
        <app-button
          class="c-ml-2"
          routerLink="/{{
            hubStore.hub ? hubStore.useHubUrl : ''
          }}/admin/hub-settings/plans/new-plan"
          buttonText="{{ 'adminPlans.addBtnLabel' | translate }}"
          buttonIconLeft="far fa-plus"
          [buttonSize]="buttonSize.MEDIUM" />
      </admin-table-header-navbar>
    </div>

    <admin-table
      [scssClass]="scssClassForTable"
      [loading]="loadingEntries"
      [columns]="columns"
      [entries]="entries"
      [paginator]="true"
      [lazy]="false"
      [enableReorder]="true"
      [emptyStateKey]="'adminSubscriptions.tableEmpty'"
      [emptyStateIcon]="'fak fa-lox-attendees'"
      (rowReorder)="onRowReorder($event)"
      (rowClicked)="onRowClicked($event)">
      <ng-template
        let-entry
        epTemplateKey="statusCell">
        <app-status-tag
          [iconClass]="entry.active ? 'fa-regular fa-check' : 'fa-regular fa-xmark'"
          tagText="{{
            (entry.active ? 'adminTable.published' : 'adminTable.unpublished') | translate
          }}"
          [bgColor]="entry.active ? 'var(--appPrimaryColor)' : '#ff244c'" />
      </ng-template>

      <ng-template
        let-entry
        epTemplateKey="actionsCell">
        <div class="table-btn-actions-container">
          <app-button
            [buttonType]="buttonType.ROUND"
            [buttonSize]="buttonSize.MEDIUM"
            [buttonStyle]="buttonStyle.FILLED_SECONDARY"
            buttonRoundIcon="far fa-pen-to-square"
            (click)="edit($event, entry)" />
          <app-button
            [buttonType]="buttonType.ROUND"
            [buttonSize]="buttonSize.MEDIUM"
            [buttonStyle]="buttonStyle.FILLED_DANGER"
            buttonRoundIcon="far fa-trash-can"
            (click)="remove($event, entry)" />
        </div>
      </ng-template>
    </admin-table>
  </div>
</div>
