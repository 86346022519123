import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MenuItem } from 'primeng/api';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

import { FeatureHeaderComponent, FeatureMainContentComponent } from 'src/app/standalone/admin';
import { IEvent, IHub } from 'src/app/core/models';
import { EventsStore, HubsStore } from 'src/app/core/stores';
import { Router } from '@angular/router';
import { goToLink } from 'src/app/core/utils';
import { EventsService } from 'src/app/core/services';
import { ButtonComponent } from 'src/app/standalone/shared';
import { ButtonSize, ButtonStyle } from 'src/app/standalone/shared/enums';

@Component({
  selector: 'app-event-container',
  standalone: true,
  imports: [
    CommonModule,
    FeatureHeaderComponent,
    FeatureMainContentComponent,
    TranslateModule,
    ButtonComponent,
  ],
  templateUrl: './event-container.component.html',
  styleUrls: ['./event-container.component.scss'],
})
export class EventContainerComponent implements OnInit {
  items: MenuItem[];
  icon: string;
  btnLabel: string;
  statusChangeInProgress: boolean;
  buttonStyle = ButtonStyle;
  buttonSize = ButtonSize;

  constructor(
    private translateService: TranslateService,
    public hubsStore: HubsStore,
    private eventsStore: EventsStore,
    private router: Router,
    private eventsService: EventsService,
  ) {}

  get hub(): IHub {
    return this.hubsStore.hub;
  }

  get event(): IEvent {
    return this.eventsStore.adminEvent;
  }

  ngOnInit(): void {
    this.updateBtnProperties();

    this.items = [
      {
        label: this.translateService.instant('adminEvent.menuItemDashboard'),
        icon: 'fa-regular fa-gear',
        routerLink: `/${this.hub ? this.hubsStore.useHubUrl : ''}/admin/event/${this.event.link}/dashboard`,
      },
      {
        label: this.translateService.instant('adminEvent.menuItemSetup'),
        icon: 'fa-regular fa-rocket-launch',
        routerLink: `/${this.hub ? this.hubsStore.useHubUrl : ''}/admin/event/${this.event.link}/setup`,
        items: [
          {
            label: this.translateService.instant('adminEvent.menuItemDetails'),
            icon: 'fa-regular fa-circle-info',
            routerLink: `/${this.hub ? this.hubsStore.useHubUrl : ''}/admin/event/${this.event.link}/setup/details`,
          },
          {
            label: this.translateService.instant('adminEvent.menuItemDesign'),
            icon: 'fa-regular fa-display',
            routerLink: `/${this.hub ? this.hubsStore.useHubUrl : ''}/admin/event/${this.event.link}/setup/design`,
          },
          {
            label: this.translateService.instant('adminEvent.menuItemSettings'),
            icon: 'far fa-gear',
            routerLink: `/${this.hub ? this.hubsStore.useHubUrl : ''}/admin/event/${this.event.link}/setup/settings`,
          },
          {
            label: this.translateService.instant('adminEvent.menuItemStages'),
            icon: 'fak fa-lox-stages',
            routerLink: `/${this.hub ? this.hubsStore.useHubUrl : ''}/admin/event/${this.event.link}/setup/stages`,
          },
          {
            label: this.translateService.instant('adminEvent.menuItemAddOns'),
            icon: 'fa-regular fa-layer-plus',
            routerLink: `/${this.hub ? this.hubsStore.useHubUrl : ''}/admin/event/${this.event.link}/setup/add-ons`,
          },
        ],
      },
      {
        label: this.translateService.instant('adminEvent.menuItemContent'),
        icon: 'fa-regular fa-folder-grid',
        routerLink: `/${this.hub ? this.hubsStore.useHubUrl : ''}/admin/event/${this.event.link}/content`,
        items: [
          {
            label: this.translateService.instant('adminEvent.menuItemSessions'),
            icon: 'fa-regular fa-calendar-lines',
            routerLink: `/${this.hub ? this.hubsStore.useHubUrl : ''}/admin/event/${this.event.link}/content/sessions`,
          },
          {
            label: this.translateService.instant('adminEvent.menuItemEventPages'),
            icon: 'far fa-book-open',
            routerLink: `/${this.hub ? this.hubsStore.useHubUrl : ''}/admin/event/${this.event.link}/content/pages`,
          },
          {
            label: this.translateService.instant('adminEvent.menuItemEventMenu'),
            icon: 'fas fa-bars',
            routerLink: `/${this.hub ? this.hubsStore.useHubUrl : ''}/admin/event/${this.event.link}/content/event-menu`,
          },
        ],
      },
      {
        label: this.translateService.instant('adminEvent.menuItemRegistrationsTickets'),
        icon: 'fa-sharp fa-regular fa-tickets',
        routerLink: `/${this.hub ? this.hubsStore.useHubUrl : ''}/admin/event/${this.event.link}/registrations-tickets`,
        items: [
          {
            label: this.translateService.instant('adminEvent.menuItemTickets'),
            icon: 'fa-regular fa-ticket',
            routerLink: `/${this.hub ? this.hubsStore.useHubUrl : ''}/admin/event/${this.event.link}/registrations-tickets/tickets`,
          },
          {
            label: this.translateService.instant('adminEvent.menuItemEventRegistration'),
            icon: 'fa-regular fa-pen-field',
            routerLink: `/${this.hub ? this.hubsStore.useHubUrl : ''}/admin/event/${this.event.link}/registrations-tickets/event-registration`,
          },
          // {
          //   label: this.translateService.instant('adminEvent.menuItemDiscountCodes'),
          //   icon: 'fa-regular fa-badge-percent',
          //   routerLink: `/${this.hub ? this.hubsStore.useHubUrl : ''}/admin/event/${this.event.link}/registrations-tickets/discount-codes`
          // },
          // {
          //   label: this.translateService.instant('adminEvent.menuItemAttendeeCheckIn'),
          //   icon: 'fa-regular fa-user-check',
          //   routerLink: `/${this.hub ? this.hubsStore.useHubUrl : ''}/admin/event/${this.event.link}/registrations-tickets/attendee-check-in`
          // }
        ],
      },
      {
        label: this.translateService.instant('adminEvent.menuItemParticipants'),
        icon: 'fak fa-lox-attendees',
        routerLink: `/${this.hub ? this.hubsStore.useHubUrl : ''}/admin/event/${this.event.link}/participants`,
        items: [
          {
            label: this.translateService.instant('adminEvent.menuItemAttendees'),
            icon: 'fa-regular fa-user-group',
            routerLink: `/${this.hub ? this.hubsStore.useHubUrl : ''}/admin/event/${this.event.link}/participants/attendees`,
          },
          {
            label: this.translateService.instant('adminEvent.menuItemSpeakers'),
            icon: 'fa-regular fa-bullhorn',
            routerLink: `/${this.hub ? this.hubsStore.useHubUrl : ''}/admin/event/${this.event.link}/participants/speakers`,
          },
          {
            label: this.translateService.instant('adminEvent.menuItemBrands'),
            icon: 'fak fa-lox-crown',
            routerLink: `/${this.hub ? this.hubsStore.useHubUrl : ''}/admin/event/${this.event.link}/participants/brands`,
          },
          {
            label: this.translateService.instant('adminEvent.menuItemBrandPeople'),
            icon: 'fak fa-lox-team',
            routerLink: `/${this.hub ? this.hubsStore.useHubUrl : ''}/admin/event/${this.event.link}/participants/brand-people`,
          },
        ],
      },
      {
        label: this.translateService.instant('adminEvent.menuItemCommunication'),
        icon: 'fa-regular fa-message-pen',
        routerLink: `/${this.hub ? this.hubsStore.useHubUrl : ''}/admin/event/${this.event.link}/communication`,
        items: [
          {
            label: this.translateService.instant('adminEvent.menuItemEventEmails'),
            icon: 'fa-regular fa-square-envelope',
            routerLink: `/${this.hub ? this.hubsStore.useHubUrl : ''}/admin/event/${this.event.link}/communication/event-emails`,
          },
          // {
          //   label: this.translateService.instant('adminEvent.menuItemEventNotifications'),
          //   icon: 'far fa-bell',
          //   routerLink: `/${this.hub ? this.hubsStore.useHubUrl : ''}/admin/event/${this.event.link}/communication/notifications`
          // }
        ],
      },
      // {
      //   label: this.translateService.instant('adminEvent.menuItemReports'),
      //   icon: 'fa-regular fa-chart-simple',
      //   routerLink: `/${this.hub ? this.hubsStore.useHubUrl : ''}/admin/event/${this.event.link}/reports`,
      //   items: [
      //     {
      //       label: this.translateService.instant('adminEvent.menuItemEventItselfReport'),
      //       icon: 'fa-regular fa-screen-users',
      //       routerLink: `/${this.hub ? this.hubsStore.useHubUrl : ''}/admin/event/${this.event.link}/reports/itself-report`
      //     },
      //     {
      //       label: this.translateService.instant('adminEvent.menuItemEventSessionsReport'),
      //       icon: 'fa-regular fa-calendar-lines',
      //       routerLink: `/${this.hub ? this.hubsStore.useHubUrl : ''}/admin/event/${this.event.link}/reports/sessions-report`
      //     },
      //     {
      //       label: this.translateService.instant('adminEvent.menuItemEventRegistrationReport'),
      //       icon: 'fa-regular fa-pen-field',
      //       routerLink: `/${this.hub ? this.hubsStore.useHubUrl : ''}/admin/event/${this.event.link}/reports/registration-report`
      //     },
      //     {
      //       label: this.translateService.instant('adminEvent.menuItemEventSalesReport'),
      //       icon: 'fa-regular fa-money-bill-trend-up',
      //       routerLink: `/${this.hub ? this.hubsStore.useHubUrl : ''}/admin/event/${this.event.link}/reports/sales-report`
      //     },
      //     {
      //       label: this.translateService.instant('adminEvent.menuItemEventEngagementReport'),
      //       icon: 'fa-regular fa-user-group',
      //       routerLink: `/${this.hub ? this.hubsStore.useHubUrl : ''}/admin/event/${this.event.link}/reports/engagement-report`
      //     }
      //   ]
      // }
    ];
    if (!this.hubsStore.hub.brands) {
      this.items.forEach((item) => {
        if (
          item.label === this.translateService.instant('adminEvent.menuItemCommunityBrands') &&
          item.items
        ) {
          item.items = item.items.filter((subItem) => {
            return (
              subItem.label !== this.translateService.instant('adminEvent.menuItemBrands') &&
              subItem.label !== this.translateService.instant('adminEvent.menuItemBrandPeople')
            );
          });
        }
      });
    }
  }

  navigateToEvent(): void {
    const newRelativeUrl = this.router.createUrlTree([
      `${this.hubsStore.useHubUrl}`,
      'events',
      this.event.id,
    ]);
    const baseUrl = window.location.href.replace(this.router.url, '');
    goToLink(baseUrl + newRelativeUrl);
  }

  async toggleEventStatus(): Promise<void> {
    this.statusChangeInProgress = true;
    this.icon = 'pi pi-spinner pi-spin';

    if (this.event.publicationDate) {
      await this.eventsService.unpublishEvent(this.event.id);
    } else {
      await this.eventsService.publishEvent(this.event.id);
    }

    this.eventsStore.adminEvent = await this.eventsService.getOne(this.event.id, true, false);
    this.statusChangeInProgress = false;
    this.updateBtnProperties();
  }

  updateBtnProperties(): void {
    this.icon = this.event.publicationDate ? 'far fa-circle-check' : 'fa-regular fa-paper-plane';
    this.btnLabel = this.event.publicationDate
      ? 'adminEvent.publishLoading'
      : 'adminEvent.publishLabel';
  }
}
