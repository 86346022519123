import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from 'src/app/shared';

@Component({
  selector: 'app-stat-card',
  standalone: true,
  imports: [CommonModule, SharedModule],
  templateUrl: './stat-card.component.html',
  styleUrls: ['./stat-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatCardComponent {
  iconClass = input<string>();
  text = input<string>();
  value = input<string>();
  isShowTooltip = input(false);
  tooltipText = input<string>();
  tooltipPosition = input<'right' | 'left' | 'top' | 'bottom'>('right');
  tooltipStyleClass = input<string>('custom-tooltip');
  tooltipEvent = input<'hover' | 'focus'>('hover');
  tabIndex = input<number>();
}
