<ng-container *ngIf="loading">
  <app-loader></app-loader>
</ng-container>

<div
  *ngIf="!loading"
  class="admin-course-media-container">
  <div class="c-mb-4">
    <admin-table-header-navbar [title]="'adminCourseMediaList.title' | translate">
      <div class="btn-actions-container">
        <app-button
          buttonText="{{ 'adminLibraryList.attachAssets' | translate }}"
          buttonIconLeft="fa fa-paperclip"
          [buttonStyle]="buttonStyle.FILLED_SECONDARY"
          [buttonSize]="buttonSize.MEDIUM"
          [disabled]="loadingEntries"
          (click)="openAttachAssetsDialog()" />
        <app-button
          buttonText="{{ 'adminLibraryList.addBtnTitle' | translate }}"
          buttonIconLeft="far fa-plus"
          [buttonSize]="buttonSize.MEDIUM"
          [disabled]="loadingEntries"
          (click)="openNewCourseAssetDialog()" />
      </div>
    </admin-table-header-navbar>
  </div>
  <div class="admin-course-media-table-container">
    <admin-table-filter-navbar
      (onSearchTermChange)="onSearchTermChange($event)"></admin-table-filter-navbar>

    <admin-table
      [loading]="loadingEntries"
      [lazy]="false"
      [columns]="columns"
      [entries]="entries"
      [pageSize]="pageSize"
      [totalRecords]="totalRecords"
      scssClass="course-assets-table show-row-border-bottom"
      emptyStateKey="adminLibraryList.emptyState"
      emptyStateIcon="far fa-photo-video"
      (rowClicked)="onRowClick($event)">
      <ng-template
        let-entry
        epTemplateKey="titleCell">
        <div class="title-cell">
          <div
            *ngIf="
              entry.type !== assetType.EVENT || (entry.type === assetType.EVENT && entry.image)
            ">
            <img
              *ngIf="entry.image"
              class="banner"
              [defaultImage]="imgPreloader"
              [lazyLoad]="entry.image | imageSize" />
            <div
              *ngIf="!entry.image"
              class="placeholder-container">
              <img
                class="placeholder"
                [src]="getImagePlaceholder(entry) | imageSize" />
            </div>
          </div>
          <div
            *ngIf="entry.type === assetType.EVENT && !entry.image"
            class="banner">
            <app-asset-event-banner
              [asset]="entry"
              [containerStyles]="assetEventBannerContainerStyles"
              [monthStyles]="assetEventBannerMonthStyles"
              [dateStyles]="assetEventBannerDateStyle">
            </app-asset-event-banner>
          </div>
          <p class="asset-title">{{ entry.title }}</p>
        </div>
      </ng-template>

      <ng-template
        let-entry
        epTemplateKey="actionsCell">
        <div class="table-btn-actions-container">
          <app-button
            [buttonType]="buttonType.ROUND"
            [buttonSize]="buttonSize.MEDIUM"
            [buttonStyle]="buttonStyle.FILLED_SECONDARY"
            buttonRoundIcon="far fa-pen-to-square"
            (click)="edit($event, entry)" />
          <app-button
            [buttonType]="buttonType.ROUND"
            [buttonSize]="buttonSize.MEDIUM"
            [buttonStyle]="buttonStyle.FILLED_DANGER"
            buttonRoundIcon="far fa-trash-can"
            (click)="remove($event, entry)" />
        </div>
      </ng-template>
    </admin-table>
  </div>
</div>
