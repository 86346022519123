@if (loading) {
  <app-loader></app-loader>
} @else {
  <div class="admin-course-reports-container">
    <div class="admin-course-reports-table-container">
      <div class="c-mb-4">
        <admin-table-header-navbar title="{{ 'courseReports.assetsTitle' | translate }}">
        </admin-table-header-navbar>
      </div>
      <admin-table-filter-navbar (onSearchTermChange)="onSearchTermChange($event)">
      </admin-table-filter-navbar>
      <admin-table
        [loading]="loadingEntries"
        [lazy]="false"
        [columns]="columns"
        [entries]="entries"
        [pageSize]="pageSize"
        [totalRecords]="totalRecords"
        [searchFilterFields]="searchFilterFields"
        [searchValue]="searchTerm"
        (rowClicked)="onRowClick($event)"
        emptyStateTitleKey="courseReports.emptyState"
        emptyStateKey="courseReports.emptyStateDescription"
        emptyStateIcon="far fa-photo-video"
        scssClass="reports-table show-row-border-bottom">
        <ng-template
          let-entry
          epTemplateKey="titleCell">
          <div class="title-cell">
            <div
              *ngIf="
                entry.type !== assetType.EVENT || (entry.type === assetType.EVENT && entry.image)
              ">
              <img
                *ngIf="entry.image"
                class="banner"
                [defaultImage]="imgPreloader"
                [lazyLoad]="entry.image | imageSize" />
              <div
                *ngIf="!entry.image"
                class="placeholder-container">
                <img
                  class="placeholder"
                  [src]="getImagePlaceholder(entry) | imageSize" />
              </div>
            </div>
            <div
              *ngIf="entry.type === assetType.EVENT && !entry.image"
              class="banner">
              <app-asset-event-banner
                [asset]="entry"
                [containerStyles]="assetEventBannerContainerStyles"
                [monthStyles]="assetEventBannerMonthStyles"
                [dateStyles]="assetEventBannerDateStyle">
              </app-asset-event-banner>
            </div>
            <p class="asset-title">{{ entry.title }}</p>
          </div>
        </ng-template>
        <ng-template
          let-entry
          epTemplateKey="viewersCountCell">
          <div class="count-cell">
            <div class="count">
              {{ entry.viewersCount }}
            </div>
          </div>
        </ng-template>
        <ng-template
          let-entry
          epTemplateKey="progressCell">
          <div class="progress-container">
            <p-progressBar
              [value]="entry.averagePercentWatched"
              [showValue]="false">
            </p-progressBar>
            <div class="percent-value">
              {{ getPercentageString(entry.averagePercentWatched) }}
            </div>
          </div>
        </ng-template>
      </admin-table>
    </div>
  </div>
}
