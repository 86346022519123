import * as moment from 'moment';
import * as momentTimezone from 'moment-timezone';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { merge, Subject } from 'rxjs';
import { debounceTime, filter, takeUntil, tap } from 'rxjs/operators';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { TranslateService } from '@ngx-translate/core';

import { Timestamp, ServerTimestamp } from 'src/app/firebase';
import { updateTime, utcTimeZones, VIRTUAL_SCROLL_ITEM_SIZE } from 'src/app/core/utils';
import { IEmail, IEvent, IHubTag, ITag, ITimeZone } from 'src/app/core/models';
import { EmailsService, EventsService, HubsService, TagsService } from 'src/app/core/services';
import { HubsStore, UsersStore } from 'src/app/core/stores';
import { EventsMaxLengthValidators, preparedValueForLink } from 'src/app/shared';
import { EventTypes } from 'src/app/core/enums';
import { environment } from 'src/environments/environment';
import { ButtonSize, ButtonStyle } from 'src/app/standalone';

@Component({
  selector: 'app-new-event',
  templateUrl: './new-event.component.html',
  styleUrls: ['./new-event.component.scss'],
})
export class NewEventComponent implements OnInit, OnDestroy {
  loading = true;
  isUpdating = false;
  timezones: ITimeZone[] = utcTimeZones;
  currentTimeZone: string;
  currentTimeZoneCity: ITimeZone;
  form: UntypedFormGroup;
  eventTypes = EventTypes;
  possibleTags: ITag[];
  createAnotherControl = new UntypedFormControl(null);
  minDate: Date = moment().toDate();
  virtualScrollItemSize: number = VIRTUAL_SCROLL_ITEM_SIZE;
  buttonStyle = ButtonStyle;
  buttonSize = ButtonSize;

  readonly DEFAULT_EMAIL = environment.defaultEmail;

  private defaultStartTimeAndDate: Date = moment()
    .add(1, 'day')
    .set({ hour: 9, minute: 0, second: 0 })
    .toDate();
  private defaultEndTimeAndDate: Date = moment()
    .add(1, 'day')
    .set({ hour: 10, minute: 0, second: 0 })
    .toDate();
  private unsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    private messageService: MessageService,
    public hubsStore: HubsStore,
    private eventsService: EventsService,
    private usersStore: UsersStore,
    private translateService: TranslateService,
    private ref: DynamicDialogRef,
    private hubsService: HubsService,
    private tagsService: TagsService,
    private emailsService: EmailsService,
  ) {}

  get canUpdate(): boolean {
    return !this.isUpdating && this.form.valid && this.form.dirty;
  }

  async ngOnInit(): Promise<void> {
    this.currentTimeZone = momentTimezone.tz.guess().split('/')[1];
    this.currentTimeZoneCity = this.timezones.find((timeZone) =>
      timeZone.name.includes(this.currentTimeZone),
    );

    this.createForm();
    await this.getTags();
    this.loading = false;

    const startDateValue$ = this.form.controls.startDate.valueChanges;
    const startTimeValue$ = this.form.controls.startTime.valueChanges;
    const endDateValue$ = this.form.controls.endDate.valueChanges;
    const endTimeValue$ = this.form.controls.endTime.valueChanges;

    const eventDates$ = merge(startDateValue$, startTimeValue$, endDateValue$, endTimeValue$).pipe(
      debounceTime(300),
      filter((value) => !!value),
      tap(() => {
        const start = updateTime(
          this.form.controls.startDate.value,
          this.form.controls.startTime.value,
        );
        const end = updateTime(this.form.controls.endDate.value, this.form.controls.endTime.value);

        if (moment(this.minDate).isAfter(start)) {
          this.form.controls.startDate.setValue(this.minDate, { emitEvent: false });
          this.form.controls.startTime.setValue(this.minDate, { emitEvent: false });
        }

        if (moment(start).isAfter(end)) {
          const validEnd = start.add(1, 'hours');
          this.form.controls.endDate.setValue(validEnd.toDate(), { emitEvent: false });
          this.form.controls.endTime.setValue(validEnd.toDate(), { emitEvent: false });
        }
      }),
    );

    const eventTypeChanges$ = this.form.controls.eventType.valueChanges.pipe(
      tap((event: string) => {
        if (event === EventTypes.HYBRID) {
          this.form.controls.location.enable();
          this.form.controls.location.setValidators(Validators.required);
        } else {
          this.form.controls.location.setValue(null);
          this.form.controls.location.disable();
        }
        this.form.controls.location.updateValueAndValidity();
      }),
    );

    merge(eventDates$, eventTypeChanges$).pipe(takeUntil(this.unsubscribe$)).subscribe();
  }

  async create(): Promise<void> {
    this.isUpdating = true;

    try {
      const event = this.form.getRawValue();

      const startDate = updateTime(event.startDate, event.startTime);
      const endDate = updateTime(event.endDate, event.endTime);
      const eventLink: string = await this.createLinkValue(event.title);

      const eventPayload: IEvent = {
        id: null,
        hubId: this.hubsStore.hub ? this.hubsStore.hub.id : null,
        user: this.usersStore.userId,
        isPublished: false,
        title: event.title,
        accountingNumber: event.accountingNumber,
        eventType: event.eventType,
        _title_: event.title.toLowerCase(),
        shortDescription: null,
        description: null,
        tagline: event.tagline,
        location: this.form.controls.eventType.value === EventTypes.HYBRID ? event.location : null,
        timezone: event.timezone,
        logoDark: null,
        logoLight: null,
        banner: null,
        icon: null,
        start: Timestamp.fromMillis(startDate.toDate().getTime()),
        end: Timestamp.fromMillis(endDate.toDate().getTime()),
        tags: event?.tags ?? [],
        link: eventLink,
        primaryColor: null,
        accentColor: null,
        linkedin: null,
        facebook: null,
        xing: null,
        email: this.DEFAULT_EMAIL,
        emailName: event.title,
        featured: false,
        featuredImage: null,
        isTicketRestrictions: false,
        updatedAt: null,
        updatedBy: null,
        createdAt: ServerTimestamp(),
        createdBy: this.usersStore.userId,
        eventbriteEventId: null,
        eventbriteToken: null,
        eventbriteSync: null,
        eventRegistrationConfirmationEmail: true,
        eventInvitationDeclinedEmail: true,
        isOnDemandMode: false,
        isOnMaintenance: false,
        appointmentFeature: false,
        appointmentAttendeesStatus: false,
        appointmentSpeakersStatus: false,
        onlineVideoMeetingStatus: false,
        duration: null,
        hideChat: false,
        hideChatPreviewScreen: false,
        hideEventSearch: false,
        hideRecommendedSessions: false,
        hideBrandLevelNames: false,
        isEnableDirectBuy: event.eventType === EventTypes.DIGITAL,
        scheduleEventCheckin: false,
        isDeleted: false,
      };

      const newCreatedEvent: IEvent = await this.eventsService.create(eventPayload);

      // adding default email templates to the event
      const emails: IEmail[] = await this.emailsService.getDefaultEmails();
      await Promise.all(
        emails.map((email: IEmail) =>
          this.emailsService.attachEmailToEvent(newCreatedEvent.id, email),
        ),
      );

      this.isUpdating = false;
      if (this.createAnotherControl.value) {
        this.form.reset({
          eventType: this.eventTypes.DIGITAL,
          startDate: this.defaultStartTimeAndDate,
          startTime: this.defaultStartTimeAndDate,
          endDate: this.defaultEndTimeAndDate,
          endTime: this.defaultEndTimeAndDate,
        });

        return;
      }
      this.ref.close(eventPayload);
    } catch (error) {
      console.log(error);
      this.isUpdating = false;
      this.messageService.add({
        severity: 'error',
        summary: this.translateService.instant('error'),
        detail: this.translateService.instant(`adminNewEvent.errorCreateEvent`),
        styleClass: 'custom-toast',
      });
    }
  }

  getMaxLengthValue(formControl): number {
    return Number(EventsMaxLengthValidators[formControl]);
  }

  closeDialog(): void {
    this.ref.close();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private async createLinkValue(titleValue: string): Promise<string> {
    const linkValue = preparedValueForLink(titleValue);
    let linkExist = true;
    let counter = 0;
    while (linkExist) {
      if (counter === 0) {
        linkExist = await this.eventsService.checkEventByLinkExists(linkValue).toPromise();
      } else {
        linkExist = await this.eventsService
          .checkEventByLinkExists(`${linkValue}-${counter}`)
          .toPromise();
      }

      if (linkExist) {
        counter += 1;
      } else {
        if (counter === 0) {
          return linkValue;
        } else {
          return `${linkValue}-${counter}`;
        }
      }
    }
  }

  private createForm(): void {
    this.form = new UntypedFormGroup({
      title: new UntypedFormControl(null, [
        Validators.required,
        Validators.maxLength(EventsMaxLengthValidators.title),
      ]),
      tagline: new UntypedFormControl(
        null,
        Validators.maxLength(EventsMaxLengthValidators.tagline),
      ),
      eventType: new UntypedFormControl(this.eventTypes.DIGITAL, Validators.required),
      description: new UntypedFormControl(null),
      startDate: new UntypedFormControl(this.defaultStartTimeAndDate, Validators.required),
      startTime: new UntypedFormControl(this.defaultStartTimeAndDate, Validators.required),
      endDate: new UntypedFormControl(this.defaultEndTimeAndDate, Validators.required),
      endTime: new UntypedFormControl(this.defaultEndTimeAndDate, Validators.required),
      location: new UntypedFormControl(null),
      timezone: new UntypedFormControl(this.currentTimeZoneCity, Validators.required),
      accountingNumber: new UntypedFormControl(null),
      tags: new UntypedFormControl(null),
    });
  }

  private async getTags(): Promise<void> {
    const hubTags: IHubTag[] = await this.hubsService.getTagsRelation(this.hubsStore.hubId);
    const hubTagIds: string[] = hubTags.map((hubTag) => hubTag.tagId);
    this.possibleTags = await this.tagsService.getByIdsOrderedByTitle(hubTagIds);
  }
}
