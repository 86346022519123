export const getMonthsFromToday = (count: number): string[] => {
  const today = new Date();
  const currentMonth = today.getMonth();
  const months = [
    'shortMonthName.january',
    'shortMonthName.february',
    'shortMonthName.march',
    'shortMonthName.april',
    'shortMonthName.may',
    'shortMonthName.june',
    'shortMonthName.july',
    'shortMonthName.august',
    'shortMonthName.september',
    'shortMonthName.october',
    'shortMonthName.november',
    'shortMonthName.december',
  ];

  const result: string[] = [];

  for (let i = count - 1; i >= 0; i--) {
    const monthIndex = (currentMonth - i + 12) % 12;
    result.push(months[monthIndex]);
  }

  return result;
};
