<ng-container *ngIf="loading">
  <app-loader></app-loader>
</ng-container>

<div
  *ngIf="!loading"
  class="brand-team-container">
  <admin-table-header-navbar>
    <app-button
      buttonText="{{ 'adminBrands.attachUsers' | translate }}"
      buttonIconLeft="far fa-user-plus"
      [buttonStyle]="buttonStyle.FILLED_SECONDARY"
      [buttonSize]="buttonSize.MEDIUM"
      [disabled]="loadingEntries"
      (click)="openAttachUserDialog()" />
  </admin-table-header-navbar>

  <div class="c-my-4">
    <p-divider styleClass="custom-divider-horizontal"></p-divider>
  </div>

  <admin-table-filter-navbar
    (onSearchTermChange)="searchValue = $event"></admin-table-filter-navbar>

  <admin-table
    [loading]="loadingEntries"
    [columns]="columns"
    [entries]="entries"
    [paginator]="true"
    [lazy]="false"
    [emptyStateKey]="'adminBrandTeam.emptyState'"
    [emptyStateIcon]="'fa-regular fa-user-group'"
    [searchValue]="searchValue"
    [searchFilterFields]="searchFilterFields"
    (rowClicked)="onRowClicked($event)">
    <ng-template
      let-entry
      epTemplateKey="logoCell">
      <img
        *ngIf="entry.profileImage"
        style="height: 4rem; width: auto"
        [defaultImage]="imgPreloader"
        [lazyLoad]="entry.profileImage | imageSize" />
      <img
        *ngIf="!entry.profileImage"
        style="height: 4rem; width: auto"
        [src]="logoAvatar | imageSize" />
    </ng-template>
    <ng-template
      let-entry
      epTemplateKey="startDateCell">
      {{ entry.start | datePipe }}
    </ng-template>
    <ng-template
      let-entry
      epTemplateKey="endDateCell">
      {{ entry.end | datePipe }}
    </ng-template>
    <ng-template
      let-entry
      epTemplateKey="statusCell">
      <app-status-tag
        [iconClass]="entry.isPublished ? 'fa-regular fa-check' : 'fa-regular fa-xmark'"
        tagText="{{
          (entry.isPublished ? 'adminTable.statusActive' : 'adminTable.statusInactive') | translate
        }}"
        [bgColor]="entry.isPublished ? 'var(--appPrimaryColor)' : '#ff244c'" />
    </ng-template>

    <ng-template
      let-entry
      epTemplateKey="actionsCell">
      <div class="table-btn-actions-container">
        <app-button
          [buttonType]="buttonType.ROUND"
          [buttonSize]="buttonSize.MEDIUM"
          [buttonStyle]="buttonStyle.FILLED_SECONDARY"
          buttonRoundIcon="far fa-pen-to-square"
          (click)="edit($event, entry)" />
        <app-button
          [buttonType]="buttonType.ROUND"
          [buttonSize]="buttonSize.MEDIUM"
          [buttonStyle]="buttonStyle.FILLED_DANGER"
          [disabled]="entry.brandRole === 'owner'"
          [buttonTooltip]="
            entry.brandRole === 'owner'
              ? ('adminBrandTeam.tooltipForOwnerDeleteBtn' | translate)
              : ''
          "
          buttonRoundIcon="far fa-trash-can"
          (click)="remove($event, entry)" />
      </div>
    </ng-template>
  </admin-table>
</div>

<p-sidebar
  styleClass="custom-sidebar"
  [(visible)]="sidebarVisible"
  position="right"
  [showCloseIcon]="false">
  <app-sidebar-user-info-container
    [userId]="userIdForSidebar"
    (closeSidebar)="onCloseSidebar()">
  </app-sidebar-user-info-container>
</p-sidebar>
