import { Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MessageService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { DynamicDialogRef } from 'primeng/dynamicdialog';

import { ICourse, ITag, IHubTag, IEmail, EmailModuleType } from 'src/app/core/models';
import { HubsService, TagsService, CoursesService, EmailsService } from 'src/app/core/services';
import { HubsStore, TagsStore, UsersStore } from 'src/app/core/stores';
import { VIRTUAL_SCROLL_ITEM_SIZE } from 'src/app/core/utils';
import { Timestamp } from 'src/app/firebase';
import { preparedValueForLink } from 'src/app/shared';
import { CurrencyCode } from 'src/app/core/enums';
import { ButtonSize, ButtonStyle, CourseLandingPageType } from 'src/app/standalone';

@Component({
  selector: 'app-new-course',
  templateUrl: './new-course.component.html',
  styleUrls: ['./new-course.component.scss'],
})
export class NewCourseComponent implements OnInit {
  loading = true;
  isUpdating = false;
  selectedTags: string[] = [];
  form: UntypedFormGroup;
  possibleTags: ITag[];
  createAnotherControl = new UntypedFormControl(null);
  virtualScrollItemSize: number = VIRTUAL_SCROLL_ITEM_SIZE;
  currencies = Object.keys(CurrencyCode);
  buttonStyle = ButtonStyle;
  buttonSize = ButtonSize;

  constructor(
    private fb: UntypedFormBuilder,
    public tagsStore: TagsStore,
    private coursesService: CoursesService,
    private hubsStore: HubsStore,
    private messageService: MessageService,
    private hubsService: HubsService,
    private tagsService: TagsService,
    private usersStore: UsersStore,
    private translateService: TranslateService,
    private ref: DynamicDialogRef,
    private emailsService: EmailsService,
  ) {}

  get canUpdate(): boolean {
    return !this.isUpdating && this.form.valid && this.form.dirty;
  }

  get titleErrorMessage(): string {
    if (this.form.controls.title.errors?.maxlength) {
      return this.translateService.instant('application.forms.msxLengthErrorText');
    }

    return this.translateService.instant('application.forms.required');
  }

  async ngOnInit(): Promise<void> {
    this.createForm();
    await this.getTags();
    this.loading = false;
  }

  async create(): Promise<void> {
    this.isUpdating = true;

    try {
      const { title, tagline, accountingNumber, currency, tags } = this.form.getRawValue();
      const courseLink = await this.createLinkValue(title);

      const coursePayload: ICourse = {
        id: null,
        title,
        _title_: title.toLowerCase(),
        hubId: this.hubsStore.hub.id,
        isPaid: false,
        currency,
        isDisplayedPrice: false,
        price: null,
        strikePrice: null,
        isPublished: false,
        tagline,
        link: courseLink,
        ownerId: this.usersStore.userId,
        start: null,
        end: null,
        duration: null,
        featured: false,
        supportedLanguages: null,
        maintenance: false,
        banner: null,
        bannerGradient: null,
        featuredImage: null,
        video: null,
        internalVideo: null,
        description: null,
        features: null,
        requirements: null,
        targetGroup: null,
        tags,
        linkedin: null,
        facebook: null,
        xing: null,
        landingPageType: CourseLandingPageType.DEFAULT,
        landingPage: null,
        isDeleted: false,
        updatedAt: null,
        updatedBy: null,
        createdAt: Timestamp.now(),
        createdBy: this.usersStore.userId,
        accountingNumber,
      };

      const newCreatedCourse: ICourse = await this.coursesService.create(coursePayload);

      // adding default email templates to the event
      const emails: IEmail[] = await this.emailsService.getDefaultEmails(EmailModuleType.COURSES);
      await Promise.all(
        emails.map((email: IEmail) =>
          this.emailsService.attachEmailToCourse(newCreatedCourse.id, email),
        ),
      );

      this.isUpdating = false;
      this.messageService.add({
        severity: 'success',
        summary: this.translateService.instant('success'),
        detail: this.translateService.instant('adminNewCourse.successCreateCourse'),
        styleClass: 'custom-toast',
      });

      if (this.createAnotherControl.value) {
        this.form.reset({ createAnotherControl: true });
      } else {
        this.ref.close(coursePayload);
      }
    } catch (error) {
      console.log(error);
      this.isUpdating = false;
      this.messageService.add({
        severity: 'error',
        summary: this.translateService.instant('error'),
        detail: this.translateService.instant(`adminNewEvent.errorCreateEvent`),
        styleClass: 'custom-toast',
      });
    }
  }

  onCloseDialog(): void {
    this.ref.close();
  }

  private async createLinkValue(titleValue: string): Promise<string> {
    const linkValue = preparedValueForLink(titleValue);
    let linkExist = true;
    let counter = 0;
    while (linkExist) {
      if (counter === 0) {
        linkExist = await this.coursesService.checkCourseByLinkExists(linkValue).toPromise();
      } else {
        linkExist = await this.coursesService
          .checkCourseByLinkExists(`${linkValue}-${counter}`)
          .toPromise();
      }

      if (linkExist) {
        counter += 1;
      } else {
        if (counter === 0) {
          return linkValue;
        } else {
          return `${linkValue}-${counter}`;
        }
      }
    }
  }

  private createForm(): void {
    this.form = this.fb.group({
      title: [null, [Validators.required, Validators.maxLength(100)]],
      tagline: [null, [Validators.maxLength(120)]],
      accountingNumber: [null],
      currency: [null, Validators.required],
      tags: [null],
    });
  }

  private async getTags(): Promise<void> {
    const hubTags: IHubTag[] = await this.hubsService.getTagsRelation(this.hubsStore.hubId);
    const hubTagIds: string[] = hubTags.map((hubTag) => hubTag.tagId);
    this.possibleTags = await this.tagsService.getByIdsOrderedByTitle(hubTagIds);
  }
}
