import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  signal,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DialogService } from 'primeng/dynamicdialog';
import { TranslateService } from '@ngx-translate/core';

import { SharedModule } from 'src/app/shared';
import { IChapter } from 'src/app/core/models';
import { ButtonComponent, LogoMediaFormComponent } from 'src/app/standalone/shared/components';
import { ButtonSize, ButtonStyle, ManageTranslationsComponent } from 'src/app/standalone';

@Component({
  selector: 'app-chapter-details',
  standalone: true,
  imports: [CommonModule, SharedModule, ButtonComponent, LogoMediaFormComponent],
  templateUrl: './chapter-details.component.html',
  styleUrls: ['./chapter-details.component.scss'],
})
export class ChapterDetailsComponent implements OnChanges, OnInit {
  @Input() chapter: IChapter;
  @Output() closeSidebar = new EventEmitter<void>();
  @Output() updateChapter = new EventEmitter<IChapter>();

  form: FormGroup;
  buttonStyle = ButtonStyle;
  buttonSize = ButtonSize;
  supportedLangs = signal<string[]>(null);

  constructor(
    private fb: FormBuilder,
    private dialogService: DialogService,
    private translateService: TranslateService,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (this.chapter && changes.chapter.previousValue?.id !== changes.chapter.currentValue?.id) {
      this.updateForm();
    }
  }

  ngOnInit(): void {
    this.supportedLangs.set(this.translateService.langs);
    this.createForm();
    this.updateForm();
  }

  onClose(): void {
    this.closeSidebar.emit();
  }

  setBannerValue(value: File): void {
    this.form.controls.banner.setValue(value);
    this.form.controls.banner.markAsDirty();
  }

  onOpenManageTranslationsDialog(): void {
    this.dialogService.open(ManageTranslationsComponent, {
      closable: false,
      styleClass: 'reset-dialog-styles',
    });
  }

  onSave(): void {
    if (this.form.invalid) {
      return;
    }
    const values = this.form.getRawValue();
    const chapterForUpdate: IChapter = {
      ...this.chapter,
      ...values,
    };
    this.updateChapter.emit(chapterForUpdate);
    this.closeSidebar.emit();
  }

  private createForm(): void {
    this.form = this.fb.group({
      banner: null,
      title: [null, Validators.required],
      shortDescription: null,
      description: null,
      isSendEmailAfterChapterCompleted: false,
      supportedLanguages: null,
    });
  }

  private updateForm(): void {
    this.form.patchValue({ ...this.chapter });
  }
}
