import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';

import { StripeService } from 'src/app/core/services';

export const initStripeConnectInstanceResolver: ResolveFn<boolean> = (): boolean => {
  const stripeService = inject(StripeService);
  stripeService.loadStripeConnectAndInitialize();

  return true;
};
