import { Component, ElementRef, inject, OnInit, Renderer2, signal } from '@angular/core';
import { ConnectElementTagName } from '@stripe/connect-js';

import { StripeService } from 'src/app/core/services';

@Component({
  selector: 'app-report-base',
  standalone: true,
  templateUrl: './report-base.component.html',
  styleUrl: './report-base.component.scss',
})
export class ReportBaseComponent implements OnInit {
  protected stripeEmbedComponentName = signal<ConnectElementTagName>(null);

  #containerElement = signal<HTMLDivElement>(null);
  #stripeService = inject(StripeService);
  #renderer2 = inject(Renderer2);
  #elementRef = inject(ElementRef);

  ngOnInit(): void {
    this.#containerElement = this.#renderer2.createElement('div');
    this.#renderer2.setStyle(this.#containerElement, 'padding', '2rem');
    this.#renderer2.setStyle(this.#containerElement, 'background', 'var(--layer-01)');
    this.#renderer2.setStyle(this.#containerElement, 'border-radius', '1.25rem');
    this.appendStripeEmbedComponent();
  }

  appendStripeEmbedComponent(): void {
    const embedComponent = this.#stripeService
      .stripeConnectInstance()
      .create(this.stripeEmbedComponentName());
    this.#renderer2.appendChild(this.#containerElement, embedComponent);
    this.#renderer2.appendChild(this.#elementRef.nativeElement, this.#containerElement);
  }
}
