export const STRIPE_EMBED_PAYMENTS_COMPONENT = {
  payments: {
    enabled: true,
    features: {
      refund_management: true,
      dispute_management: true,
      capture_payments: true,
      destination_on_behalf_of_charge_management: false,
    },
  },
};
