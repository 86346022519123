<nav>
  <ul class="list">
    <li class="list-item">
      <a
        class="item-link"
        routerLink="event-details"
        routerLinkActive="active">
        <span>{{ 'adminUser.tabEventDetails' | translate }}</span>
        <i class="fa-regular fa-chevron-right chevron-icon"></i>
      </a>
    </li>
    <li class="list-item">
      <a
        class="item-link"
        routerLink="profile"
        routerLinkActive="active">
        <span>{{ 'adminUser.tabProfile' | translate }}</span>
        <i class="fa-regular fa-chevron-right chevron-icon"></i>
      </a>
    </li>
    <li class="list-item">
      <a
        class="item-link"
        routerLink="account"
        routerLinkActive="active">
        <span>{{ 'adminUser.tabAccount' | translate }}</span>
        <i class="fa-regular fa-chevron-right chevron-icon"></i>
      </a>
    </li>
    <li class="list-item">
      <a
        class="item-link"
        routerLink="appearance"
        routerLinkActive="active">
        <span>{{ 'adminUser.tabAppearance' | translate }}</span>
        <i class="fa-regular fa-chevron-right chevron-icon"></i>
      </a>
    </li>
    <li class="list-item">
      <a
        class="item-link"
        routerLink="more"
        routerLinkActive="active">
        <span>{{ 'adminUser.tabMore' | translate }}</span>
        <i class="fa-regular fa-chevron-right chevron-icon"></i>
      </a>
    </li>
  </ul>
</nav>
