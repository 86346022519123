import { AssetEventTypes, AssetFileTypes, AssetType } from 'src/app/core/enums';
import { Timestamp } from 'src/app/firebase';
import { IUserCourseTracking } from '../user';
import { ITag } from './../tag/tag.model';

export interface IAsset {
  id: string;
  hubId: string;
  title: string;
  _title_: string;
  shortDescription: string;
  link: string;
  type: AssetType;
  file: File | string;
  fileType: AssetFileTypes;
  fileName: string;
  fileSize: number;
  videoLink: string;
  videoDuration: number;
  internalVideo: File | string;
  isGlobal: boolean;
  brands: string[];
  featured: boolean;
  isVisible: boolean;
  description: string;
  status: AssetStatus;
  tags: string[];
  eventAssetStartDate: Timestamp;
  eventAssetEndDate: Timestamp;
  instructors: string[];
  eventType: AssetEventTypes;
  isRecording: boolean;
  eventAssetMeetingRoomUrl: string;
  eventAssetMeetingRoomName: string;
  breakoutRoomDailySetting: boolean;
  cameraDailySetting: boolean;
  microphoneDailySetting: boolean;
  screenSharingDailySetting: boolean;
  textChatDailySetting: boolean;
  peopleListingDailySetting: boolean;
  supportedLanguages: string[];
  pictureInPictureDailySetting: boolean;
  handRisingDailySetting: boolean;
  emojiReactionDailySetting: boolean;
  updatedAt?: Timestamp;
  updatedBy?: string;
  createdAt?: Timestamp;
  createdBy?: string;
  image?: File | string;
  tagsList?: ITag[];
  brandName?: string;
  userCourseTracking?: IUserCourseTracking;
  isActionBtnShowingSpinner?: boolean;
}

export enum AssetStatus {
  PUBLISHED = 'published',
  DRAFT = 'draft',
}
