import { Component, OnInit } from '@angular/core';

import { ReportBaseComponent } from '../report-base/report-base.component';

@Component({
  selector: 'app-report-account-onboarding',
  standalone: true,
  templateUrl: './report-account-onboarding.component.html',
  styleUrl: './report-account-onboarding.component.scss',
})
export class ReportAccountOnboardingComponent extends ReportBaseComponent implements OnInit {
  constructor() {
    super();
  }

  async ngOnInit(): Promise<void> {
    this.stripeEmbedComponentName.set('account-onboarding');
    super.ngOnInit();
  }
}
