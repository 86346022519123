import { Injectable } from '@angular/core';

import { Firestore, IFirestore } from 'src/app/firebase';
import { API_ROUTES as apiRoutes } from 'src/app/shared';
import { AlgoliaSearchResult, IUser, IUserEvent, IUserHub } from '../../models';
import { AlgoliaService } from '../algolia/algolia.service';

@Injectable({
  providedIn: 'root',
})
export class EventUserService {
  private firestore: IFirestore;

  constructor(private algoliaService: AlgoliaService) {
    this.firestore = Firestore();
  }

  async getAllEventUsers(eventId: string): Promise<IUserEvent[]> {
    const query = await this.firestore
      .collection(apiRoutes.userEvents)
      .where('eventId', '==', eventId)
      .get();

    const eventUsers: IUserEvent[] = query.docs.map((doc) => doc.data() as IUserEvent);

    return eventUsers;
  }

  async getUnattachedUsersFromAlgolia(
    hubId: string,
    eventId: string,
    pageIndex: number,
    pageSize: number,
    searchTerm: string,
  ): Promise<AlgoliaSearchResult<IUser>> {
    const attachedUsers: IUserEvent[] = await this.getAllEventUsers(eventId);
    const attachedUsersIds: string[] = attachedUsers.map((user: IUserEvent) => user.userId);
    const facetFilters = attachedUsersIds.map((id) => `userId:-${id}`).join(',');

    const resultFromAlgolia: AlgoliaSearchResult<IUserHub> =
      await this.algoliaService.search<IUserHub>(
        apiRoutes.userHubs,
        `${searchTerm} ${hubId}`,
        pageSize,
        pageIndex * pageSize,
        '',
        facetFilters,
      );

    const result: AlgoliaSearchResult<IUser> = {
      total: resultFromAlgolia.total,
      results: resultFromAlgolia.results.map((item: any) => item.user),
    };

    return result;
  }
}
