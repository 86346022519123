<div class="sidebar-container">
  <img
    [ngSrc]="logo()"
    class="logo"
    width="48"
    height="20"
    priority />
  <nav class="nav">
    <ul class="list">
      <li
        class="list-item"
        [class.active]="isActiveRoute('dashboard')">
        <a
          class="item-link"
          routerLink="/{{ hub() ? hubsStore.useHubUrl : '' }}/admin/dashboard">
          <i class="fa-regular fa-house item-icon"></i>
          <span class="item-text">{{ 'adminMenu.dashboard' | translate }}</span>
        </a>
      </li>
      @if (hub().events) {
        <li
          class="list-item"
          [class.active]="isActiveRoute('event')">
          <a
            class="item-link"
            routerLink="/{{ hub() ? hubsStore.useHubUrl : '' }}/admin/events">
            <i class="far fa-screen-users item-icon"></i>
            <span class="item-text">{{ 'adminMenu.events' | translate }}</span>
          </a>
        </li>
      }
      @if (hub().academy) {
        <li
          class="list-item"
          [class.active]="isActiveRoute('courses')">
          <a
            class="item-link"
            routerLink="/{{ hub() ? hubsStore.useHubUrl : '' }}/admin/courses">
            <i class="far fa-graduation-cap item-icon"></i>
            <span class="item-text">{{ 'adminMenu.courses' | translate }}</span>
          </a>
        </li>
      }
      <li
        class="list-item"
        [class.active]="isActiveRoute('library')">
        <a
          class="item-link"
          routerLink="/{{ hub ? hubsStore.useHubUrl : '' }}/admin/library">
          <i class="far fa-photo-video item-icon"></i>
          <span class="item-text">{{ 'adminMenu.library' | translate }}</span>
        </a>
      </li>
      <li
        class="list-item"
        [class.active]="isActiveRoute('user')">
        <a
          class="item-link"
          routerLink="/{{ hub() ? hubsStore.useHubUrl : '' }}/admin/users">
          <i class="fak fa-lox-attendees item-icon"></i>
          <span class="item-text">{{ 'adminMenu.users' | translate }}</span>
        </a>
      </li>
      @if (hub().brands) {
        <li
          class="list-item"
          [class.active]="isActiveRoute('brand')">
          <a
            class="item-link"
            routerLink="/{{ hub() ? hubsStore.useHubUrl : '' }}/admin/brands">
            <i class="fak fa-lox-crown item-icon"></i>
            <span class="item-text">{{ 'adminMenu.brands' | translate }}</span>
          </a>
        </li>
      }
      <li
        class="list-item"
        [class.active]="isActiveRoute('reports')">
        <a
          class="item-link"
          routerLink="/{{ hub() ? hubsStore.useHubUrl : '' }}/admin/reports">
          <i class="fa-solid fa-chart-mixed item-icon"></i>
          <span class="item-text">{{ 'adminMenu.reports' | translate }}</span>
        </a>
      </li>
      <li
        class="list-item"
        [class.active]="isActiveRoute('hub-settings')">
        <a
          class="item-link"
          routerLink="/{{ hub() ? hubsStore.useHubUrl : '' }}/admin/hub-settings">
          <i class="far fa-cog item-icon"></i>
          <span class="item-text">{{ 'adminMenu.hub' | translate }}</span>
        </a>
      </li>
    </ul>
    <ul class="list">
      @if (isShowHelpIcon) {
        <li class="list-item">
          <a
            class="item-link"
            (click)="onHelp()">
            <i class="fa-regular fa-comments-question-check item-icon"></i>
            <span class="item-text">{{ 'adminMenu.help' | translate }}</span>
          </a>
        </li>
      }
      @if (isShowGlobalItem) {
        <li
          class="list-item"
          [class.active]="isActiveRoute('system-settings')">
          <a
            class="item-link"
            routerLink="/{{ hub() ? hubsStore.useHubUrl : '' }}/admin/system-settings">
            <i class="fa-regular fa-globe item-icon"></i>
            <span class="item-text">{{ 'adminMenu.systemSettings' | translate }}</span>
          </a>
        </li>
      }
    </ul>
  </nav>
</div>
