@if (loading()) {
  <app-loader></app-loader>
} @else {
  <div class="admin-edit-session-assets-container">
    <div class="c-mb-4">
      <admin-table-header-navbar title="{{ 'adminEventSessionSidebar.assetsNavItem' | translate }}">
        <div class="flex align-items-center gap-2">
          <app-button
            buttonText="{{ 'adminLibraryList.attachAssets' | translate }}"
            buttonIconLeft="fa fa-paperclip"
            [buttonStyle]="buttonStyle.FILLED_SECONDARY"
            [buttonSize]="buttonSize.MEDIUM"
            [disabled]="loadingEntries()"
            (click)="openAttachAssetsDialog()" />
          <app-button
            buttonText="{{ 'adminLibraryList.addBtnTitle' | translate }}"
            buttonIconLeft="far fa-plus"
            [buttonSize]="buttonSize.MEDIUM"
            [disabled]="loadingEntries()"
            (click)="onNewAsset()" />
        </div>
      </admin-table-header-navbar>
    </div>
    <div class="admin-edit-session-assets-table-container">
      <admin-table-filter-navbar
        (onSearchTermChange)="onSearchTermChange($event)"></admin-table-filter-navbar>

      <admin-table
        [loading]="loadingEntries()"
        [lazy]="false"
        [columns]="columns()"
        [entries]="entries()"
        [pageSize]="pageSize()"
        [totalRecords]="totalRecords()"
        emptyStateKey="adminLibraryList.emptyState"
        emptyStateIcon="far fa-photo-video"
        (rowClicked)="onRowClicked($event)">
        <ng-template
          let-entry
          epTemplateKey="titleCell">
          <div class="title-cell">
            <div>
              <img
                *ngIf="entry.image"
                class="banner"
                [defaultImage]="imgPreloader"
                [lazyLoad]="entry.image | imageSize" />
              <div
                *ngIf="!entry.image"
                class="placeholder-container">
                <img
                  class="placeholder"
                  [src]="getImagePlaceholder(entry) | imageSize" />
              </div>
            </div>
            <p class="asset-title">{{ entry.title }}</p>
          </div>
        </ng-template>
        <ng-template
          let-entry
          epTemplateKey="statusCell">
          <i
            class="{{
              (entry.isGlobal ? 'far fa-circle-check' : 'far fa-circle-xmark') | translate
            }}"></i>
        </ng-template>

        <ng-template
          let-entry
          epTemplateKey="actionsCell">
          <div class="table-btn-actions-container">
            <app-button
              [buttonType]="buttonType.ROUND"
              [buttonSize]="buttonSize.MEDIUM"
              [buttonStyle]="buttonStyle.FILLED_SECONDARY"
              buttonRoundIcon="far fa-pen-to-square"
              (click)="edit($event, entry)" />
            <app-button
              [buttonType]="buttonType.ROUND"
              [buttonSize]="buttonSize.MEDIUM"
              [buttonStyle]="buttonStyle.FILLED_DANGER"
              buttonRoundIcon="far fa-trash-can"
              (click)="remove($event, entry)" />
          </div>
        </ng-template>
      </admin-table>
    </div>
  </div>
}

<p-toast>
  <ng-template
    let-message
    pTemplate="message">
    <app-toast
      [severity]="message.severity"
      [summary]="message.summary"
      [detail]="message.detail">
    </app-toast>
  </ng-template>
</p-toast>
