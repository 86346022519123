<ng-container *ngIf="loading">
  <app-loader></app-loader>
</ng-container>

<div
  *ngIf="!loading"
  class="container">
  <div class="c-mb-4">
    <admin-table-header-navbar>
      <app-button
        buttonText="{{ 'adminBrands.addProduct' | translate }}"
        buttonIconLeft="far fa-plus"
        [buttonSize]="buttonSize.MEDIUM"
        [disabled]="loadingEntries"
        (click)="onAddProduct()" />
    </admin-table-header-navbar>
  </div>

  <admin-table-filter-navbar (onSearchTermChange)="searchValue = $event">
  </admin-table-filter-navbar>

  <admin-table
    [loading]="loadingEntries"
    [columns]="columns"
    [entries]="entries"
    [paginator]="true"
    [lazy]="false"
    [searchValue]="searchValue"
    [searchFilterFields]="searchFilterFields"
    emptyStateKey="brandProducts.emptyState"
    emptyStateIcon="fak fa-lox-crown"
    (rowClicked)="onRowClicked($event)">
    <ng-template
      let-entry
      epTemplateKey="logoCell">
      <img
        *ngIf="entry.image"
        style="height: 4rem; width: auto"
        [defaultImage]="imgPreloader"
        [lazyLoad]="entry.image | imageSize" />
      <img
        *ngIf="!entry.image"
        style="height: 4rem; width: auto"
        [src]="logoAvatar" />
    </ng-template>
    <ng-template
      let-entry
      epTemplateKey="startDateCell">
      {{ entry.start | datePipe }}
    </ng-template>
    <ng-template
      let-entry
      epTemplateKey="endDateCell">
      {{ entry.end | datePipe }}
    </ng-template>

    <ng-template
      let-entry
      epTemplateKey="actionsCell">
      <div class="table-btn-actions-container">
        <app-button
          [buttonType]="buttonType.ROUND"
          [buttonSize]="buttonSize.MEDIUM"
          [buttonStyle]="buttonStyle.FILLED_SECONDARY"
          buttonRoundIcon="far fa-pen-to-square"
          (click)="edit($event, entry)" />
        <app-button
          [buttonType]="buttonType.ROUND"
          [buttonSize]="buttonSize.MEDIUM"
          [buttonStyle]="buttonStyle.FILLED_DANGER"
          buttonRoundIcon="far fa-trash-can"
          (click)="remove($event, entry)" />
      </div>
    </ng-template>
  </admin-table>
</div>
