import { Component, inject, signal } from '@angular/core';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { DividerModule } from 'primeng/divider';
import { TranslateService } from '@ngx-translate/core';
import { Chart } from 'chart.js';

import { ICourse } from 'src/app/core/models';
import { CoursesStore } from 'src/app/core/stores';
import {
  StatCardComponent,
  StatChartComponent,
  StatSingleProgressBarComponent,
} from 'src/app/standalone/shared/components/stats';
import { IStatCard, IStatChart } from 'src/app/standalone/shared/models';

@Component({
  selector: 'app-course-dashboard',
  standalone: true,
  imports: [
    StatCardComponent,
    DividerModule,
    StatChartComponent,
    StatSingleProgressBarComponent,
    CommonModule,
  ],
  templateUrl: './course-dashboard.component.html',
  styleUrl: './course-dashboard.component.scss',
  providers: [CurrencyPipe],
})
export class CourseDashboardComponent {
  stats = signal<IStatCard[]>(null);
  charts = signal<IStatChart[]>(null);

  #route = inject(ActivatedRoute);
  #currencyPipe = inject(CurrencyPipe);
  #coursesStore = inject(CoursesStore);
  #translateService = inject(TranslateService);

  get course(): ICourse {
    return this.#coursesStore.adminCourse;
  }

  ngOnInit(): void {
    this.initStats();
    this.initCharts();
  }

  private initStats(): void {
    this.stats.set([
      {
        iconClass: 'fa-regular fa-users',
        text: 'courseDashboard.subscribers',
        value: this.#route.snapshot.data['countOfCourseSubscribers'],
        isShowTooltip: true,
        tooltipText: this.#translateService.instant('courseDashboard.subscribersTooltip'),
      },
      {
        iconClass: 'fa-regular fa-money-bill',
        text: 'courseDashboard.totalSales',
        value: this.#route.snapshot.data['courseStripeReport'].totalSales,
        isShowTooltip: true,
        tooltipText: this.#translateService.instant('courseDashboard.totalSalesTooltip'),
      },
      {
        iconClass: 'fa-regular fa-money-bill-trend-up',
        text: 'courseDashboard.totalRevenue',
        value: this.#currencyPipe.transform(
          this.#route.snapshot.data['courseStripeReport'].totalRevenue.toString(),
          this.course.currency,
        ),
        isShowTooltip: true,
        tooltipText: this.#translateService.instant('courseDashboard.totalRevenueTooltip'),
      },
      {
        iconClass: 'fa-regular fa-play',
        text: 'courseDashboard.viewers',
        value: this.#route.snapshot.data['courseViewers'],
        isShowTooltip: true,
        tooltipText: this.#translateService.instant('courseDashboard.viewersTooltip'),
      },
    ]);
  }

  private initCharts(): void {
    const documentStyle = getComputedStyle(document.documentElement);
    this.charts.set([
      {
        statName: this.#translateService.instant('courseDashboard.weeklyEnrollmentsGraphName'),
        type: 'line',
        data: {
          labels: [
            'Week 1',
            'Week 2',
            'Week 3',
            'Week 4',
            'Week 5',
            'Week 6',
            'Week 7',
            'Week 8',
            'Week 9',
          ],
          datasets: [
            {
              data: [45, 50, 47, 60, 55, 65, 70, 80, 85],
              label: this.#translateService.instant('courseDashboard.weeklyEnrollmentsGraphLegend'),
              fill: false,
              tension: 0.5,
              borderColor: documentStyle.getPropertyValue('--brand-500'),
              pointRadius: 3,
              pointBackgroundColor: 'transparent',
              pointBorderColor: documentStyle.getPropertyValue('--brand-500'),
            },
          ],
        },
        options: {
          maintainAspectRatio: false,
          scales: {
            y: {
              beginAtZero: true,
            },
          },
          plugins: {
            legend: {
              labels: {
                generateLabels: function (chart) {
                  const original = Chart.defaults.plugins.legend.labels.generateLabels;
                  const labels = original.call(this, chart);
                  labels.forEach((label) => {
                    label.fillStyle = 'rgba(0, 0, 0, 0)';
                  });
                  return labels;
                },
              },
            },
          },
        },
        chartStyles: { height: '19rem' },
      },
      {
        statName: this.#translateService.instant('courseDashboard.revenueTrendGraphName'),
        type: 'bar',
        data: {
          labels: [
            'Week 1',
            'Week 2',
            'Week 3',
            'Week 4',
            'Week 5',
            'Week 6',
            'Week 7',
            'Week 8',
            'Week 9',
          ],
          datasets: [
            {
              data: [2250, 2500, 2400, 2800, 2600, 3000, 3300, 3700, 4200],
              label: this.#translateService.instant('courseDashboard.revenueTrendGraphLegend'),
              borderColor: documentStyle.getPropertyValue('--brand-500'),
              backgroundColor: documentStyle.getPropertyValue('--brand-500'),
            },
          ],
        },
        options: {
          maintainAspectRatio: false,
          scales: {
            y: {
              beginAtZero: true,
            },
          },
        },
        chartStyles: { height: '19rem' },
      },
    ]);
  }
}
