<ng-container *ngIf="loading">
  <app-loader></app-loader>
</ng-container>

<div
  *ngIf="!loading"
  class="admin-developer-settings-container">
  <div class="admin-developer-settings-table-container">
    <admin-table-header-navbar
      [title]="'adminDeveloperSettings.title' | translate"></admin-table-header-navbar>

    <div class="c-my-4">
      <p-divider styleClass="custom-divider-horizontal"></p-divider>
    </div>

    <div class="main-container">
      <div class="setting-item">
        <h5>{{ 'adminDeveloperSettings.algoliaDesc' | translate }}</h5>
        <app-button
          buttonText="{{ 'adminDeveloperSettings.algoliaBtnLabel' | translate }}"
          [buttonSize]="buttonSize.MEDIUM"
          (click)="algoliaInit()" />
        <p-divider styleClass="custom-divider-horizontal"></p-divider>
      </div>

      <div class="setting-item c-mt-4">
        <h5>{{ 'adminDeveloperSettings.algoliaDesc' | translate }}</h5>
        <app-button
          buttonText="{{ 'adminDeveloperSettings.signOutAllUsersBtnLabel' | translate }}"
          [buttonSize]="buttonSize.MEDIUM"
          (click)="signOutAllUsers()" />
        <p-divider styleClass="custom-divider-horizontal"></p-divider>
      </div>

      <div class="setting-item c-mt-4">
        <h5>Make all chapters inside course published</h5>
        <app-button
          buttonText="Publish chapters"
          [buttonSize]="buttonSize.MEDIUM"
          (click)="onPublishChapters()" />
        <p-divider styleClass="custom-divider-horizontal"></p-divider>
      </div>

      <div class="setting-item c-mt-4">
        <h5>Make all assets in the app published</h5>
        <app-button
          buttonText="Publish assets"
          [buttonSize]="buttonSize.MEDIUM"
          (click)="onPublishAssets()" />
        <p-divider styleClass="custom-divider-horizontal"></p-divider>
      </div>

      <div class="setting-item c-mt-4">
        <h5>Add isDeleted property to all events</h5>
        <app-button
          buttonText="Add isDeleted property to events"
          [buttonSize]="buttonSize.MEDIUM"
          (click)="onAddIsDeletedPropertyToEvents()" />
        <p-divider styleClass="custom-divider-horizontal"></p-divider>
      </div>

      <div class="setting-item c-mt-4">
        <h5>Add isDeleted property to all courses</h5>
        <app-button
          buttonText="Add isDeleted property to courses"
          [buttonSize]="buttonSize.MEDIUM"
          (click)="onAddIsDeletedPropertyToCourses()" />
        <p-divider styleClass="custom-divider-horizontal"></p-divider>
      </div>

      <div class="setting-item c-mt-4">
        <h5>Get diff between auth collection and users collection</h5>
        <app-button
          buttonText="Get diff"
          [buttonSize]="buttonSize.MEDIUM"
          (click)="onGetDiffBetweenAuthAndUsersCollections()" />

        @if (isShowDiff()) {
          <p>The app has {{ allUsers()?.length }} documents in users collection</p>
          <p>
            The app has {{ allDocumentsFromAuthCollection()?.length }} documents in Auth collection
          </p>
          <p>The difference in collections is {{ usersDiff().length }} documents</p>
          @for (user of usersDiff(); track $index) {
            <p>{{ user.email }}</p>
          }
        }
        <p-divider styleClass="custom-divider-horizontal"></p-divider>
      </div>
    </div>
  </div>
</div>
