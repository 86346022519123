import { Timestamp } from 'src/app/firebase';
import { ITimeZone } from '..';
import { EventTypes } from 'src/app/core/enums';
import { ITag } from '../tag/tag.model';

export interface IEvent {
  id: string;
  hubId: string;
  user: string;
  isPublished: boolean;
  publicationDate?: Timestamp | null;
  title: string;
  _title_: string;
  shortDescription: string;
  description: string;
  tagline: string;
  location: string;
  timezone: ITimeZone;
  logoDark: any;
  logoLight: any;
  banner: any;
  icon: any;
  start: Timestamp | any;
  startTime?: Date;
  end: Timestamp | any;
  endTime?: Date;
  tags: string[];
  tagsList?: ITag[];
  link: string;
  primaryColor: string;
  accentColor: string;
  linkedin: string;
  facebook: string;
  xing: string;
  email: string;
  emailName: string;
  featured: boolean;
  featuredImage: File | string;
  isTicketRestrictions: boolean;
  isDeleted: boolean;
  updatedAt: any;
  updatedBy: string;
  createdAt: any;
  createdBy: string;
  appointmentFeature: boolean;
  appointmentAttendeesStatus?: boolean;
  appointmentSpeakersStatus?: boolean;
  appointmentBrandPeopleStatus?: boolean;
  eventbriteEventId: string;
  eventbriteToken: string;
  onlineVideoMeetingStatus: boolean;
  duration: number;
  eventbriteButton?: string;
  eventbriteSync: boolean;
  eventRegistrationConfirmationEmail?: boolean;
  eventInvitationDeclinedEmail?: boolean;
  itemsLeft?: number; // TODO check if really needed
  isOnDemandMode?: boolean;
  isOnDemandComingSoon?: boolean;
  isOnMaintenance?: boolean;
  isMatchmaking?: boolean;
  isPrivateEvent?: boolean;
  docRef?: any;
  hideChat: boolean;
  hideChatPreviewScreen: boolean;
  hideEventSearch: boolean;
  hideRecommendedSessions: boolean;
  hideBrandLevelNames: boolean;
  isEnableDirectBuy: boolean;
  accountingNumber: string;
  eventType: EventTypes;
  scheduleEventCheckin: boolean;
  scheduleEventDate?: Timestamp;
}
