<ng-container *ngIf="loading">
  <app-loader></app-loader>
</ng-container>

<div
  *ngIf="!loading"
  class="admin-brands-container">
  <div class="admin-brands-table-container">
    <div class="c-mb-4">
      <admin-table-header-navbar [title]="'adminBrands.title' | translate">
        <!-- NOTE: ↓ now commented out in connection with the new logic, this logic has not yet been removed -->
        <!-- <button
          *ngIf="hubsStore.hub"
          class="attach"
          pButton
          pRipple
          [label]="'adminEventBrands.attachBrands' | translate"
          icon="far fa-grid-2-plus"
          [disabled]="loadingEntries"
          (click)="openAttachBrandsDialog()">
        </button> -->
        <!-- NOTE: ↑ -->
        <app-button
          buttonText="{{ 'adminBrands.addBtnLabel' | translate }}"
          buttonIconLeft="far fa-plus"
          [buttonSize]="buttonSize.MEDIUM"
          (click)="openNewBrandDialog()" />
      </admin-table-header-navbar>
    </div>

    <admin-table-filter-navbar
      [searchActive]="!appStore.generalSystemSettings.enableEncryption"
      (onSearchTermChange)="onSearchTermChange($event)">
    </admin-table-filter-navbar>

    <admin-table
      [lazyLoading]="loadingEntries"
      [columns]="columns"
      [entries]="entries"
      [pageSize]="pageSize"
      [totalRecords]="totalRecords"
      scssClass="show-row-border-bottom"
      emptyStateKey="adminBrands.emptyState"
      emptyStateIcon="fak fa-lox-crown"
      (lazyLoad)="fetchEntries($event)"
      (rowClicked)="onRowClicked($event)">
      <ng-template
        let-entry
        epTemplateKey="nameCell">
        <div class="name-cell">
          <div>
            <img
              *ngIf="entry.logo"
              class="banner"
              [defaultImage]="imgPreloader"
              [lazyLoad]="entry.logo | imageSize" />
            <div
              *ngIf="!entry.logo"
              class="placeholder-container">
              <img
                class="placeholder"
                [src]="logoAvatar | imageSize" />
            </div>
          </div>
          <p class="brand-name">{{ entry.name }}</p>
        </div>
      </ng-template>

      <ng-template
        let-entry
        epTemplateKey="actionsCell">
        <div class="table-btn-actions-container">
          <app-button
            [buttonType]="buttonType.ROUND"
            [buttonSize]="buttonSize.MEDIUM"
            [buttonStyle]="buttonStyle.FILLED_SECONDARY"
            buttonRoundIcon="far fa-pen-to-square"
            (click)="edit($event, entry)" />
          @if (hub) {
            <app-button
              [buttonType]="buttonType.ROUND"
              [buttonSize]="buttonSize.MEDIUM"
              [buttonStyle]="buttonStyle.FILLED_DANGER"
              buttonRoundIcon="far fa-trash-can"
              (click)="remove($event, entry)" />
          }
        </div>
      </ng-template>
    </admin-table>
  </div>
</div>
