import { Component, OnInit, signal } from '@angular/core';
import {
  AssetStatus,
  ChapterStatus,
  IAsset,
  IChapter,
  ICourse,
  IEvent,
  IUser,
} from 'src/app/core/models';

import {
  AlgoliaService,
  AuthenticationService,
  CourseChaptersService,
  CoursesService,
  EventsService,
  LibraryService,
  UsersService,
} from 'src/app/core/services';
import { ButtonSize } from 'src/app/standalone';

@Component({
  selector: 'app-developer-settings',
  templateUrl: './developer-settings.component.html',
  styleUrls: ['./developer-settings.component.scss'],
})
export class DeveloperSettingsComponent implements OnInit {
  loading = true;
  signOutAllUserLoading = false;
  algoliaLoading = false;
  buttonSize = ButtonSize;
  allUsers = signal<IUser[]>(null);
  allDocumentsFromAuthCollection = signal<unknown[]>(null);
  isShowDiff = signal(false);
  usersDiff = signal<unknown[]>(null);

  constructor(
    private authenticationService: AuthenticationService,
    private algoliaService: AlgoliaService,
    private coursesService: CoursesService,
    private courseChaptersService: CourseChaptersService,
    private libraryService: LibraryService,
    private eventsService: EventsService,
    private usersService: UsersService,
  ) {}

  ngOnInit(): void {
    this.loading = false;
  }

  async algoliaInit(): Promise<void> {
    this.algoliaLoading = true;
    await this.algoliaService.reindex();
    this.algoliaLoading = false;
  }

  async signOutAllUsers(): Promise<void> {
    this.signOutAllUserLoading = true;
    await this.authenticationService.signOutAllUsers();
    this.signOutAllUserLoading = false;
  }

  async onPublishChapters(): Promise<void> {
    const courses: ICourse[] = await this.coursesService.getAllWithoutLimitations();
    const filteredCourses: ICourse[] = courses.filter((course: ICourse) => course?.id);
    await Promise.all(
      filteredCourses.map(async (filteredCourse: ICourse) => {
        const chapters: IChapter[] = await this.courseChaptersService.getAllByCourseId(
          filteredCourse.id,
        );
        await Promise.all(
          chapters.map((chapter: IChapter) =>
            this.courseChaptersService.updateChapter(filteredCourse.id, {
              ...chapter,
              status: ChapterStatus.PUBLISHED,
            }),
          ),
        );

        return filteredCourse;
      }),
    );

    console.log('Chapters successfully updated');
  }

  async onPublishAssets(): Promise<void> {
    const assets: IAsset[] = await this.libraryService.getAllWithoutLimitations();
    await Promise.all(
      assets.map((asset: IAsset) =>
        this.libraryService.update(asset.hubId, { ...asset, status: AssetStatus.PUBLISHED }),
      ),
    );

    console.log('Assets successfully updated');
  }

  async onAddIsDeletedPropertyToEvents(): Promise<void> {
    const events: IEvent[] = await this.eventsService.getAllWithoutAnyRestrictions();
    console.log(events, 'events before update');
    await Promise.all(
      events.map((event: IEvent) =>
        this.eventsService.update(event.id, { ...event, isDeleted: false }),
      ),
    );
    console.log(events, 'events after update');
  }

  async onAddIsDeletedPropertyToCourses(): Promise<void> {
    const courses: ICourse[] = await this.coursesService.getAllWithoutLimitations();
    console.log(courses, 'courses before update');
    await Promise.all(
      courses.map((course: ICourse) =>
        this.coursesService.update(course.id, { ...course, isDeleted: false }, false),
      ),
    );
    console.log(courses, 'courses after update');
  }

  async onGetDiffBetweenAuthAndUsersCollections(): Promise<void> {
    console.log('start getting diff flow');

    const allUsers: IUser[] = await this.usersService.getAll();
    this.allUsers.set(allUsers);
    const allDocumentsFromAuthCollection: { msg: string; data: unknown[] } =
      await this.authenticationService.getAllDocumentsFromAuthCollection();
    this.allDocumentsFromAuthCollection.set(allDocumentsFromAuthCollection.data);

    let firstArray: unknown[];
    let secondArray: unknown[];

    if (allUsers.length > allDocumentsFromAuthCollection.data.length) {
      firstArray = [...allUsers];
      secondArray = [...allDocumentsFromAuthCollection.data];
    } else {
      firstArray = [...allDocumentsFromAuthCollection.data];
      secondArray = [...allUsers];
    }

    const difference = firstArray.filter(
      (firstArrayItem) =>
        !secondArray.some((secondArrayItem) => {
          const firstArrayItemId = firstArrayItem['id'] ?? firstArrayItem['uid'];
          const secondArrayItemId = secondArrayItem['id'] ?? secondArrayItem['uid'];
          return secondArrayItemId === firstArrayItemId;
        }),
    );
    this.usersDiff.set(difference);
    this.isShowDiff.set(true);

    console.log('end getting diff flow');
  }
}
