import { Component, inject, signal } from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';

import { IUser } from 'src/app/core/models';
import { CoursesStore, UsersStore } from 'src/app/core/stores';
import { ButtonComponent, ButtonSize, ButtonStyle, ButtonType } from 'src/app/standalone/shared';
import { CourseSubscriberInstructorSidebarComponent } from './course-subscriber-instructor-sidebar/course-subscriber-instructor-sidebar.component';

@Component({
  selector: 'app-course-subscriber-instructor',
  standalone: true,
  imports: [ButtonComponent, RouterModule, CourseSubscriberInstructorSidebarComponent],
  templateUrl: './course-subscriber-instructor.component.html',
  styleUrl: './course-subscriber-instructor.component.scss',
})
export class CourseSubscriberInstructorComponent {
  buttonType = signal<typeof ButtonType>(ButtonType);
  buttonSize = signal<typeof ButtonSize>(ButtonSize);
  buttonStyle = signal<typeof ButtonStyle>(ButtonStyle);

  #router = inject(Router);
  #route = inject(ActivatedRoute);
  #usersStore = inject(UsersStore);
  #coursesStore = inject(CoursesStore);

  get user(): IUser {
    return this.#usersStore.adminUser;
  }

  onNavigateToSubscribersOrInstructorsList(): void {
    this.#router.navigate([`../`], { relativeTo: this.#route });
  }

  updateStateForHidingSidebarInAdminCourse(): void {
    this.#coursesStore.updateStateForHidingSidebarInAdminCourse(false);
  }
}
