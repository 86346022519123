import * as moment from 'moment';
import { Component, OnInit, inject, signal } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import {
  imgPreloader,
  logoAvatar,
  IAdminTableColumn,
  parseToMoment,
  SharedModule,
} from 'src/app/shared';
import { IEvent, IHub, IUser } from 'src/app/core/models';
import { UsersStore, HubsStore } from 'src/app/core/stores';
import { EventsService, UsersService } from 'src/app/core/services';
import { ButtonSize, ButtonStyle, ButtonType } from 'src/app/standalone/shared/enums';
import { ButtonComponent, StatusTagComponent } from 'src/app/standalone/shared/components';

@Component({
  selector: 'app-user-events',
  standalone: true,
  imports: [SharedModule, ButtonComponent, StatusTagComponent],
  templateUrl: './user-events.component.html',
  styleUrl: './user-events.component.scss',
})
export class UserEventsComponent implements OnInit {
  loading = signal(true);
  loadingEntries = signal(false);
  entries = signal<IEvent[]>([]);
  searchValue = signal('');
  searchFilterFields = signal<string[]>(['title', 'start', 'end', 'location', 'role', 'status']);
  columns = signal<IAdminTableColumn[]>([
    {
      field: 'title',
      template: 'titleCell',
      titleTranslationKey: 'adminUserEvents.tableColTitle',
      sortable: true,
    },
    {
      field: 'location',
      titleTranslationKey: 'adminUserEvents.tableColLocation',
      sortable: true,
    },
    {
      field: 'status',
      template: 'statusCell',
      titleTranslationKey: 'adminUserEvents.tableColStatus',
      sortable: true,
    },
    { field: 'actions', template: 'actionsCell' },
  ]);
  imgPreloader = signal<string>(imgPreloader);
  logoAvatar = signal<string>(logoAvatar);
  buttonSize = signal<typeof ButtonSize>(ButtonSize);
  buttonType = signal<typeof ButtonType>(ButtonType);
  buttonStyle = signal<typeof ButtonStyle>(ButtonStyle);

  private router = inject(Router);
  private route = inject(ActivatedRoute);
  private usersService = inject(UsersService);
  private usersStore = inject(UsersStore);
  private hubsStore = inject(HubsStore);
  private eventsService = inject(EventsService);
  private translateService = inject(TranslateService);

  get user(): IUser {
    return this.usersStore.adminUser;
  }

  get hub(): IHub {
    return this.hubsStore.hub;
  }

  async ngOnInit(): Promise<void> {
    await this.fetchEvents();
    this.loadingEntries.set(false);
    this.loading.set(false);
  }

  edit(event: Event, entry: IEvent): void {
    event.stopPropagation();
    this.router.navigate([`/${this.hubsStore.useHubUrl}/admin/event/${entry.link}`], {
      relativeTo: this.route,
      state: {
        event: entry,
      },
    });
  }

  async onRowClicked(id: string): Promise<void> {
    const events: IEvent[] = await this.eventsService.getById(id);
    this.router.navigate([`/${this.hubsStore.useHubUrl}/admin/event/${events[0].link}`]);
  }

  getEventDate(event: IEvent): string {
    const lang: string = this.translateService.currentLang;
    const localLocaleStartDate = moment(parseToMoment(event.start));
    const localLocaleEndDate = moment(parseToMoment(event.end));
    localLocaleStartDate.locale(lang);
    localLocaleEndDate.locale(lang);
    if (moment(parseToMoment(event.start)).isSame(parseToMoment(event.end), 'day')) {
      return `${localLocaleStartDate.format('LLL')} - ${localLocaleEndDate.format('LT')}`;
    }

    return `${localLocaleStartDate.format('LL')} - ${localLocaleEndDate.format('LL')}`;
  }

  private async fetchEvents(): Promise<void> {
    const events = await this.usersService.getUserAllEvents(this.user.id, this.hub.id);
    this.entries.set(events);
  }
}
