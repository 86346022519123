import { Component, OnInit } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { take } from 'rxjs/operators';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { MessageService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';

import { ConfirmDialogComponent, IAdminTableColumn } from 'src/app/shared';
import { TagsService } from 'src/app/core/services';
import { ITag } from 'src/app/core/models';
import { ButtonSize, ButtonStyle, ButtonType, TagComponent } from 'src/app/standalone';

@Component({
  selector: 'app-system-tags',
  templateUrl: './system-tags.component.html',
  styleUrls: ['./system-tags.component.scss'],
})
export class SystemTagsComponent implements OnInit {
  loading = true;
  loadingEntries = true;
  searchValue = '';
  searchFilterFields: string[] = ['title', 'timeStamp', 'updateTimeStamp'];
  columns: IAdminTableColumn[] = [
    { field: 'title', titleTranslationKey: 'adminSystemTags.tableColTitle', sortable: true },
    {
      field: 'timeStamp',
      titleTranslationKey: 'adminSystemTags.tableColTimeStamp',
      sortable: true,
    },
    {
      field: 'updateTimeStamp',
      titleTranslationKey: 'adminSystemTags.tableColTimeStampUpdated',
      sortable: true,
    },
    { field: 'actions', template: 'actionsCell' },
  ];
  entries: ITag[] = [];
  totalRecords = 0;
  pageSize = 10;
  buttonSize = ButtonSize;
  buttonType = ButtonType;
  buttonStyle = ButtonStyle;

  constructor(
    private messageService: MessageService,
    private dialogService: DialogService,
    private tagsService: TagsService,
    private translateService: TranslateService,
  ) {}

  async ngOnInit(): Promise<void> {
    await this.fetchTags();
    this.loadingEntries = false;
    this.loading = false;
  }

  async add(): Promise<void> {
    try {
      const deleteDialogRef: DynamicDialogRef = this.dialogService.open(TagComponent, {
        closable: false,
        styleClass: 'reset-dialog-styles',
      });

      const tag: ITag = await firstValueFrom(deleteDialogRef.onClose);

      if (tag) {
        this.messageService.add({
          severity: 'success',
          summary: this.translateService.instant('success'),
          detail: this.translateService.instant('adminSystemTags.succesCreate'),
          styleClass: 'custom-toast',
        });
        await this.fetchTags();
      }
    } catch (error) {
      this.messageService.add({
        severity: 'error',
        summary: this.translateService.instant('success'),
        detail: this.translateService.instant('adminSystemTags.errorCreate'),
        styleClass: 'custom-toast',
      });
    }
  }

  async edit(event: Event, tag: ITag): Promise<void> {
    try {
      event.stopPropagation();
      const deleteDialogRef: DynamicDialogRef = this.dialogService.open(TagComponent, {
        closable: false,
        styleClass: 'reset-dialog-styles',
        data: {
          tag: tag,
        },
      });

      const updatedTag: ITag = await firstValueFrom(deleteDialogRef.onClose);

      if (updatedTag) {
        this.messageService.add({
          severity: 'success',
          summary: this.translateService.instant('success'),
          detail: this.translateService.instant('adminSystemTags.succesUpdate'),
          styleClass: 'custom-toast',
        });
        await this.fetchTags();
      }
    } catch (error) {
      this.messageService.add({
        severity: 'error',
        summary: this.translateService.instant('success'),
        detail: this.translateService.instant('adminSystemTags.errorUpdate'),
        styleClass: 'custom-toast',
      });
    }
  }

  remove(event: Event, entry: ITag): void {
    event.stopPropagation();
    const deleteDialogRef = this.dialogService.open(ConfirmDialogComponent, {
      closable: false,
      styleClass: 'confirm-dialog',
      data: {
        titleKey: 'adminSystemTags.deleteTitle',
        descriptionKey: 'adminSystemTags.deleteText',
        confirmBtnKey: 'adminSystemTags.confirmBtn',
        cancelBtnKey: 'adminSystemTags.cancelBtn',
        entry: entry,
      },
    });

    deleteDialogRef.onClose.pipe(take(1)).subscribe(async (result: 'cancel' | 'confirm') => {
      if (result === 'confirm') {
        try {
          this.loadingEntries = true;
          let deleteResult = await this.tagsService.remove(entry.id);
          if (deleteResult)
            this.messageService.add({
              severity: 'success',
              summary: this.translateService.instant('success'),
              detail: this.translateService.instant('adminSystemTags.succesDelete'),
              styleClass: 'custom-toast',
            });
          await this.fetchTags();
        } catch (error) {
          this.messageService.add({
            severity: 'error',
            summary: this.translateService.instant('success'),
            detail: this.translateService.instant('adminSystemTags.errorDelete'),
            styleClass: 'custom-toast',
          });
        }
      }
    });
  }

  private async fetchTags(): Promise<void> {
    this.loadingEntries = true;
    this.entries = await this.tagsService.getAllOrderedByTitle();
    this.loadingEntries = false;
  }
}
