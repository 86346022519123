<div *ngIf="loading">
  <app-loader></app-loader>
</div>

<div
  *ngIf="!loading"
  class="container">
  <div class="event-pages-list-table-container">
    <admin-table-header-navbar [title]="'adminEventPagesList.title' | translate">
    </admin-table-header-navbar>

    <div class="c-mt-4">
      <p-divider styleClass="custom-divider-horizontal"></p-divider>
    </div>

    <admin-table
      [scssClass]="scssClassForTable"
      [loading]="loadingEntries"
      [columns]="columns"
      [entries]="entries"
      [paginator]="false"
      [lazy]="false"
      [emptyStateKey]="'adminEventPagesList.emptyState'"
      [isShowTotalEntries]="false">
      <ng-template
        let-entry
        epTemplateKey="langCell">
        <span
          class="flag-icon flag-icon-squared flag-icon-circle flag-icon-{{
            entry.language === 'en' ? 'gb' : entry.language
          }}">
        </span>
      </ng-template>

      <ng-template
        let-entry
        epTemplateKey="htmlCell">
        <app-status-tag
          [iconClass]="entry.isHtmlMode ? 'fa-regular fa-check' : 'fa-regular fa-xmark'"
          [bgColor]="entry.isHtmlMode ? 'var(--appPrimaryColor)' : '#ff244c'" />
      </ng-template>

      <ng-template
        let-entry
        epTemplateKey="defaultCell">
        <app-status-tag
          [iconClass]="entry.default ? 'fa-regular fa-check' : 'fa-regular fa-xmark'"
          [bgColor]="entry.default ? 'var(--appPrimaryColor)' : '#ff244c'" />
      </ng-template>

      <ng-template
        let-entry
        epTemplateKey="statusCell">
        <app-status-tag
          [iconClass]="entry.status ? 'fa-regular fa-check' : 'fa-regular fa-xmark'"
          tagText="{{
            (entry.status ? 'adminTable.statusActive' : 'adminTable.statusInactive') | translate
          }}"
          [bgColor]="entry.status ? 'var(--appPrimaryColor)' : '#ff244c'" />
      </ng-template>

      <ng-template
        let-entry
        epTemplateKey="actionsCell">
        <div class="table-btn-actions-container">
          <app-button
            [buttonType]="buttonType.ROUND"
            [buttonSize]="buttonSize.MEDIUM"
            [buttonStyle]="buttonStyle.FILLED_SECONDARY"
            buttonRoundIcon="far fa-pen-to-square"
            (click)="edit($event, entry)" />
        </div>
      </ng-template>
    </admin-table>
  </div>
</div>
