import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class FormService {
  #form: FormGroup;
  #originalValue: any;

  setForm(form: FormGroup): void {
    this.#form = form;
    this.#originalValue = this.#form.getRawValue();
  }

  isValueChanged(): boolean {
    return JSON.stringify(this.#form.getRawValue()) !== JSON.stringify(this.#originalValue);
  }
}
