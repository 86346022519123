export const STRIPE_EMBED_PAYOUTS_COMPONENT = {
  payouts: {
    enabled: true,
    features: {
      instant_payouts: true,
      standard_payouts: true,
      edit_payout_schedule: true,
      external_account_collection: true,
    },
  },
};
