<div class="container">
  <header class="header">
    <h2 class="title">{{ 'mangeTranslationsDialog.title' | translate }}</h2>
    <app-cross-icon (click)="onCloseDialog()" />
  </header>
  <main>Loading...</main>
  <footer>
    <app-button
      buttonText="{{ 'shared.cancel' | translate }}"
      [buttonStyle]="buttonStyle().GHOST_PRIMARY"
      [buttonSize]="buttonSize().MEDIUM"
      buttonIconLeft="fa-regular fa-xmark"
      (click)="onCloseDialog()" />
    <app-button
      buttonText="{{ 'shared.save' | translate }}"
      [buttonSize]="buttonSize().MEDIUM"
      [loading]="isUpdating()"
      [disabled]="!canUpdate"
      (click)="onSave()" />
  </footer>
</div>
