<div class="container">
  <header class="header">
    <h2 class="title">{{ 'shareDialogComponent.title' | translate }}</h2>
    <app-cross-icon (click)="onCloseDialog()" />
  </header>
  <p class="subtitle">{{ 'shareDialogComponent.subtitle' | translate }}</p>
  <div class="share-options">
    <div
      class="share-option facebook"
      (click)="shareHub('facebook')">
      <i class="fab fa-facebook"></i>
      <span>{{ 'shared.facebook' | translate }}</span>
    </div>
    <div
      class="share-option x"
      (click)="shareHub('x')">
      <i class="fab fa-x-twitter"></i>
      <span>{{ 'shared.x' | translate }}</span>
    </div>
    <div
      class="share-option linkedin"
      (click)="shareHub('linkedin')">
      <i class="fab fa-linkedin"></i>
      <span>{{ 'shared.linkedin' | translate }}</span>
    </div>
    <a
      class="share-option email"
      [href]="emailHref()"
      (click)="markHubAsShared()">
      <i class="fas fa-envelope"></i>
      <span>{{ 'shared.email' | translate }}</span>
    </a>
  </div>
</div>
