import { Injectable } from '@angular/core';

import {
  Firestore,
  IDocumentData,
  IDocumentReference,
  IDocumentSnapshot,
  IFirestore,
  IQuerySnapshot,
  Timestamp,
} from 'src/app/firebase';
import { API_ROUTES as apiRoutes } from 'src/app/shared';
import { IUserCourseTracking } from '../../models';
import { UsersStore } from '../../stores';

@Injectable({
  providedIn: 'root',
})
export class CourseTrackingService {
  private firestore: IFirestore;

  constructor(private usersStore: UsersStore) {
    this.firestore = Firestore();
  }

  async getOne(id: string): Promise<IUserCourseTracking> {
    try {
      const userCourseTrackingQuery: IDocumentSnapshot<IDocumentData> = await this.firestore
        .collection(apiRoutes.userCoursesTracking)
        .doc(id)
        .get();

      return userCourseTrackingQuery.data() as IUserCourseTracking;
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  async getOneByIds(
    courseId: string,
    assetId: string,
    userId: string,
  ): Promise<IUserCourseTracking> {
    try {
      const userCourseTrackingQuery: IQuerySnapshot<IDocumentData> = await this.firestore
        .collection(apiRoutes.userCoursesTracking)
        .where('courseId', '==', courseId)
        .where('assetId', '==', assetId)
        .where('userId', '==', userId)
        .get();

      if (userCourseTrackingQuery.empty) {
        return null;
      }

      return userCourseTrackingQuery.docs[0].data() as IUserCourseTracking;
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  async getAllByUserId(userId: string, courseId: string): Promise<IUserCourseTracking[]> {
    try {
      const userCourseTrackings: IUserCourseTracking[] = (
        await this.firestore
          .collection(apiRoutes.userCoursesTracking)
          .where('userId', '==', userId)
          .where('courseId', '==', courseId)
          .get()
      ).docs.map((doc) => doc.data() as IUserCourseTracking);

      return userCourseTrackings;
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  async getTotalViewsByUserId(userId: string, courseId: string): Promise<number> {
    try {
      const userCourseTrackings: IUserCourseTracking[] = (
        await this.firestore
          .collection(apiRoutes.userCoursesTracking)
          .where('userId', '==', userId)
          .where('courseId', '==', courseId)
          .where('isWatched', '==', true)
          .get()
      ).docs.map((doc) => doc.data() as IUserCourseTracking);

      return userCourseTrackings.length;
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  async getByAssetId(assetId: string, courseId: string): Promise<IUserCourseTracking[]> {
    try {
      const userCourseTrackings: IUserCourseTracking[] = (
        await this.firestore
          .collection(apiRoutes.userCoursesTracking)
          .where('assetId', '==', assetId)
          .where('courseId', '==', courseId)
          .get()
      ).docs.map((doc) => doc.data() as IUserCourseTracking);

      return userCourseTrackings;
    } catch (error) {
      console.warn(error);
      throw error;
    }
  }

  async getAllByAssetId(assetId: string, courseId: string): Promise<any> {
    try {
      const userCourseTrackings: IUserCourseTracking[] = (
        await this.firestore
          .collection(apiRoutes.userCoursesTracking)
          .where('assetId', '==', assetId)
          .where('courseId', '==', courseId)
          .get()
      ).docs.map((doc) => doc.data() as IUserCourseTracking);

      const viewersCount = userCourseTrackings.filter((track) => track.isWatched).length;
      const viewsCount = userCourseTrackings.length;
      const totalPercentWatched = userCourseTrackings.reduce(
        (acc, track) => acc + track.percentageViewed,
        0,
      );
      const averagePercentWatched = totalPercentWatched / viewsCount || 0;

      return {
        viewersCount,
        viewsCount,
        averagePercentWatched,
      };
    } catch (error) {
      console.warn(error);
      throw error;
    }
  }

  async getAllInfoByUserId(userId: string, courseId: string): Promise<any> {
    try {
      const userCourseTrackings: IUserCourseTracking[] = (
        await this.firestore
          .collection(apiRoutes.userCoursesTracking)
          .where('userId', '==', userId)
          .where('courseId', '==', courseId)
          .get()
      ).docs.map((doc) => doc.data() as IUserCourseTracking);

      const assetsViewed = userCourseTrackings.filter((track) => track.isWatched).length;
      const viewsCount = userCourseTrackings.length;
      const totalPercentageWatched = userCourseTrackings.reduce(
        (acc, track) => acc + track.percentageViewed,
        0,
      );
      const averagePercentageWatched = totalPercentageWatched / viewsCount || 0;

      return {
        assetsViewed,
        viewsCount,
        averagePercentageWatched,
      };
    } catch (error) {
      console.warn(error);
      throw error;
    }
  }

  async getAllInfoByUserAndAssetId(
    userId: string,
    assetId: string,
    courseId: string,
  ): Promise<any> {
    try {
      const userCourseTrackings: IUserCourseTracking[] = (
        await this.firestore
          .collection(apiRoutes.userCoursesTracking)
          .where('userId', '==', userId)
          .where('assetId', '==', assetId)
          .where('courseId', '==', courseId)
          .get()
      ).docs.map((doc) => doc.data() as IUserCourseTracking);
      const viewed = userCourseTrackings[0].isWatched;
      const views = userCourseTrackings.length;
      const averagePercentWatched = userCourseTrackings[0].percentageViewed;

      return {
        viewed,
        views,
        averagePercentWatched,
      };
    } catch (error) {
      console.warn(error);
      throw error;
    }
  }

  async create(userCourseTracking: IUserCourseTracking): Promise<IUserCourseTracking> {
    try {
      const preCourseTrackingReqDoc: IDocumentReference<IDocumentData> = this.firestore
        .collection(apiRoutes.userCoursesTracking)
        .doc();
      userCourseTracking.id = preCourseTrackingReqDoc.id;
      userCourseTracking.createdAt = Timestamp.now();
      userCourseTracking.createdBy = this.usersStore.userId;
      await preCourseTrackingReqDoc.set(userCourseTracking);

      return userCourseTracking;
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  async update(userCourseTracking: IUserCourseTracking): Promise<IUserCourseTracking> {
    try {
      const userCourseTrackingDocument: IDocumentReference<IDocumentData> = this.firestore
        .collection(apiRoutes.userCoursesTracking)
        .doc(userCourseTracking.id);
      await userCourseTrackingDocument.update({
        ...userCourseTracking,
        updatedAt: Timestamp.now(),
        updatedBy: this.usersStore.userId,
      });

      return userCourseTracking;
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  async delete(id: string): Promise<boolean> {
    try {
      await this.firestore.collection(apiRoutes.userCoursesTracking).doc(id).delete();

      return true;
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  async deleteByCourseAndUserInfo(courseId: string, userId: string) {
    const userCourseTrackingsSnapshot: IQuerySnapshot<IDocumentData> = await this.firestore
      .collection(apiRoutes.userCoursesTracking)
      .where('courseId', '==', courseId)
      .where('userId', '==', userId)
      .get();

    const batch = this.firestore.batch();

    userCourseTrackingsSnapshot.docs.forEach((doc) => {
      batch.delete(doc.ref);
    });

    await batch.commit();
  }

  async getViewersCountByCourseId(courseId: string): Promise<number> {
    try {
      const userCourseTrackings: IUserCourseTracking[] = (
        await this.firestore
          .collection(apiRoutes.userCoursesTracking)
          .where('courseId', '==', courseId)
          .where('isWatched', '==', true)
          .get()
      ).docs.map((doc) => doc.data() as IUserCourseTracking);

      return userCourseTrackings?.length;
    } catch (error) {
      console.warn(error);
      throw error;
    }
  }

  async getViewersCountForSingleAssetByAssetId(assetId: string, courseId: string): Promise<number> {
    try {
      const userCourseTrackings: IUserCourseTracking[] = (
        await this.firestore
          .collection(apiRoutes.userCoursesTracking)
          .where('assetId', '==', assetId)
          .where('isWatched', '==', true)
          .where('courseId', '==', courseId)
          .get()
      ).docs.map((doc) => doc.data() as IUserCourseTracking);

      return userCourseTrackings.length;
    } catch (error) {
      console.warn(error);
      throw error;
    }
  }

  async getViewsCountForSingleAssetByAssetId(assetId: string, courseId: string): Promise<number> {
    try {
      const userCourseTrackings: IUserCourseTracking[] = (
        await this.firestore
          .collection(apiRoutes.userCoursesTracking)
          .where('assetId', '==', assetId)
          .where('courseId', '==', courseId)
          .get()
      ).docs.map((doc) => doc.data() as IUserCourseTracking);

      return userCourseTrackings.length;
    } catch (error) {
      console.warn(error);
      throw error;
    }
  }

  async getAveragePercentViewByAssetId(assetId: string, courseId: string): Promise<number> {
    try {
      const userCourseTrackings: IUserCourseTracking[] = (
        await this.firestore
          .collection(apiRoutes.userCoursesTracking)
          .where('assetId', '==', assetId)
          .where('courseId', '==', courseId)
          .get()
      ).docs.map((doc) => doc.data() as IUserCourseTracking);
      let totalPercentage = 0;
      userCourseTrackings.forEach((ct: IUserCourseTracking) => {
        totalPercentage += ct.percentageViewed;
      });
      const averagePercentage = totalPercentage / userCourseTrackings.length;
      return averagePercentage;
    } catch (error) {
      console.warn(error);
      throw error;
    }
  }

  async getAverageTimeSpendByAssetId(assetId: string, courseId: string): Promise<number> {
    try {
      const userCourseTrackings: IUserCourseTracking[] = (
        await this.firestore
          .collection(apiRoutes.userCoursesTracking)
          .where('assetId', '==', assetId)
          .where('courseId', '==', courseId)
          .get()
      ).docs.map((doc) => doc.data() as IUserCourseTracking);
      let totalSeconds = 0;
      userCourseTrackings.forEach((ct: IUserCourseTracking) => {
        totalSeconds += ct.secondsViewed;
      });
      const averageTimeSpent = totalSeconds / userCourseTrackings.length / 60;
      if (averageTimeSpent) {
        return averageTimeSpent;
      } else {
        return 0;
      }
      return averageTimeSpent;
    } catch (error) {
      console.warn(error);
      throw error;
    }
  }

  async getAverageTimeSpentByUserId(userId: string, courseId: string): Promise<number> {
    try {
      const userCourseTrackings: IUserCourseTracking[] = (
        await this.firestore
          .collection(apiRoutes.userCoursesTracking)
          .where('userId', '==', userId)
          .where('courseId', '==', courseId)
          .get()
      ).docs.map((doc) => doc.data() as IUserCourseTracking);
      let totalSeconds = 0;
      userCourseTrackings.forEach((ct: IUserCourseTracking) => {
        totalSeconds += ct.secondsViewed;
      });
      const averageTimeSpent = totalSeconds / userCourseTrackings.length / 60;
      if (averageTimeSpent) {
        return averageTimeSpent;
      } else {
        return 0;
      }
    } catch (error) {
      console.warn(error);
      throw error;
    }
  }

  async getAveragePercentViewByUserId(userId: string, courseId: string): Promise<number> {
    try {
      const userCourseTrackings: IUserCourseTracking[] = (
        await this.firestore
          .collection(apiRoutes.userCoursesTracking)
          .where('userId', '==', userId)
          .where('courseId', '==', courseId)
          .get()
      ).docs.map((doc) => doc.data() as IUserCourseTracking);

      let totalPercent = 0;
      userCourseTrackings.forEach((ct: IUserCourseTracking) => {
        totalPercent += ct.percentageViewed;
      });
      const averagePercentage = totalPercent / userCourseTrackings.length;
      if (averagePercentage) {
        return averagePercentage;
      } else {
        return 0;
      }
    } catch (error) {
      console.warn(error);
      throw error;
    }
  }

  async getIsWatchedByTrackingId(userCourseTrackingId: string): Promise<boolean> {
    try {
      const userCourseTracking: IUserCourseTracking = (
        await this.firestore
          .collection(apiRoutes.userCoursesTracking)
          .doc(userCourseTrackingId)
          .get()
      ).data() as IUserCourseTracking;

      return userCourseTracking.isWatched;
    } catch (error) {
      console.warn(error);
      throw error;
    }
  }

  async getAllWatchedAssetsByUserAndCourseId(courseId: string, userId: string): Promise<number> {
    try {
      const userCourseTrackings: IUserCourseTracking[] = (
        await this.firestore
          .collection(apiRoutes.userCoursesTracking)
          .where('courseId', '==', courseId)
          .where('userId', '==', userId)
          .where('isWatched', '==', true)
          .get()
      ).docs.map((doc) => doc.data() as IUserCourseTracking);

      return userCourseTrackings.length;
    } catch (error) {
      console.warn(error);
      throw error;
    }
  }

  async getAverageTimeSpentOnAllAssetsByUserAndCourseId(
    courseId: string,
    userId: string,
  ): Promise<number> {
    try {
      const userCourseTrackings: IUserCourseTracking[] = (
        await this.firestore
          .collection(apiRoutes.userCoursesTracking)
          .where('courseId', '==', courseId)
          .where('userId', '==', userId)
          .get()
      ).docs.map((doc) => doc.data() as IUserCourseTracking);
      let totalTimeSpent = 0;
      userCourseTrackings.forEach((ct: IUserCourseTracking) => {
        totalTimeSpent += ct.secondsViewed;
      });
      totalTimeSpent = Math.round(totalTimeSpent / 60);
      return totalTimeSpent;
    } catch (error) {
      console.warn(error);
      throw error;
    }
  }

  async getAveragePercentWatchedByUserAndCourseId(
    courseId: string,
    userId: string,
  ): Promise<number> {
    try {
      const userCourseTrackings: IUserCourseTracking[] = (
        await this.firestore
          .collection(apiRoutes.userCoursesTracking)
          .where('courseId', '==', courseId)
          .where('userId', '==', userId)
          .get()
      ).docs.map((doc) => doc.data() as IUserCourseTracking);
      let totalPercentageWatched = 0;
      userCourseTrackings.forEach((ct: IUserCourseTracking) => {
        totalPercentageWatched += ct.percentageViewed;
      });
      return totalPercentageWatched / userCourseTrackings.length;
    } catch (error) {
      console.warn(error);
      throw error;
    }
  }

  async getIfUserWatchedAsset(userId: string, assetId: string): Promise<boolean> {
    try {
      const userCourseTrackings: IUserCourseTracking[] = (
        await this.firestore
          .collection(apiRoutes.userCoursesTracking)
          .where('userId', '==', userId)
          .where('assetId', '==', assetId)
          .get()
      ).docs.map((doc) => doc.data() as IUserCourseTracking);
      return userCourseTrackings[0].isWatched;
    } catch (error) {
      console.warn(error);
      throw error;
    }
  }

  async getPercentageAssetViewByUserAndAssetId(userId: string, assetId: string): Promise<number> {
    try {
      const userCourseTrackings: IUserCourseTracking[] = (
        await this.firestore
          .collection(apiRoutes.userCoursesTracking)
          .where('userId', '==', userId)
          .where('assetId', '==', assetId)
          .get()
      ).docs.map((doc) => doc.data() as IUserCourseTracking);
      return userCourseTrackings[0].percentageViewed;
    } catch (error) {
      console.warn(error);
      throw error;
    }
  }
}
