import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgChartsModule } from 'ng2-charts';
import { ChartConfiguration, ChartType, DefaultDataPoint } from 'chart.js';

import { SharedModule } from 'src/app/shared';

@Component({
  selector: 'app-stat-chart',
  standalone: true,
  imports: [CommonModule, SharedModule, NgChartsModule],
  templateUrl: './stat-chart.component.html',
  styleUrls: ['./stat-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatChartComponent<
  TType extends ChartType = ChartType,
  TData = DefaultDataPoint<TType>,
  TLabel = unknown,
> {
  statName = input<string>();
  isShowTooltip = input(false);
  tooltipText = input<string>();
  tooltipPosition = input<'right' | 'left' | 'top' | 'bottom'>('right');
  tooltipStyleClass = input<string>('custom-tooltip');
  tooltipEvent = input<'hover' | 'focus'>('hover');
  tabIndex = input<number>();
  chartStyles = input<{ [key: string]: string }>();
  chartType = input<ChartConfiguration<TType, TData, TLabel>['type']>();
  chartData = input<ChartConfiguration<TType, TData, TLabel>['data']>();
  chartOptions = input<ChartConfiguration<TType, TData, TLabel>['options']>();
  chartLegend = input(true);
}
