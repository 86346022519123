import { Component, inject, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DividerModule } from 'primeng/divider';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Chart } from 'chart.js';

import {
  StatCardComponent,
  StatChartComponent,
  StatSingleProgressBarComponent,
} from 'src/app/standalone/shared/components/stats';
import { IStatCard, IStatChart } from 'src/app/standalone/shared/models';

@Component({
  selector: 'app-event-dashboard',
  standalone: true,
  imports: [
    StatCardComponent,
    DividerModule,
    StatChartComponent,
    StatSingleProgressBarComponent,
    CommonModule,
    TranslateModule,
  ],
  templateUrl: './event-dashboard.component.html',
  styleUrl: './event-dashboard.component.scss',
})
export class EventDashboardComponent {
  stats = signal<IStatCard[]>(null);
  charts = signal<IStatChart[]>(null);

  #translateService = inject(TranslateService);

  ngOnInit(): void {
    this.initStats();
    this.initCharts();
  }

  private initStats(): void {
    this.stats.set([
      {
        iconClass: 'fa-regular fa-users',
        text: 'eventDashboard.attendeesStat',
        value: '900',
        isShowTooltip: true,
        tooltipText: this.#translateService.instant('eventDashboard.attendeesTooltip'),
      },
      {
        iconClass: 'fa-regular fa-money-bill-trend-up',
        text: 'eventDashboard.totalSalesStat',
        value: '80.0',
        isShowTooltip: true,
        tooltipText: this.#translateService.instant('eventDashboard.totalSalesTooltip'),
      },
      {
        iconClass: 'fa-regular fa-hand-holding-dollar',
        text: 'eventDashboard.totalRevenueStat',
        value: '100',
        isShowTooltip: true,
        tooltipText: this.#translateService.instant('eventDashboard.totalRevenueTooltip'),
      },
      {
        iconClass: 'fa-regular fa-calendar-days',
        text: 'eventDashboard.daysUntilEventStat',
        value: '500',
        isShowTooltip: true,
        tooltipText: this.#translateService.instant('eventDashboard.daysUntilEventTooltip'),
      },
    ]);
  }

  private initCharts(): void {
    const documentStyle = getComputedStyle(document.documentElement);
    this.charts.set([
      {
        statName: this.#translateService.instant('eventDashboard.weeklyRegistrationsGraphName'),
        type: 'line',
        data: {
          labels: ['Week 1', 'Week 2', 'Week 3', 'Week 4', 'Week 5', 'Week 6'],
          datasets: [
            {
              data: [500, 700, 1000, 1250, 1500, 1700],
              label: this.#translateService.instant(
                'eventDashboard.weeklyRegistrationsGraphLegend',
              ),
              fill: false,
              tension: 0.5,
              borderColor: documentStyle.getPropertyValue('--brand-500'),
              pointRadius: 3,
              pointBackgroundColor: 'transparent',
              pointBorderColor: documentStyle.getPropertyValue('--brand-500'),
            },
          ],
        },
        options: {
          maintainAspectRatio: false,
          scales: {
            y: {
              beginAtZero: true,
              title: {
                display: true,
                text: this.#translateService.instant(
                  'eventDashboard.weeklyRegistrationsGraphAxisYTitle',
                ),
              },
            },
          },
          plugins: {
            legend: {
              labels: {
                generateLabels: function (chart) {
                  const original = Chart.defaults.plugins.legend.labels.generateLabels;
                  const labels = original.call(this, chart);
                  labels.forEach((label) => {
                    label.fillStyle = 'rgba(0, 0, 0, 0)';
                  });
                  return labels;
                },
              },
            },
          },
        },
        chartStyles: { height: '19rem' },
      },
      {
        statName: this.#translateService.instant('eventDashboard.revenueByTicketTypeGraphName'),
        type: 'bar',
        data: {
          labels: ['General Admission', 'VIP', 'Early Bird', 'Group Discount'],
          datasets: [
            {
              data: [500000, 200000, 50000, 40000],
              label: this.#translateService.instant(
                'eventDashboard.revenueByTicketTypeGraphLegend',
              ),
              borderColor: [
                documentStyle.getPropertyValue('--brand-500'),
                documentStyle.getPropertyValue('--green-500'),
                documentStyle.getPropertyValue('--orange-500'),
                documentStyle.getPropertyValue('--red-500'),
              ],
              backgroundColor: [
                documentStyle.getPropertyValue('--brand-500'),
                documentStyle.getPropertyValue('--green-500'),
                documentStyle.getPropertyValue('--orange-500'),
                documentStyle.getPropertyValue('--red-500'),
              ],
            },
          ],
        },
        options: {
          maintainAspectRatio: false,
          scales: {
            y: {
              beginAtZero: true,
              title: {
                display: true,
                text: this.#translateService.instant(
                  'eventDashboard.revenueByTicketTypeGraphAxisYTitle',
                ),
              },
            },
          },
        },
        chartStyles: { height: '19rem' },
      },
      {
        statName: this.#translateService.instant('eventDashboard.weeklyTicketSalesGraphName'),
        type: 'bar',
        data: {
          labels: ['Week 1', 'Week 2', 'Week 3', 'Week 4', 'Week 5', 'Week 6'],
          datasets: [
            {
              data: [1500, 2000, 2500, 3000, 3500, 3200],
              label: this.#translateService.instant('eventDashboard.weeklyTicketSalesGraphLegend'),
              borderColor: documentStyle.getPropertyValue('--brand-500'),
              backgroundColor: documentStyle.getPropertyValue('--brand-500'),
            },
          ],
        },
        options: {
          maintainAspectRatio: false,
          scales: {
            y: {
              beginAtZero: true,
              title: {
                display: true,
                text: this.#translateService.instant(
                  'eventDashboard.weeklyTicketSalesGraphAxisYTitle',
                ),
              },
            },
          },
        },
        chartStyles: { height: '19rem' },
      },
      {
        statName: this.#translateService.instant('eventDashboard.revenueByTicketTypeGraphName'),
        type: 'doughnut',
        data: {
          labels: ['General Admission', 'VIP', 'Early Bird', 'Group Discount'],
          datasets: [
            {
              data: [500000, 200000, 50000, 40000],
              label: this.#translateService.instant(
                'eventDashboard.revenueByTicketTypeGraphLegend',
              ),
              borderColor: [
                documentStyle.getPropertyValue('--brand-500'),
                documentStyle.getPropertyValue('--green-500'),
                documentStyle.getPropertyValue('--orange-500'),
                documentStyle.getPropertyValue('--red-500'),
              ],
              backgroundColor: [
                documentStyle.getPropertyValue('--brand-500'),
                documentStyle.getPropertyValue('--green-500'),
                documentStyle.getPropertyValue('--orange-500'),
                documentStyle.getPropertyValue('--red-500'),
              ],
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
        },
        chartStyles: { height: '19rem' },
      },
    ]);
  }
}
