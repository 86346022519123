import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  CanDeactivateFn,
  Router,
  RouterStateSnapshot,
} from '@angular/router';

import { HubsStore, UsersStore } from 'src/app/core/stores';
import { StripeService, ThemesService } from 'src/app/core/services';

export const adminAccessGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
): boolean => {
  const themesService = inject(ThemesService);
  const usersStore = inject(UsersStore);
  const hubsStore = inject(HubsStore);
  const router = inject(Router);

  const currentTheme = localStorage.getItem('styleTheme');
  const currentThemeProps =
    themesService.systemAppearanceSettings[currentTheme + 'Theme'].properties;
  document.documentElement.style.setProperty(
    '--appPrimaryColor',
    currentThemeProps['--appPrimaryColor'],
  );

  if (state.url.endsWith('events') && !hubsStore.hub.events) {
    router.navigate([`${hubsStore.useHubUrl}/admin/courses`]);

    return false;
  }

  if (state.url.endsWith('courses') && !hubsStore.hub.events && !hubsStore.hub.academy) {
    router.navigate([`${hubsStore.useHubUrl}/admin/library`]);

    return false;
  }

  const condition: boolean =
    !!(usersStore.user && hubsStore.hub) ||
    !!(usersStore.user && !hubsStore.hub && state.url.includes('system-settings'));

  if (condition) {
    return true;
  }

  if (usersStore.user && !hubsStore.hub) {
    router.navigate(['admin/system-settings/general-settings/general']);

    return true;
  }

  router.navigate(['/dashboard']);

  return false;
};

export const deactivateAdminAccessGuard: CanDeactivateFn<unknown> = (
  component: unknown,
): boolean => {
  const hubsStore = inject(HubsStore);
  const themesService = inject(ThemesService);
  const stripeService = inject(StripeService);

  const hub = hubsStore.hub;
  if (hub) {
    const currentTheme = localStorage.getItem('styleTheme');
    const currentThemeProps =
      themesService.systemAppearanceSettings[currentTheme + 'Theme'].properties;

    document.documentElement.style.setProperty(
      '--appPrimaryColor',
      hub.primaryColor !== null && hub.primaryColor !== undefined && hub.primaryColor !== ''
        ? hub.primaryColor
        : currentThemeProps['--appPrimaryColor'],
    );
  }

  stripeService.logoutFromAccountSession();

  return true;
};
