<ng-container *ngIf="loading">
  <app-loader></app-loader>
</ng-container>

<ng-container *ngIf="!loading">
  <app-item-detail-header
    [title]="asset.title"
    (backClick)="onBackClick()"></app-item-detail-header>
</ng-container>

<ng-container>
  <div
    class="stat-cards-container"
    *ngIf="!loading">
    <ng-container *ngFor="let stat of statCards; let last = last; trackBy: trackByFn">
      <div
        class="card-container"
        [ngStyle]="{ width: getCardsContainerWidth() }">
        <app-stat-card
          [iconClass]="stat.iconClass"
          [text]="stat.text | translate"
          [value]="stat.value">
        </app-stat-card>
      </div>
      <ng-container *ngIf="!last">
        <p-divider
          styleClass="custom-divider-vertical-2"
          layout="vertical"></p-divider>
      </ng-container>
    </ng-container>
  </div>
</ng-container>
<ng-container>
  <div class="admin-course-asset-reports-container">
    <div class="admin-course-asset-reports-table-container">
      <admin-table-filter-navbar (onSearchTermChange)="onSearchTermChange($event)">
      </admin-table-filter-navbar>

      <admin-table
        [loading]="loadingEntries"
        [lazy]="false"
        [entries]="entries"
        [columns]="columns"
        [pageSize]="pageSize"
        [totalRecords]="totalRecords"
        [searchFilterFields]="searchFilterFields"
        [searchValue]="searchTerm"
        (rowClicked)="onRowClick($event)"
        emptyStateTitleKey="courseReports.emptyState"
        emptyStateKey="courseReports.emptyStateDescription"
        emptyStateIcon="fa-regular fa-users">
        <ng-template
          let-entry
          epTemplateKey="titleCell">
          <div class="title-cell">
            <div>
              <img
                *ngIf="entry.profileImage"
                class="img-wrapper"
                [defaultImage]="imgPreloader"
                [lazyLoad]="entry.profileImage | imageSize" />
              <div
                *ngIf="!entry.profileImage"
                class="placeholder-container">
                <img
                  class="placeholder"
                  [src]="userAvatar | imageSize" />
              </div>
            </div>
            <div class="user-info-wrapper">
              <p class="user-name">{{ entry.firstName }} {{ entry.lastName }}</p>
              <p class="user-email">{{ entry.email }}</p>
            </div>
          </div>
        </ng-template>

        <ng-template
          let-entry
          epTemplateKey="statusCell">
          <app-status-tag
            [iconClass]="entry.viewed ? 'fa-regular fa-check' : 'fa-regular fa-xmark'"
            [bgColor]="entry.viewed ? 'var(--appPrimaryColor)' : '#ff244c'" />
        </ng-template>

        <!-- <ng-template let-entry epTemplateKey="countCell">
          <div class="count-cell">
            <div class="count">
              {{entry.views}}
            </div>
          </div>
        </ng-template> -->

        <ng-template
          let-entry
          epTemplateKey="progressCell">
          <div class="progress-container">
            <p-progressBar
              [value]="entry.averagePercentWatched"
              [showValue]="false">
            </p-progressBar>
            <div class="percent-value">
              {{ getPercentageString(entry.averagePercentWatched) }}
            </div>
          </div>
        </ng-template>
      </admin-table>
    </div>
  </div>
</ng-container>
